import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { useAuth } from '../../hooks/Auth';
import { VideoProvider } from '../../hooks/Video';
import ClassRoomContainer from '../../components/ClassRoomContainer';
import { apiUrl } from '../../config';
import { LoaderWithElement } from '../../components/Loader';
import { useSocket } from '../../hooks/Socket';
import Button from '../../components/Button';

import { useToast } from '../../hooks/Toast';

interface IClassRoom {
  course ?: string;
}

const ClassRoom: React.FC<IClassRoom> = ({course = ''}) => {
  const { token } = useAuth();
  const { socket } = useSocket();
  const { addToast } = useToast();
  const [online, setOnline] = useState(0);
  const [removeUser, setRemoveUser] = useState(false);

  return removeUser ? (
    <LoaderWithElement
      message={
        <>
          <p>Você foi desconectado</p>
          <Button type="button" onClick={() => window.location.reload()}>
            Entrar novamente
          </Button>
        </>
      }
    />
  ) : (
    <VideoProvider>
      <ClassRoomContainer course={course} />
    </VideoProvider>
  );
};

export default ClassRoom;
