import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap } from 'react-icons/fi';
import { Link } from 'react-router-dom';


import { MdError } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { recaptchaKey } from '../../../config';
import getCep from '../../../services/cep';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useModal } from '../../../hooks/Modal';
import { useToast } from '../../../hooks/Toast';

interface IRecoverFormData {
  name: string;
  email: string;
  password: string;
  phone: string;
  captcha: string;
  birth_date: string;
}

interface ICaptcha {
  getValue(): string;
}

interface IProps {
  id : number;
}

const FormContainer : React.FC<IProps> = ({ id }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [knowAbout, setKnowAbout] = useState('');
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const {removeModal} = useModal();
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const {addToast} = useToast();
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);

    data.birth_date = data.birth_date
      ? data.birth_date.toString().replace(/[^0-9/]/g, '')
      : '';

    try {
      formRef.current?.setErrors({});

      const shape = {
        p1: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p2: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p3: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p4: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p5: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p6: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p7: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p8: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p9: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p10: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p12: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p13: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
        p14: Yup.string().required('Por favor, responda a pergunta todas as perguntas'),
   
      };

      if( knowAbout === 'Outro' || knowAbout === 'redes sociais'){
        shape['p11'] = Yup.string().required('Por favor, responda a pergunta todas as perguntas');
      }

      const schema = Yup.object().shape(shape);

      await schema.validate(data, { abortEarly: false });

      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const response = await api.post(`/send-research`, {
        ...data,
        client_id : id
      });

      removeModal('research');
      addToast({ title: 'Pesquisa de satisfação enviada', description: 'Você já pode acessar seu certificado!', type: 'success'})
      setLoading(false);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        if (formRef && formRef.current && formRef.current.setErrors) {
          formRef.current.setErrors(errors);
        }
        setLoading(false);

        const errorsString = Object.keys(errors).reduce(
          (prev: Array<string>, key: string): Array<string> => {
            prev = [...prev, errors[key]];
            return prev;
          },
          [],
        );

        setErrorMessage(errorsString);

        return;
      }

      setErrorMessage([
        err?.response?.data?.message ||
          err?.response?.data?.error ||
          'Erro ao cadastrar sua pesquisa',
      ]);
    }
    setLoading(false);
  };

  {/* 
  
              <SelectSimple name="p1" label="" options={[
              { label: '0', value: '0'},
              { label: '1', value: '1'},
              { label: '2', value: '2'},
              { label: '3', value: '3'},
              { label: '4', value: '4'},
              { label: '5', value: '5'},
              { label: '6', value: '6'},
              { label: '7', value: '7'},
              { label: '8', value: '8'},
              { label: '9', value: '9'},
              { label: '10', value: '10'},
            ]}/>
 
 
          */         }


  return (
    <>
      {loading === true && (
        <Loader message="Carregando..." position="fixed" />
      )}

      <aside>
     
          <Form ref={formRef} onSubmit={handleNewLogin} initialData={{}}>
          <br/><br/><h2 style={{width:'100%', textAlign:'center', fontSize:'24px', color: "#333"}}>PESQUISA DE SATISFAÇÃO</h2>

            <br/><br/><strong style={{margin: '10px 0px', maxWidth: '500px', color: "#333"}}>Antes de acessar seu certificado, pedimos que preencha nossa pesquisa de satisfação.</strong>

            <br/><br/><strong style={{margin: '10px 0px', maxWidth: '500px', color: "#333"}}>1) Dê uma nota de 1 a 5 (sendo 5 a nota máxima), para as seguintes perguntas:</strong>


            <p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Qualidade técnica do estúdio:</p>
            <SelectSimple name="p1" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>

<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Site do evento:</p>
            <SelectSimple name="p2" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>


<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Ficha de inscrição do evento:</p>
            <SelectSimple name="p3" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>
 
 <p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Qualidade do conteúdo apresentado:</p>
            <SelectSimple name="p4" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>

<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Clareza do conteúdo apresentado:</p>
            <SelectSimple name="p5" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>

<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Aplicabilidade do conteúdo para você:</p>
            <SelectSimple name="p6" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>

<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Seu nível de conhecimento adquirido:</p>
            <SelectSimple name="p7" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>
<p style={{color: "#333", textAlign:'left', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>Sua nota geral para o evento:</p>
            <SelectSimple name="p8" label="" options={[
                { label: '1', value: '1'},
                { label: '2', value: '2'},
                { label: '3', value: '3'},
                { label: '4', value: '4'},
                { label: '5', value: '5'},
            ]}/>

<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>2) Se o evento fosse cobrado, você consideraria um valor justo a ser pago:
</strong>

<SelectSimple name="p9" label="" options={[
              { label: 'de 10 a 30 reais', value: 'de 10 a 30 reais'},
              { label: 'de 30 a 50 reais', value: 'de 30 a 50 reais'},
              { label: 'de 50 a 80 reais', value: 'de 50 a 80 reais'},
              { label: '80 a 100 reais', value: '80 a 100 reais'},
              { label: 'mais de 100 reais', value: 'mais de 100 reais'},
 
            ]}/>


<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>3) Como você ficou sabendo do evento?
</strong>


<SelectSimple name="p10" label=""
setCallback={(value) => {setKnowAbout(value)}}
options={[
              { label: 'E-mail marketing', value: 'e-mail marketing'},
              { label: 'Redes sociais', value: 'redes sociais'},
              { label: 'Indicação de um amigo', value: 'indicação de um amigo'},
              { label: 'Pela imprensa: site, blog, jornais ou revistas (impressas ou digitais)', value: 'pela imprensa: site, blog, jornais ou revistas (impressas ou digitais)'},
              { label: 'Outro', value: 'Outro'},
 
            ]}/>

{ (knowAbout === 'Outro' || knowAbout === 'redes sociais') ? <Input name="p11" placeholder='Qual?'/> : <> </>}

<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>4) Você indicaria para alguém o Workshop dos Melhores Eventos?
</strong>

<SelectSimple name="p12" label="" options={[
              { label: 'Sim', value: 'Sim'},
              { label: 'Não', value: 'Não'},
        
            ]}/>

<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>5) Qual o melhor turno para a realização do evento na sua opinião:
</strong>

<SelectSimple name="p13" label="" options={[
              { label: 'manhã', value: 'manhã'},
              { label: 'tarde', value: 'tarde'},
              { label: 'noite', value: 'noite'},
            ]}/>

<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>6) Qual o melhor dia para a realização do evento, na sua opinião:
</strong>

<SelectSimple name="p14" label="" options={[
              { label: '2ª', value: '2ª'},
              { label: '3ª', value: '3ª'},
              { label: '4ª', value: '4ª'},
              { label: '5ª', value: '5ª'},
              { label: '6ª', value: '6ª'},
              { label: 'Sábado', value: 'Sábado'},
              { label: 'Domingo', value: 'Domingo'},
            ]}/>


<br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>7) Gostaríamos de saber qual a sua sugestão ou tema de interesse para tratarmos nas próximas edições: </strong>
            <TextareaInput name="p15"/>


            <br/><br/><strong style={{color: "#333",textAlign:'left',display:'flex', marginTop: '20px', marginBottom: '10px', fontWeight: 'bold'}}>8) Espaço aberto para ouvirmos a sua opinião ou considerações em geral: </strong>
            <TextareaInput name="p16"/>

            <br/><br/>
           <h2 style={{color: "#333"}} > A equipe do Workshop dos Melhores Eventos agradece a sua participação.</h2>
           <br/><br/>
            {errorMessage && (
              <Error>
                {errorMessage.map((item, index) => (
                  <div key={`error${index}`}>
                    <MdError />
                    {item}
                  </div>
                ))}
              </Error>
            )}
            <aside style={{display: 'flex', justifyContent:'center', width: '100%'}}>
            <button className='defaultButton' style={{ width: '200px', margin: '10px auto' }} type="submit">
              Enviar
            </button>
            </aside>
          </Form>
        
      </aside>
    </>
  );
};

export default FormContainer;
