import React, { useCallback, useRef, useEffect, useState } from 'react';



import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';


import Vimeo from '../../components/Vimeo';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Termos: React.FC = () => {
  return (
    
      <ContainerWhite id="termos">
        <div className="p50 p600" style={{  textAlign: 'justify' }}>
   
        <p dir="ltr">
   <strong>POLÍTICA DE PRIVACIDADE E PROTEÇÃO DE DADOS</strong> 
</p>
<br/>
<p dir="ltr">
    O Fronteiras do Pensamento, deseja que a experiência de contato com seus
    produtos e serviços, por meio deste site, crie em você um sentimento de
    alegria e satisfação. Para isso, recomendamos a leitura cuidadosa desta
    Política de Privacidade e Proteção de Dados.
</p>
<p dir="ltr">
    A presente Política de Privacidade e Proteção de Dados, se aplica às
    páginas relacionadas ao Fronteiras do Pensamento, bem como, aplica-se a
    todos os seus produtos e serviços.
</p>
<p dir="ltr">
    Para melhor ilustrar a forma como realizamos o tratamento de dados,
    apresentamos um resumo da nossa Política de Privacidade e Proteção de
    Dados:
</p>
<p dir="ltr">
    Quadro Resumo
</p>
<br/>
<div dir="ltr" style={{ textAlign:"left"}}>
    <table className="table">
        <colgroup>
            <col width="217"/>
            <col width="425"/>
        </colgroup>
        <tbody>
            <tr>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Agente de tratamento
                    </p>
                </td>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        DELOS PRODUÇÕES CULTURAIS LTDA – FRONTEIRAS DO
                        PENSAMENTO
                    </p>
                    <p dir="ltr">
                        CNPJ: 39.601.053/0001-01
                    </p>
                    <p dir="ltr">
                        Avenida Juscelino Kubitschek, nº 1545, 15 andar, conj.
                        155 e 157, parte, Bairro Vila Nova Conceição, São
                        Paulo/SP.
                    </p>
                </td>
            </tr>
            <tr>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Papel no tratamento
                    </p>
                </td>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Predominantemente Controladora.
                    </p>
                </td>
            </tr>
            <tr>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Natureza dos dados tratados
                    </p>
                </td>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Dados pessoais fornecidos pelo USUÁRIO e/ou coletados
                        automaticamente.
                    </p>
                </td>
            </tr>
            <tr>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Finalidade como controladora
                    </p>
                </td>
                 <td style={{border:'1px solid #333'}}>
                    <ul>
                        <li dir="ltr">
                            <p dir="ltr">
                                (i) Concessão de acesso ao site; (ii) criação
                                de cadastros para contato e/ou contratação e/ou
                                participação em evento; bem como (iii) o
                                oferecimento de promoções, descontos e/ou
                                benefícios do Fronteiras do Pensamento ou de
                                seus parceiros comerciais, de cursos, produtos,
                                subprodutos, e/ou serviços qualquer
                                relacionados aos projetos Fronteiras.
                            </p>
                        </li>
                    </ul>
                    <p dir="ltr">
                        Base Legal:
                    </p>
                    <ul>
                        <li dir="ltr">
                            <p dir="ltr">
                                Consentimento do titular.
                            </p>
                        </li>
                    </ul>
                </td>
            </tr>
            <tr>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Compartilhamento
                    </p>
                </td>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Operadores, Parceiros e fornecedores de serviços
                        essenciais para o desenvolvimento das atividades do
                        Fronteiras do Pensamento.
                    </p>
                </td>
            </tr>
            <tr>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Proteção de Dados
                    </p>
                </td>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Medidas de segurança, técnicas e administrativas
                        adequadas.
                    </p>
                </td>
            </tr>
            <tr>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Seus direitos
                    </p>
                </td>
                 <td style={{border:'1px solid #333'}}>
                    <p dir="ltr">
                        Confirmação da existência de tratamento, acesso,
                        correção, anonimização, exclusão, portabilidade,
                        consentimento e declaração completa.
                    </p>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<br/>
<p dir="ltr">
    O Fronteiras do Pensamento encoraja os Usuários a rever este documento
    periodicamente para estar informado de como está protegendo suas
    informações.
</p>
<br/>
<p dir="ltr">
<strong>1. QUAIS DADOS O FRONTEIRAS DO PENSAMENTO UTILIZA E PARA QUAL FINALIDADE?</strong>
</p>
<br/>
<p dir="ltr">
    O Fronteiras do Pensamento poderá coletar os dados pessoais inseridos
    ativamente pelo Usuário que variam conforme o uso que faz dos seus
    produtos, ou seja, serão coletados dados caso seja assinante dos seus
    produtos, participe de algum evento ou promoção, ou se estiver apenas
    visitando seus sites ou acessando de forma online seus conteúdos e, ainda,
    dados pessoais coletados automaticamente quando da utilização das páginas e
    da rede, como, por exemplo, identificação da página acessada, IP com data e
    hora da conexão, entre outras.
</p>
<br/>
<p dir="ltr">
    Para tornar mais acessível essas diferenças, listamos abaixo algumas
    finalidades para as quais os Dados dos Usuários podem ser utilizados
    dependendo do tipo de interação que faz com os produtos do Fronteiras do
    Pensamento, como segue:
</p>
<br/>
<p dir="ltr">
    (i) Dados Pessoais fornecidos pelo Usuário para Envio de Conteúdos e/ou
    Newsletter: O Fronteiras do Pensamento trata todos os dados pessoais
    inseridos ativamente pelo Usuário nas Páginas, tais como, nome completo
    e/ou e-mail, quando do preenchimento de formulários nas Páginas pelo
    Usuário. O Fronteiras do Pensamento fará uso desses dados pessoais para
    fornecimento de informações e conteúdo, para a criação de cadastros para
    contato, bem como para o oferecimento de promoções, descontos e/ou
    benefícios do Fronteiras do Pensamento ou de seus parceiros comerciais, de
    cursos, produtos, subprodutos, e/ou serviços quaisquer relacionados aos
    projetos Fronteiras.
</p>
<br/>
<p dir="ltr">
    Base Legal: Nestes casos, o tratamento de dados é autorizado pelo inciso I
    do artigo 7º da Lei nº 13.709/2018, a Lei Geral de Proteção de Dados (“Lei
    Geral de Proteção de Dados – LGPD”).
</p>
<br/>
<p dir="ltr">
    (ii) Dados Pessoais fornecidos pelo Usuário para Contato: O Fronteiras do
    Pensamento trata todos os dados pessoais inseridos ativamente pelo Usuário
    nas Páginas, tais como, nome completo, celular, e-mail, estado, quando do
    preenchimento de formulários nas Páginas pelo Usuário. O Fronteiras do
    Pensamento fará uso desses dados pessoais para fornecimento de informações,
    para a criação de cadastros para contato, bem como para o oferecimento de
    promoções, descontos e/ou benefícios do Fronteiras do Pensamento ou de seus
    parceiros comerciais, de cursos, produtos, subprodutos, e/ou serviços
    quaisquer relacionados aos projetos Fronteiras.
</p>
<br/>
<p dir="ltr">
    Base Legal: Nestes casos, o tratamento de dados é autorizado pelo inciso I
    do artigo 7º da Lei nº 13.709/2018, a Lei Geral de Proteção de Dados (“Lei
    Geral de Proteção de Dados – LGPD”).
</p>
<br/>
<p dir="ltr">
    (iii) Dados Pessoais fornecidos pelo Usuário para Participar de Evento
    Gratuito: O Fronteiras do Pensamento trata todos os dados pessoais
    inseridos ativamente pelo Usuário nas Páginas, tais como, nome completo,
    celular, e-mail, login e senha para acesso ao evento online, quando do
    preenchimento de formulários nas Páginas pelo Usuário. O Fronteiras do
    Pensamento fará uso desses dados pessoais para fornecimento de informações,
    para a criação de cadastros para contato, bem como para o oferecimento de
    promoções, descontos e/ou benefícios do Fronteiras do Pensamento ou de seus
    parceiros comerciais, de cursos, produtos, subprodutos, e/ou serviços
    qualquer relacionados aos projetos Fronteiras, desde que autorizado pelo
    Usuário.
</p>
<br/>
<p dir="ltr">
    Base Legal: Nestes casos, o tratamento de dados é autorizado pelo inciso I
    do artigo 7º da Lei nº 13.709/2018, a Lei Geral de Proteção de Dados (“Lei
    Geral de Proteção de Dados – LGPD”).
</p>
<br/>
<p dir="ltr">
    (iv) Dados Pessoais fornecidos pelo Usuário para Contratação dos Produtos
    e/ou Serviços: O Fronteiras do Pensamento trata todos os dados pessoais,
    mesmo que preenchidos de forma parcial, inseridos ativamente pelo Usuário
    nas Páginas, tais como, nome completo, endereço, CPF, RG, telefone celular,
    e-mail, login e senha para acesso aos produtos digitais, além dos dados de
    cobrança, conforme fornecidos no momento da contratação, quando do
    preenchimento de formulários nas Páginas pelo Usuário. O Fronteiras do
    Pensamento fará uso desses dados pessoais para acesso aos Produtos e
    Serviços contratados, criação de cadastro, para fornecimento de
    informações, para a criação de cadastros para contato, bem como para o
    oferecimento de promoções, descontos e/ou benefícios do Fronteiras do
    Pensamento ou de seus parceiros comerciais, de cursos, produtos,
    subprodutos, e/ou serviços qualquer relacionados aos projetos Fronteiras,
    desde que autorizado pelo Usuário.
</p>
<br/>
<p dir="ltr">
    Base Legal: Nestes casos, enquanto não finalizado o preenchimento dos
    dados, o tratamento de dados é autorizado pelo inciso I do artigo 7º da Lei
    nº 13.709/2018, a Lei Geral de Proteção de Dados (“Lei Geral de Proteção de
    Dados – LGPD”), encerrada inserção dos dados nas Páginas, o tratamento de
    dados é autorizado pelo inciso V do artigo 7º igualmente da Lei nº
    13.709/2018.
</p>
<br/>
<p dir="ltr">
    (v) Dados tratados automaticamente: O Fronteiras do Pensamento também trata
    dados pessoais de modo automático, tais como: características do
    dispositivo de acesso, do navegador, IP (com data e hora), origem do IP,
    dados sobre cliques, páginas acessadas, ou termo de procura digitado nos
    sites de buscas. Para tal coleta, o Fronteiras do Pensamento fará uso de
    tecnologias padrões, como cookies, pixel tags e beacons, que são utilizadas
    com o propósito de melhorar a experiência de navegação do Usuário nas
    páginas, de acordo com seus hábitos e suas preferências e para gerar
    estatísticas de acesso e melhorar a utilidade do Site.
</p>
<br/>
<p dir="ltr">
    Base Legal: Nestes casos, o tratamento de dados é autorizado pelo inciso I
    do artigo 7º da Lei nº 13.709/2018, a Lei Geral de Proteção de Dados (“Lei
    Geral de Proteção de Dados – LGPD”).
</p>
<br/>
<p dir="ltr">
    O conteúdo das informações inseridas pelo Usuário nos formulários é de
    inteira responsabilidade do Usuário, não sendo o site e/ou ao Fronteiras do
    Pensamento pela correção ou verificação dos dados pessoais do Usuário ou,
    ainda, por qualquer consequência do fornecimento de dados incorretos ou
    inverídicos pelo Usuário.
</p>
<p dir="ltr">
    Não obstante o disposto no parágrafo anterior, o Fronteiras do Pensamento
    se reserva o direito de checar a veracidade dos dados fornecidos pelo
    Usuário, bem como de solicitar informações adicionais ou documentos que
    julgue necessários para a confirmação dos dados informados. Caso o
    Fronteiras do Pensamento constate que o Usuário forneceu dados incorretos
    ou inverídicos, poderá, a seu exclusivo critério, bloquear, suspender ou
    cancelar o cadastro do Usuário.
</p>
<br/>
<p dir="ltr">
<strong>2. COMO O FRONTEIRAS DO PENSAMENTO COMPARTILHA OS DADOS PESSOAIS?</strong>
</p>
<br/>
<p dir="ltr">
    O Usuário declara ter ciência e concordar que as informações coletadas
    podem vir a ser utilizadas e compartilhadas, nos limites exigidos e
    autorizados por lei, para os seguintes propósitos e situações:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Com empresas parceiras de cursos, subprodutos, serviços ou de
            alguma forma relacionada com o projeto Fronteiras, para propósitos
            comerciais, possibilitando identificação de perfil, preferência e
            necessidades do usuário, com a finalidade de refinar a oferta de
            produtos e/ou serviços oferecidos.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Com as empresas e indivíduos contratados para a execução de
            determinadas atividades e serviços no Fronteiras do Pensamento;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Com empresas do grupo;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Com fornecedores e parceiros para consecução dos serviços
            contratados com a empresa (como tecnologia da informação,
            provedoras de infraestrutura tecnológica e operacional, provedores
            de serviço de armazenamento de informações, contabilidade,
            marketing, administradoras de cartão de crédito e sites de gerir
            pagamentos, entre outros);
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Para propósitos administrativos, como pesquisa, planejamento,
            desenvolvimento de serviços, segurança e gerenciamento de risco;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Quando necessário, em decorrência de obrigação legal, determinação
            de autoridades competentes, requerimento de autoridades
            administrativas que detenham competência legal para sua requisição,
            ou decisão judicial.
        </p>
    </li>
</ol>
<p dir="ltr">
    Nas hipóteses de compartilhamento de dados pessoais com terceiros, todos os
    sujeitos mencionados nos de itens l a VI deverão utilizar os dados de
    maneira consistente e de acordo com os propósitos para os quais foram
    coletados, em consonância com o que foi determinado por esta Política de
    Privacidade, bem como todas as leis de privacidade e proteção de dados
    aplicáveis.
</p>
<p dir="ltr"><br/>
<strong> 3. COMO UTILIZAMOS OS COOKIES</strong>
</p>
<p dir="ltr">
    As informações são recebidas e armazenadas automaticamente nos servidores
    da empresa mediante a utilização de cookies. Cookies são pequenos arquivos
    de texto que são enviados e armazenados no computador do Usuário, com a
    finalidade de reconhecer, acompanhar, identificar os dados de navegação,
    personalizando o acesso.
</p>
<br/>
<p dir="ltr">
    O Usuário pode controlar os dados armazenados em cookies e retirar seu
    consentimento usando os controles de segurança e privacidade nativos de
    cada navegador.
</p>
<br/>
<p dir="ltr">
<strong> 4. COMO O FRONTEIRAS DO PENSAMENTO MANTÉM OS DADOS SEGUROS?</strong>
</p>
<br/>
<p dir="ltr">
    O Fronteiras do Pensamento armazenará os dados pessoais tratados em
    servidores próprios ou por ele contratados, sendo mantida sua privacidade,
    devidamente de acordo com a legislação de dados vigente.
</p>
<p dir="ltr">
    A empresa se compromete a utilizar tecnologia suficientemente adequada para
    a proteção dos dados, mantendo o ambiente seguro, com o uso de ferramentas
    e procedimentos de segurança para proteger a confidencialidade e
    integridade de seus dados pessoais, prevenindo a ocorrência de eventuais
    danos em virtude do tratamento desses dados. No entanto, embora a empresa
    utilize medidas de segurança e monitore seu sistema para verificar
    eventuais vulnerabilidades e ataques para proteger suas informações contra
    divulgação não autorizada, o Usuário entende e concorda que não há
    garantias de que as informações não poderão ser acessadas, divulgadas,
    alteradas ou destruídas por violações de qualquer uma das proteções
    físicas, técnicas ou administrativas.
</p>
<br/>
<p dir="ltr">
<strong>  5. RETENÇÃO DOS DADOS PESSOAIS TRATADOS</strong>
</p>
<br/>
<p dir="ltr">
    Os Dados Pessoais tratados pelo Fronteiras do Pensamento serão excluídos
    quando finalizado o período de tratamento, ou quando o titular dos dados
    solicitar a exclusão.
</p>
<p dir="ltr">
    Sem prejuízo, os Dados Pessoais poderão ser conservados para cumprimento de
    obrigação legal ou regulatória, transferência a terceiro – desde que
    respeitados os requisitos de tratamento de dados – e uso exclusivo do
    Fronteiras do Pensamento.
</p>
<p dir="ltr"><br/>
<strong>  6. SEUS DIREITOS</strong>
</p>
<p dir="ltr">
    Em cumprimento à regulamentação aplicável, no que diz respeito ao
    tratamento de dados pessoais, o Fronteiras do Pensamento respeita e garante
    aos Usuários, a possibilidade de apresentação de solicitações baseadas nos
    seguintes direitos:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            a confirmação da existência de tratamento;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            o acesso aos dados;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            a correção de dados incompletos, inexatos ou desatualizados;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            a anonimização ou eliminação de dados desnecessários, excessivos ou
            tratados em desconformidade;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            a portabilidade de seus dados a outro fornecedor de serviço ou
            produto, mediante requisição expressa pelo Usuário;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            a eliminação dos dados tratados com consentimento do Usuário;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            a obtenção de dados pessoais sobre as entidades públicas ou
            privadas com as quais a Elo compartilhou seus dados;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            a informação sobre a possibilidade de não fornecer o seu
            consentimento, bem como de ser informado sobre as consequências, em
            caso de negativa;
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            a revogação do consentimento.
        </p>
    </li>
</ol>
<br/>
<p dir="ltr">
    O Fronteiras do Pensamento empreenderá todos os esforços para atender tais
    pedidos no menor espaço de tempo possível. No entanto, mesmo em caso de
    requisição de exclusão, será respeitado o prazo de armazenamento mínimo de
    informações de Usuários de aplicações de Internet, determinado pela
    legislação brasileira.
</p>
<br/>
<p dir="ltr">
<strong> 7. COMO FALAR COM O ENCARREGADO DE DADOS (DPO) DO FRONTEIRAS DO PENSAMENTO?</strong>
</p>
<br/>
<p dir="ltr">
    Se você acredita que seus Dados Pessoais foram usados de maneira
    incompatível com esta Política de Privacidade e Proteção de Dados ou com as
    suas escolhas enquanto titular destes Dados Pessoais, ou, ainda, se você
    tiver outras dúvidas, comentários ou sugestões relacionados a esta
    Política, você pode entrar em contato com o Encarregado de Dados (DPO) do
    Fronteiras do Pensamento através do e-mail:
    <a href="mailto:dpo@fronteirasdopensamento.com.br">
        dpo@fronteiras.com.br
    </a>
    <a href="mailto:privacidade@gruporbs.com.br">.</a>
</p>
<p dir="ltr"><br/>
<strong> 8. LEGISLAÇÃO E FORO</strong>
</p>
<p dir="ltr">
    Esta Política de Privacidade e Proteção de Dados será regida, interpretada
    e executada de acordo com as Leis da República Federativa do Brasil,
    especialmente a Lei nº 13.709/2018, independentemente das Leis de outros
    estados ou Países, sendo competente o foro da Comarca de São Paulo, para
    dirimir quaisquer controvérsias decorrente deste documento.
</p>
<div>
    <br/>
</div>
    </div>
      </ContainerWhite>
    
  );
};
export default Termos;
