import React, { useState, useEffect } from 'react';

import { AnimatedDiv } from './styles';

interface ITextPopperProps {
  messages: Array<string>;
  time: number;
  group?: boolean;
  direction?: 'up' | 'down' | 'left' | 'right' | 'fade';
  keyName?: string;
}

const TextPopper: React.FC<ITextPopperProps> = ({
  messages,
  time = 1000,
  group = false,
  direction = 'down',
  keyName = 'oi',
}) => {
  const [singleText, setSingleText] = useState<Array<string>>(
    [messages[0]] || [],
  );

  useEffect(() => {
    const length = messages.length - 1;
    let x = 0;

    const interval = setInterval(() => {
      if (x === 0) {
        setSingleText([messages[x]]);
      } else if (!messages[x]) {
        setSingleText(state => [...state, ` `]);
      } else if (group) {
        setSingleText(state => [...state, `${messages[x]}`]);
      } else {
        setSingleText([messages[x]]);
      }

      if (x === length) {
        x = 0;
      } else {
        x += 1;
      }
    }, time);

    return () => {
      setSingleText([]);
      clearInterval(interval);
    };
  }, [messages, keyName]);

  const Ani: React.FC = () => {
    const size = singleText.length - 1;
    return (
      <div
        style={{
          position: 'absolute',
          top: '0',
          right: '-50%',
          height: '46px',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          flexWrap: 'wrap',
          width: '200%',
        }}
      >
        {singleText.map((single, index) => (
          <AnimatedDiv
            style={{ minWidth: '4px' }}
            direction={direction}
            key={`message_${index}`}
          >
            {single}
          </AnimatedDiv>
        ))}
      </div>
    );
  };

  return <Ani />;
};

export default TextPopper;
