import React, { useState, useEffect, useCallback, useRef } from 'react';
import { format, parseISO } from 'date-fns';

import { MdRemoveCircle } from 'react-icons/md';

import { useField } from '@unform/core';
import {
  FaArrowUp,
  FaArrowDown,
  FaArrowCircleUp,
  FaArrowCircleDown,
} from 'react-icons/fa';
import api from '../../../../../../services/api';


import { Side, MainContainer, Container } from './styles';
import { array } from 'yup';

interface ISearchItems {
  title: string;
}

interface IJsonInputContainer {
  searchRender?: Function;
  bodyRender?: Function;
  storageType: 'id' | 'json';
  endpoint: string;
  name: string;
}

const formattedDate = (date: string): string =>
  format(parseISO(date), 'dd/MM/yyyy');

const JsonInput: React.FC<IJsonInputContainer> = ({
  endpoint,
  name,
  bodyRender = (data: Record<string, any>) => <nav>{data.title}</nav>,
  searchRender = (data: Record<string, any>) => <>{data.title}</>,
  storageType = 'id',
}) => {
  const [searchItems, setSearchItems] = useState<Array<ISearchItems>>([]);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [currentItems, setCurrentItems] = useState<Array<Record<string, any>>>(
    [],
  );

  const [currentItemsValues, setCurrentItemsValues] = useState<Array<Record<string,any>>>([]);


  const selectRef = useRef(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: undefined,
 
      getValue: (ref: any) => {
        const i : Array<Number> = [];

        currentItems.map( c =>{
          i.push(storageType === 'id' ? c.id : c);
        })

        return JSON.stringify(i);
      },
    });
  }, [fieldName, registerField, currentItems]);

  const search = useCallback(
    async value => {
      const response = await api.get(`${endpoint}`, {
        params: { search: value, limitItems: 10000, order: {orderBy:'id', direction: 'asc'} },
      });
      const { data } = response;

      setSearchItems(data.hasOwnProperty('rows') ? data.rows : data);
    },
    [endpoint, name],
  );

  const addToSalesCourses = (item: Record<string, any>): void => {
  
    setCurrentItems( state => [...state, item]);
    setCurrentItemsValues(state => [...state, item.id]);
  };

  const removeFromSalesCourses = (index: number): void => {
    const SalesCoursess = currentItems.filter(
      (item, indexSalesCourses, array) => {
        return array.indexOf(item) !== index;
      },
    );

    const SalesCoursesValues = currentItemsValues.filter(
      (item, indexSalesCourses, array) => {
        return array.indexOf(item) !== index;
      },
    );

    setCurrentItems([...SalesCoursess]);
    setCurrentItemsValues([...SalesCoursesValues]);
  };

  useEffect(() => {
    let updateItems: Array<Record<string, any>> = [];
    if (defaultValue && defaultValue.length > 0) {
      const load = async () => {
        const row = typeof defaultValue === 'string' ? JSON.parse(defaultValue): defaultValue;

        if (storageType === 'id') {
    
          await Promise.all(
            row.map(async (findData: number & Record<string, any>) => {
         
              const response = await api.get(`${endpoint}`, {
                params: { where : {id: findData.id ? findData.id : findData}, limitItems: 10000, order: {orderBy:'id', direction: 'asc'} },
              });

              const getResponse = response.data.rows ? response.data.rows : response.data;

              if (getResponse) {
           
               
                updateItems = [...updateItems, ...getResponse];
              }
            }),
          );
        } else {
       
          updateItems = row;
        }

        setCurrentItems([...currentItems, ...updateItems]);
      };
      load();
    }
  }, []);

  useEffect(() => {
    search('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClearCourses(): void {
    setCurrentItems([]);
    setCurrentItemsValues([]);
  }

  const goUp = index => {
    const indexup = index - 1;

    if (currentItemsValues[indexup]) {
      const newCurrent = [...currentItemsValues];

      newCurrent[index] = currentItemsValues[indexup];
      newCurrent[indexup] = currentItemsValues[index];

      setCurrentItemsValues([...newCurrent]);
      setCurrentItems([...newCurrent]);
    }
  };

  const goDown = index => {
    const indexup = index + 1;

    if (currentItemsValues[indexup]) {
      const newCurrent = [...currentItemsValues];

      newCurrent[index] = currentItemsValues[indexup];
      newCurrent[indexup] = currentItemsValues[index];

      setCurrentItemsValues([...newCurrent]);
      setCurrentItems([...newCurrent]);
    }
  };

  const goAllUp = index => {
    if (currentItemsValues[index]) {
      const newCurrent = [...currentItemsValues];
      const content = { ...newCurrent[index] };

      newCurrent.splice(index, 1);

      newCurrent.unshift(content);

      setCurrentItemsValues([...newCurrent]);
      setCurrentItems([...newCurrent]);
    }
  };

  const goAllDown = index => {
    if (currentItemsValues[index]) {
      const newCurrent = [...currentItemsValues];

      const content = newCurrent[index];

      newCurrent.splice(index, 1);

      newCurrent.push(content);

      setCurrentItemsValues([...newCurrent]);
      setCurrentItems([...newCurrent]);
    }
  };

  return (
    <Container>
      <Side>
        <input
          autoComplete="off"
          name="search"
          onChange={e => search(e.target.value)}
          placeholder="Ex: Curso"
          type="text"
        />

        <div>
          {searchItems.map(el => (
            <button type="button" style={{display:'flex', flexDirection:'column'}} onClick={() => addToSalesCourses(el)}>
              {searchRender(el)}
            </button>
          ))}
        </div>
      </Side>
      <MainContainer>
        <button type="button" onClick={handleClearCourses}>
          Limpar Lista
        </button>
        <div>
          <input
            name={name}
            style={{ display: 'none' }}
            value={JSON.stringify(
              storageType === 'id' ? currentItemsValues : currentItems,
            )}
            ref={selectRef}
          />
          {currentItems.map((item, index) => {
            
            return (
              <nav>
                <div className="main">{bodyRender(item)}</div>
                <div className="modules">
                  <MdRemoveCircle
                    title="Remover"
                    onClick={() => removeFromSalesCourses(index)}
                  />
                  <FaArrowCircleUp
                    title="Remover"
                    onClick={() => goAllUp(index)}
                  />
                  <FaArrowUp title="Remover" onClick={() => goUp(index)} />
                  <FaArrowDown title="Remover" onClick={() => goDown(index)} />
                  <FaArrowCircleDown
                    title="Remover"
                    onClick={() => goAllDown(index)}
                  />
                </div>
              </nav>
            );
          })}
        </div>
      </MainContainer>
    </Container>
  );
};

export default JsonInput;
