import React, { useEffect, useState } from 'react';

import Gallery from '../Gallery';
import { AnimatedDiv, Fixed } from './styles';
import { urlWebsite } from '../../config';

import Vimeo from '../Vimeo';
import Youtube from '../Youtube';
import ChatComponent from '../Chat';

interface IPhoto {
  title?: string;
  video_id: string;
  type_id: string;
  className?: string;
  chat_id ?: string;
}

interface IData {
  data: Array<IPhoto>;
  targetOpen?: string;
}

const VideoGallery: React.FC<IData> = ({ data, targetOpen }) => {
  const [currentImage, setCurrentImage] = useState(-1);
  const [dataItems, setDataItems] = useState<Array<IPhoto>>(data);
  const [current, setCurrent] = useState(0);
  const [fixed, setFixed] = useState(false);
  const dataSize = dataItems.length - 1;

  useEffect(() => {
    setDataItems(data)
  },[data])

  const handleFixed = index => {
    setCurrent(index);
    setCurrentImage(index);
    setFixed(true);
  };


  return (
    <>
{fixed && (
        <Fixed>
          <div className="imageContent"><div style={{width:'100%', maxWidth: '1000px', display: 'flex', flexDirection: 'column', justifyContent:'flex-start', alignItems: 'center'}}>
          {dataItems?.[current]?.title ? <h2 style={{textAlign:'center'}}>{dataItems?.[current]?.title}</h2> : ''}
         
          {  dataItems?.[current]?.type_id === '1' ? <Youtube id={dataItems?.[current]?.video_id} /> : <Vimeo id={dataItems?.[current]?.video_id}/>}
          </div>
           <div style={{maxWidth:'400px'}}>
           {dataItems?.[current]?.chat_id  && <ChatComponent id={dataItems[current].chat_id}/> }
           </div>
            
          </div>


         
          <div className="buttons">
            <button
              type="button"
              onClick={() =>
                handleFixed(dataItems[current - 1] ? current - 1 : dataSize)}
            >
              Anterior
            </button>
            <button type="button" onClick={() => setFixed(false)}>
              Fechar
            </button>
            <button
              type="button"
              onClick={() =>
                handleFixed(dataItems[current + 1] ? current + 1 : 0)}
            >
              Próxima
            </button>
          </div>
        </Fixed>
      )}

      {data && 
      <>
      <Gallery>
        {dataItems.map((video, index) => (
           video?.video_id ? 
          <AnimatedDiv className="video-gallery"
            key={`${urlWebsite}/${video.video_id}`}
            
          >
            <aside className="hoverDiv" onClick={() => { setCurrent(index); setFixed(true)}}/>
            {  video.type_id === '1' ? <Youtube id={video.video_id} /> : <Vimeo id={video.video_id}/>}

            {video.title && <p>{video.title}</p>}
          </AnimatedDiv> : <></>
        ))}
      </Gallery></>
}
    </>
  );
};
export default VideoGallery;
