import React from 'react';
import { Container, Body, Content, Footer, Header } from './styles';
import { useModal } from '../../../hooks/Modal';
import AnimatedDiv from '../../AnimatedDiv';

interface IModalProps {
  title: string;
  key: string;
  content: JSX.Element;
  className : string;
}

const ModalContainer: React.FC<IModalProps> = ({
  key,
  content,
  title = '',
  className = ''
}) => {
  const { removeModal } = useModal();

  return (
    <Container >
      <Body className={className}>
        <Header>{title}</Header>
        <Content>{content}</Content>
        <Footer>
          <button type="button" onClick={() => removeModal(key)}>
            Fechar
          </button>
        </Footer>
      </Body>
    </Container>
  );
};

export default ModalContainer;
