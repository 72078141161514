import React, { useEffect } from 'react';
import { Wrapper, Background } from './styles';

import { Menu } from '../../../components/Menu';

import { useAuth } from '../../../hooks/Auth';
import FooterLayout from '../../Footer';
import PopupComponent from '../../../components/PopupContainer';
import { RoomChangeContainer } from '../../../components/RoomChange';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
const menuList = [
  {
    title: 'Home',
    target: '/',
    icon: '',
    external: 2,
    fluid: 1,
    targetID: '',
    status: 2,
  },
  {
    title: 'Poster',
    target: '/poster',
    icon: '',
    fluid: 1,
    external: 1,
    targetID: '',
    status: 2,
  },
  {
    title: 'Palestras',
    target: '/dashboard',
    targetID: '',
    fluid: 1,
    icon: '',
    status: 2,
  },
  {
    title: 'Gerenciador',
    target: '/manager',
    icon: '',
    fluid: 1,
    targetID: '',
    status: 1,
  },
];

const DefaultLayout: React.FC = ({ children }) => {


  return (
    <>
     <Wrapper>
      {children}
      
      <PopupComponent />
      <RoomChangeContainer />
      </Wrapper>
      <FooterLayout />
    </>
  );
};

export default DefaultLayout;
