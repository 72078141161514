import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import whatsIcon from '../../assets/whats.png';

import { Whats } from './styles';

interface IWhatsapp {
  number?: string;
  text?: string;
  message?: string;
  iconSize?: string;
  fontSize?: string;
  iconColor?: string;
  className?: string;
}

const Whatsapp: React.FC<IWhatsapp> = ({
  number = '5551981476007',
  text = 'Oi, estou em seu website!',
  message = 'Tire suas dúvidas pelo WhatsApp',
  iconSize = '30px',
  fontSize = '16px',
  iconColor = '#fff',
  className = ''
}) => {
  return (
    <Whats target="_BLANK"
      iconSize={iconSize}
      fontSize={fontSize}
      className={className}
      href={`https://api.whatsapp.com/send?l=pt&phone=${number}&text=${encodeURI(
        text,
      )}`}
   
    >
      <FaWhatsapp size={20}
        style={{color: iconColor}}
      />
      {message}
    </Whats>
  );
};

export default Whatsapp;
