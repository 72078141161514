import React, { useEffect } from 'react';
import { Wrapper, Background } from './styles';

import { Menu } from '../../../components/Menu';

import { useAuth } from '../../../hooks/Auth';
import FooterLayout from '../../Footer';
import PopupComponent from '../../../components/PopupContainer';
import { RoomChangeContainer } from '../../../components/RoomChange';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';
const menuList = [
  {
    title: 'Home',
    target: '/',
    icon: '',
    external: 2,
    fluid: 1,
    targetID: '',
    status: 2,
  },
  {
    title: 'Poster',
    target: '/poster',
    icon: '',
    fluid: 1,
    external: 1,
    targetID: '',
    status: 2,
  },
  {
    title: 'Palestras',
    target: '/dashboard',
    targetID: '',
    fluid: 1,
    icon: '',
    status: 2,
  },
  {
    title: 'Gerenciador',
    target: '/manager',
    icon: '',
    fluid: 1,
    targetID: '',
    status: 2,
  },
];

const DefaultLayout: React.FC = ({ children }) => {


  return (
    <>
     <Wrapper> <Menu menuList={menuList} fixedMenu={false} />
      {children}  <Particles
        params={{
          particles: {
            lineLinked: {
              enable: false,
            },
            move: {
              speed: 1,
              direction: 'top-right',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },

             
            },
            opacity: {
              value: 1,
              random: false,
              animation: {
                enable: false
              }

            },
            shape: {
              type: 'circle',
              polygon: {
               
                nb_sides: 6,
                
              },
           
            },
     
            color: { value: ['#f9c771','#9e5313'] },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 200,
            },
            size: {
              value: 1,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
      />
      <FooterLayout />
      <PopupComponent />
      <RoomChangeContainer />
      </Wrapper>
      <Particles
        params={{
          particles: {
            lineLinked: {
              enable: false,
            },
            move: {
              speed: 1,
              direction: 'top',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },

             
            },
            opacity: {
              value: 1,
              random: false,
              animation: {
                enable: false
              }

            },
            shape: {
              type: 'circle',
              polygon: {
               
                nb_sides: 6,
                
              },
           
            },
     
            color: { value: '#d53503' },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 60,
            },
            size: {
              value: 8,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
      />
    </>
  );
};

export default DefaultLayout;
