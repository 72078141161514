import styled from 'styled-components';
import {ContainerRef} from '../styles';

export const Container = styled(ContainerRef)`
width: 100%;
>div{
  width:100%;
}

.react-select__menu {
    text-align: left;
    justify-content: left;
    color: #333;
  }

  .react-select__control{
    border: 0px;
    background: none;
  }

  .react-select__single-value{
    color: #333;
  }

`;
