import React from 'react';
import { Wrapper } from './styles';
import { Menu } from '../../../components/Menu';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import { useAuth } from '../../../hooks/Auth';
import PopupComponent from '../../../components/PopupContainer';
import { RoomChangeContainer } from '../../../components/RoomChange';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../../components/ImageBackgroundSlider';

const DefaultLayout: React.FC = ({ children }) => {
  const page = window.location.pathname !== '/';
  const { menu } = usePages();

  const { token, user } = useAuth();




  return (
    <>
      <Wrapper>
      {/*  <Menu menuList={menu} fixedMenu={false} /> */}
        {children}
   
        <PopupComponent />
        <RoomChangeContainer />
        
      </Wrapper>
      <FooterLayout />
   {/*    <Particles
        params={{
          particles: {
            lineLinked: {
              enable: false,
            },
            move: {
              speed: 1,
              direction: 'top-right',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },

             
            },
            opacity: {
              value: 1,
              random: false,
              animation: {
                enable: false
              }

            },
            shape: {
              type: 'circle',
              polygon: {
               
                nb_sides: 6,
                
              },
           
            },
     
            color: { value: ['#f9c771','#9e5313'] },

            number: {
              density: {
                enable: true,
                value_area: 2400,
              },
              value: 200,
            },
            size: {
              value: 1,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 1
        }}
      /> */}
    </>
  );
};

export default DefaultLayout;
