export const traductionList = [
  {
    ptBr: '',
    en: '',
    es: '',
  },
  
  {
    ptBr: 'O limite de participantes desta sala foi definido pelo coordenador da atividade, em função da metodologia. Caso esta sala não tenha mais capacidade, temos atividades simultânea sem restrição de público.',
    en: 'The limit of participants in this room was defined by the activity coordinator, depending on the methodology. If this room no longer has capacity, we have simultaneous activities with no audience restriction.',
    es: 'El límite de participantes en esta sala fue definido por el coordinador de la actividad, en función de la metodología. Si esta sala ya no tiene capacidad, tenemos actividades simultáneas sin restricción de audiencia.',
  },
  {
    ptBr: 'Vagas esgotadas',
    en: 'The room is full',
    es: 'Vacantes agotadas',
  },
  {
    ptBr: 'Caso enfrente algum problema, clique no botão de suporte técnico',
    en: 'If you experience any problems, click on the technical support button',
    es: 'Si tiene algún problema, haga clic en el botón de soporte técnico',
  },
  {
    ptBr: 'Atividade encerrada',
    en: 'Activity closed',
    es: 'Actividad cerrada',
  },
  {
    ptBr: 'A atividade será aberta até 15 minutos antes do início',
    en: 'The activity will open up to 15 minutes before the start.',
    es: 'La actividad se abrirá hasta 15 minutos antes del inicio.',
  },
  {
    ptBr: 'Acessar sala',
    en: 'Access room',
    es: 'Sala abierta',
  },
  {
    ptBr: 'Programação encerrada',
    en: 'Schedule closed',
    es: 'Horario cerrado',
  },
  {
    ptBr: 'Atualizar página',
    en: 'Refresh page',
    es: 'Actualizar página',
  },
  {
    ptBr: 'Aguardando iniciar',
    en: 'Awaiting start',
    es: 'Esperando el comienzo',
  },
  {
    ptBr: 'Sala de Audio e Vídeo',
    en: 'Audio and Video Room',
    es: 'Sala de audio y video',
  },
  {
    ptBr: 'Se faltam menos de 15 minutos para a atividade e a sala não abriu, clique no botão atualizar página.',
    en: 'If the activity is less than 15 minutes away and the room has not opened, click the refresh page button.',
    es: 'Si faltan menos de 15 minutos para la actividad y la sala no se ha abierto, haga clic en el botón Actualizar página.',
  },
  {
    ptBr: 'A sala tem um limite de 500 pessoas.',
    en: 'The room is limited to 500 people.',
    es: 'La habitación está limitada a 500 personas.',
  },
  {
    ptBr: 'Esta sala será aberta 15 minutos antes da atividade.',
    en: 'This room will open 15 minutes before the activity.',
    es: 'Esta sala se abrirá 15 minutos antes de la actividad.',
  },
  {
    ptBr: 'Mostra Cultural',
    en: 'Cultural exhibition',
    es: 'Exposición cultural',
  },
  {
    ptBr: 'Secretaria do evento',
    en: 'Event secretary',
    es: 'Secretaria de eventos',
  },
  {
    ptBr: 'Comentários',
    en: 'Comments',
    es: 'Comentarios',
  },
  {
    ptBr: 'Enviar mensagem',
    en: 'Send Message',
    es: 'Enviar mensaje',
  },
  {
    ptBr: 'Título',
    en: 'Title',
    es: 'Título',
  },
  {
    ptBr: 'Autores',
    en: 'Authors',
    es: 'Autores',
  },
  {
    ptBr: '19 de agosto - quinta-feira',
    en: "August 19th - Thursday",
    es: '19 de agosto - jueves',
  }, 
  {
    ptBr: '20 de agosto - sexta-feira',
    en: "August 20th - Friday",
    es: '20 de agosto - viernes',
  }, 
  {
    ptBr: '21 de agosto - sábado',
    en: "August 21st - Saturday",
    es: '21 de agosto - sábado',
  }, 
  {
    ptBr: '22 de agosto - domingo',
    en: "august 22 - sunday",
    es: '22 de agosto - domingo',
  }, 
  {
    ptBr: 'Sala',
    en: "Conference room",
    es: 'Sala de conferencias',
  }, 
  {
    ptBr: 'Horário de Brasília',
    en: "Brasilia time",
    es: 'Hora de Brasilia',
  }, 
  {
    ptBr: 'Pesquisar',
    en: "Search",
    es: 'Buscar',
  }, 
  {
    ptBr: 'Todos',
    en: "All",
    es: 'Todos',
  }, 
  {
    ptBr: 'Eixo Temático',
    en: "Category",
    es: 'Tema',
  }, 
  {
    ptBr: 'Escreva para pesquisar',
    en: "Write to search",
    es: 'Escribir para buscar',
  }, 
  {
    ptBr: 'Escreva aqui a sua mensagem',
    en: "Write here your message",
    es: 'Escribe aquí tu mensaje',
  }, 
  {
    ptBr: 'Responder',
    en: "Reply",
    es: 'Respuesta',
  }, 
  {
    ptBr: 'Enviar',
    en: "Submit",
    es: 'Entregar',
  }, 
  {
    ptBr: 'Acessar sala',
    en: "Access room",
    es: 'Acceso',
  }, 
  {
    ptBr: '2. Caso enfrente algum problema, clique no botão de suporte técnico',
    en: "2. If you experience any problems, click on the technical support button",
    es: '2. Si tiene algún problema, haga clic en el botón de soporte técnico',
  }, 
  {
    ptBr: '1. Clique no botão "Acessar sala"',
    en: "1. Click on the Join Room button",
    es: '1. Haga clic en el botón "Unirse a la sala".',
  },
  {
    ptBr: 'Instruções',
    en: "Instructions",
    es: 'Instrucciones',
  },
  {
    ptBr: '19.08',
    en: "08/19",
    es: '19.08',
  },
  {
    ptBr: '20.08',
    en: "08/20",
    es: '20.08',
  },
  {
    ptBr: '21.08',
    en: "08/21",
    es: '21.08',
  },
  {
    ptBr: '22.08',
    en: "08/22",
    es: '22.08',
  },

  {
    ptBr: '19/08',
    en: "08/19",
    es: '19/08',
  },
  {
    ptBr: '20/08',
    en: "08/20",
    es: '20/08',
  },
  {
    ptBr: '21/08',
    en: "08/21",
    es: '21/08',
  },
  {
    ptBr: '22/08',
    en: "08/22",
    es: '22/08',
  },
  {
    ptBr: 'Finalizado',
    en: "Finished",
    es: 'Terminado',
  },
  {
    ptBr: 'Finalizado',
    en: "Finished",
    es: 'Terminado',
  },
  {
    ptBr: 'Ao vivo',
    en: "Live",
    es: 'En vivo',
  },
  {
    ptBr: 'Em breve',
    en: "Soon",
    es: 'Pronto',
  },

  {
    ptBr: 'Todos',
    en: "Todos",
    es: 'TODAS LAS CONFERENCIAS',
  },
  {
    ptBr: 'TODAS AS SALAS',
    en: "ALL ROOMS",
    es: 'TODAS LAS CONFERENCIAS',
  },
  {
    ptBr: 'FAVORITOS',
    en: "FAVORITES",
    es: 'FAVORITOS',
  },

  {
    ptBr: 'SAIR',
    en: "LOG OUT",
    es: 'SALIR',
  },

  {
    ptBr: 'PALESTRAS',
    en: "CONFERENCE ROOM",
    es: 'AREA DE CONFERENCIAS',
  },

  {
    ptBr: 'TRABALHOS',
    en: "POSTERS",
    es: 'CARTELES',
  },

  
  {
    ptBr: 'PROGRAMAÇÃO',
    en: "SCHEDULE",
    es: 'CALENDARIO',
  },

  {
    ptBr: 'E-mail, CPF (somente números) ou Passaporte',
    en: "E-mail, CPF (numbers only) or Passport",
    es: 'Correo electrónico, CPF (solo números) o pasaporte',
  },
  {
    ptBr: 'Voltar para login',
    en: "Back to login",
    es: 'Volver',
  },
  {
    ptBr: 'Já sou inscrito',
    en: "I'm already subscribed",
    es: 'Ya estoy suscrito',
  },
  {
    ptBr: 'Olá! Acabei de me inscrever no Global Stroke. Inscreva-se também!. https://globalstroke.encontrodigital.com.br',
    en: "Hi! I just signed up for Global Stroke. Sign up too !. https://globalstroke.encontrodigital.com.br",
    es: '¡Hola! Me acabo de registrar en Global Stroke. ¡Regístrate también!. https://globalstroke.encontrodigital.com.br',
  },
  {
    ptBr: 'Organização',
    en: "Organization",
    es: 'Organización',
  },
  {
    ptBr: 'Apoio',
    en: "Collaborators",
    es: 'Colaboradores',
  },
  {
    ptBr: 'Patrocinadores',
    en: "Sponsors",
    es: 'Patrocinadores',
  },

{
  ptBr: 'Vamos caminhar juntos para a excelência',
  en: "Let's walk together towards excellence",
  es: 'Caminemos juntos hacia la excelencia',
},
{
  ptBr: 'Inscreva seu centro!',
  en: "Register your center!",
  es: 'Postula tu centro',
},

{
  ptBr: 'Como inscrever o seu AVC Center - passo a passo',
  en: "How to register your AVC Center - step by step",
  es: 'Como postular su Centro de AVC - paso a paso',
},

{
  ptBr: 'Abertura da plataforma para aplicativos',
  en: "Opening the platform for applications",
  es: 'Apertura de la plataforma para solicitudes',
},
  {
    ptBr: 'Compra realizada com sucesso!',
    en: "Purchase successful!",
    es: 'Compra exitosa!',
  },
  {
    ptBr: 'Finalizar Compra',
    en: "Checkout",
    es: 'Revisa',
  },
  {
    ptBr: 'Parcelas',
    en: "installments",
    es: 'Cuotas',
  },
  {
    ptBr: 'Validade',
    en: "Validity (DD/MM)",
    es: 'Validad',
  },
  {
    ptBr: 'Nome no Cartão',
    en: "Credit Card Name",
    es: 'Nombre en la tarjeta',
  },
  {
    ptBr: 'Número do Cartão',
    en: "Credit Card Number",
    es: 'Numero de tarjeta',
  },
  {
    ptBr: 'Emitir como',
    en: "Issue as",
    es: 'Emitir como',
  },
  {
    ptBr: 'Entidade / Pessoa Jurídica',
    en: "Company",
    es: 'Entidad / Entidad legal',
  },
  {
    ptBr: 'Pessoa Física',
    en: "Person",
    es: 'Persona física',
  },
  {
    ptBr: 'Revisar meu Cadastro',
    en: "Update my profile",
    es: 'Hola, estoy finalizando la compra el',
  },
  {
    ptBr: 'Olá, estou finalizando a compra no',
    en: "Hello, I'm finalizing the purchase on",
    es: '',
  },


  {
    ptBr: 'Ou no link abaixo',
    en: 'Or the link below',
    es: 'O el enlace de abajo',
  },
  {
    ptBr: 'Clique no botão abaixo para acessar o boleto.',
    en: 'Click the button below to access the boleto.',
    es: 'Haga clic en el botón de abajo para acceder al boleto.',
  },
  {
    ptBr: 'Erro',
    en: 'Error',
    es: 'Error',
  },
  
  {
    ptBr: 'Erro ao efetuar pagamento',
    en: 'Error making payment',
    es: 'Error al realizar el pago',
  },
  {
    ptBr: 'Para acessar a plataforma, clique no botão abaixo',
    en: 'To access the platform, click the button below',
    es: 'Para acceder a la plataforma, haga clic en el botón de abajo',
  },
  {
    ptBr: 'Sua compra foi confirmada!',
    en: 'Your purchase has been confirmed!',
    es: '¡Tu compra ha sido confirmada!',
  },
  {
    ptBr: 'Verifique o número do seu cartão',
    en: 'Check your credit card number',
    es: 'Verifique su número de tarjeta',
  },
  {
    ptBr: 'Cartão inválido',
    en: 'Invalid Credit card',
    es: 'Tarjeta no valida',
  },
  {
    ptBr: 'Cartão de Crédito',
    en: 'Credit card',
    es: 'Tarjeta de crédito',
  },
  {
    ptBr: 'Carregando',
    en: 'Loading',
    es: 'Cargando',
  },
  {
    ptBr: 'Boleto',
    en: 'Bank slip',
    es: 'Billete',
  },

  {
    ptBr: 'Suporte Técnico',
    en: 'Technical support',
    es: 'Soporte técnico',
  },

  {
    ptBr: 'Forma de Pagamento',
    en: 'Payment Method',
    es: 'Forma de pago',
  },


  {
    ptBr: 'Login ou senha inválidos',
    en: 'Invalid email or password',
    es: 'Error de autenticación',
  },

  {
    ptBr: 'Erro na autenticação',
    en: 'Authentication error',
    es: '',
  },


  {
    ptBr: 'Obrigatório',
    en: 'Mandatory',
    es: 'Pasaporte',
  },

  {
    ptBr: 'Passaporte',
    en: 'Passport Number',
    es: '',
  },

  {
    ptBr: 'Outro',
    en: 'Other',
    es: 'Continuar',
  },

  {
    ptBr: 'Continuar',
    en: 'Next',
    es: '',
  },


  {
    ptBr: 'Senha',
    en: 'Password',
    es: 'Contraseña',
  },


  {
    ptBr: 'Bairro',
    en: 'Neighborhood',
    es: 'Barrio',
  },

  {
    ptBr: 'Número / Apartamento',
    en: 'Number',
    es: 'Dirección',
  },
  
  {
    ptBr: 'Endereço',
    en: 'Address',
    es: '',
  },
  {
    ptBr: 'CEP',
    en: 'Zipcode',
    es: 'Código postal',
  },
  {
    ptBr: 'CNPJ da Empresa / Entidade',
    en: 'Company Identification Number',
    es: 'Número de identificación de la empresa',
  },
  {
    ptBr: 'Empresa / Entidade',
    en: 'Company / Entity',
    es: 'Empresa / Entidad',
  },


  {
    ptBr: 'Data de Nascimento (DD/MM/AAAA)',
    en: 'Date of birth (DD/MM/YYYY)',
    es: 'Fecha de nacimiento (dd / mm / aaaa)',
  },

  {
    ptBr: 'Estado',
    en: 'State / Province / Region',
    es: 'Estado / Provincia / Región',
  },

  {
    ptBr: 'Você selecionou o idioma inglês e o país Brasil.',
    en: 'You have selected the English language and the country Brazil.',
    es: 'Ha seleccionado el idioma inglés y el país Brasil.',
  },
 
  {
    ptBr: 'Para alterar os campos para estrangeiros, selecione outro país.',
    en: 'To change the fields to foreigners, select another country.',
    es: 'Para cambiar los campos a extranjeros, seleccione otro país.',
  },


  {
    ptBr: 'Voltar',
    en: 'Back',
    es: 'Regresar',
  },

  {
    ptBr: 'Novo cadastro',
    en: 'New register',
    es: 'Nuevo registro',
  },

  {
    ptBr: 'Já tenho cadastro',
    en: 'I already have registration',
    es: 'Ya tengo registro',
  },

  {
    ptBr: 'Selecione a Opção',
    en: 'Select Option',
    es: 'Seleccionar opción',
  },
  {
    ptBr: 'Sou prescritor',
    en: 'I\'m a prescriber',
    es: '',
  },
  {
    ptBr: 'Tipo de Documento (Passaporte / Outro)',
    en: 'Document Type (Passport / Other)',
    es: 'Tipo de documento (pasaporte / otro)',
  }, 
  
  {
    ptBr: 'Tipo de documento',
    en: 'Document type',
    es: 'Tipo de documento',
  },
  {
    ptBr: 'Nº do documento',
    en: 'Document number',
    es: 'Número del Documento',
  },
  {
    ptBr: 'Pais',
    en: 'Country',
    es: 'País',
  },
 
  {
    ptBr: 'Inscrição',
    en: 'Registration',
    es: 'Inscripción',
  },
  {
    ptBr: 'CPF',
    en: 'CPF (Brazilian Document Number)',
    es: 'CPF (Número de documento brasileño)',
  },

  {
    ptBr: 'Público Geral',
    en: 'General Audiences',
    es: 'Audiencia general',
  },

  {
    ptBr: 'Certificado do Evento',
    en: 'Event certificate',
    es: 'certificado de evento',
  },
  {
    ptBr: 'Acesso online as Palestras do Evento',
    en: 'Online access to the Event',
    es: 'Acceso en línea a las conferencias del evento',
  },
  {
    ptBr: 'Benefícios',
    en: 'Benefits',
    es: 'Beneficios',
  },
  {
    ptBr: 'Valor',
    en: 'Price',
    es: 'Valor',
  },
  {
    ptBr: 'Acesso Digital',
    en: 'Digital Access',
    es: 'Acceso digital',
  },
  {
    ptBr: 'Inscrição',
    en: 'Subscription',
    es: 'Inscripción',
  },
  {
    ptBr: 'Selecionar idioma do Vídeo',
    en: 'Select Video language',
    es: 'Seleccione el idioma del video',
  },
  {
    ptBr: 'Suas perguntas serão visualizadas por nossos moderadores e encaminhadas aos palestrantes.',
    en: 'Your questions will be viewed by our moderators and forwarded to the speakers.',
    es: 'Nuestros moderadores verán sus preguntas y las remitirán a los ponentes.',
  },

  {
    ptBr: 'Perguntas',
    en: 'Questions',
    es: 'Preguntas',
  },
  

  {
    ptBr: 'Ao me cadastrar, afirmo que li e aceito a',
    en: 'When I register, I affirm that I have read and accept the',
    es: 'Cuando me registro, afirmo que he leído y acepto el',
  },
  {
    ptBr: 'Política de Privacidade',
    en: 'Privacy Policy',
    es: 'Política de privacidad',
  },
  {
    ptBr: 'desta plataforma.',
    en: 'of this platform.',
    es: 'de esta plataforma.',
  },

  {
    ptBr: 'NOVA INSCRIÇÃO',
    en: 'NEW REGISTRATION',
    es: 'NUEVO REGISTRO',
  },





  {
    ptBr: 'INSCRIÇÃO | ACESSAR',
    en: 'REGISTER | SIGNIN IN',
    es: '',
  },

  {
    ptBr: 'INSCREVA-SE!',
    en: 'REGISTER',
    es: '¡SUSCRIBIRSE!',
  },
  {
    ptBr: 'ACESSE!',
    en: 'SIGNIN IN',
    es: '¡ACCESO!',
  },

  {
    ptBr: 'Todos os direitos reservados - 2020',
    en: 'All rights reserved - 2020',
    es: '',
  },

  {
    ptBr: 'ACESSE!',
    en: 'SIGIN IN',
    es: '',
  },
  {
    ptBr: 'Dias',
    en: 'Days',
    es: 'Dias',
  },
  {
    ptBr: 'Horas',
    en: 'Hours',
    es: 'Horas',
  },
  {
    ptBr: 'Minutos',
    en: 'Minutes',
    es: 'Minutos',
  },
  {
    ptBr: 'Segundos',
    en: 'Seconds',
    es: 'Segundos',
  },
  {
    ptBr: 'Inscrição Confirmada',
    en: 'Confirmed Registration',
    es: 'Registro confirmado',
  },
  {
    ptBr: 'Retorne a este website no dia do evento para participar do evento.',
    en: 'Return to this website on the day of the event to attend the event.',
    es: 'Regrese a este sitio web el día del evento para participar en el evento.',
  },
  {
    ptBr: 'Você foi desconectado',
    en: 'You have been disconnected',
    es: 'Has sido desconectado',
  },
  {
    ptBr: 'Seu token expirou ou outra pessoa logou com o seu acesso.',
    en:
      'Your token has expired or someone else has logged in with your access.',
    es: 'Su token ha caducado o alguien más ha iniciado sesión con su acceso.',
  },
  {
    ptBr: 'Preparando seu cadastro...',
    en: 'Preparing your registration ...',
    es: 'Preparando su registro ...',
  },
  {
    ptBr: 'Sua inscrição na',
    en: 'Your subscription to',
    es: 'Su suscripción a',
  },
  {
    ptBr: 'já está confirmada',
    en: 'is already confirmed',
    es: 'ya esta confirmado',
  },
  {
    ptBr: 'Aproveite e indique para seus amigos!',
    en: 'Enjoy and invite your friends!',
    es: '¡Disfruta y cuéntaselo a tus amigos!',
  },

  {
    ptBr: 'Olá! Acabei de me inscrever na',
    en: 'Hi! I just signed up for',
    es: '¡Hola! Me acabo de registrar para',
  },


  {
    ptBr:
      'Acredito que você irá gostar de participar desse evento! Inscreva-se pelo link:',
    en:
      'I believe you will enjoy participating in this event! Subscribe by link:',
    es: '¡Creo que disfrutarás participando en este evento! Regístrese por el enlace:',
  },

  {
    ptBr: 'Confirmar sua vaga',
    en: 'Confirm your presence',
    es: 'Confirma tu presencia',
  },

  {
    ptBr: 'Agronegócio e Infraestrutura para o desenvolvimento da Amazônia.',
    en: 'Agribusiness and Infrastructure for the development of the Amazon.',
    es: 'Agronegocio e Infraestructura para el desarrollo de la Amazonía',
  },
  {
    ptBr:
      'AGRONEGÓCIOS E GERAÇÃO DE RIQUEZAS, REGULARIZAÇÃO FUNDIÁRIA E PROJETOS PARA A CONSERVAÇÃO DA AMAZÔNIA',
    en:
      'AGRIBUSINESS AND WEALTH GENERATION, LAND REGULARIZATION AND PROJECTS FOR THE CONSERVATION OF THE AMAZON',
    es:
      'AGRONEGOCIOS Y GENERACIÓN DE RIQUEZAS, REGULARIZACIÓN AGRARIA Y PROYECTOS PARA LA CONSERVACIÓN DE LA AMAZONÍA',
  },

  {
    ptBr:
      'INFRAESTRUTURA, ENERGIA LIMPA E A MATRIZ ENERGÉTICA BRASILEIRA: INVESTIMENTOS E O DESENVOLVIMENTO DA AMAZÔNIA',
    en:
      'INFRASTRUCTURE, CLEAN ENERGY AND THE BRAZILIAN ENERGY MATRIX: INVESTMENTS AND THE DEVELOPMENT OD THE AMAZON',

    es:
      'INFRAESTRUCTURA, ENERGIA LIMPIA Y LA MATRIZ ENERGÉTICA BRASILEÑA: INVERSIONES Y EL DESARROLLO DE LA AMAZONIA',
  },

  {
    ptBr: 'PAINEL 1 – 11H GMT - 3',
    en: 'PANEL 1 - 11AM GMT - 3',
    es: 'PANEL 1 – 11’00 GMT - 3',
  },

  {
    ptBr: 'PAINEL 2 – 14H30 GMT – 3',
    en: 'PANEL 2 - 2:30PM GMT - 3',
    es: 'PANEL 2 – 14’30 GMT – 3',
  },
  {
    ptBr: 'PAINEL 3 – 16H30 – GMT – 3',
    en: 'PANEL 3 - 4:30PM - GMT - 3',
    es: 'PANEL 3 – 16’30 – GMT – 3',
  },

  {
    ptBr: 'Amazônia Invisível e Amazônia Real',
    en: 'The Invisible Amazon and the Real Amazon',
    es: 'Amazonia Invisible y Amazonia Real',
  },
  {
    ptBr: 'A Diversidade dos Povos, Comunidades e Cidades',
    en: 'The Diversity of Peoples, Communities and Cities',
    es: 'La Diversidad de los Pueblos, Comunidades y Ciudades',
  },

  {
    ptBr:
      'PRODUÇÃO AGRÍCOLA, PECUÁRIA E ABASTECIMENTO - OS DESAFIOS DA PRODUÇÃO E A CONSERVAÇÃO DA FLORESTA',
    en:
      'AGRICULTURAL PRODUCTION, LIVESTOCK AND FOOD SUPPLY - CHALLENGES FOR THE PRODUCTION AND FOREST CONSERVATION',
    es:
      'PRODUCCIÓN AGRÍCOLA, PECUARIA Y ABASTECIMIENTO – LOS RETOS DE LA PRODUCCIÓN Y LA CONSERVACIÓN DEL BOSQUE',
  },
  {
    ptBr:
      'SEGURANÇA ALIMENTAR: O BRASIL NO CENÁRIO GLOBAL E AS OPORTUNIDADES PARA A REGIÃO AMAZÔNICA',
    en:
      'FOOD SECURITY BRAZIL IN THE GLOBAL SCENARIO AND OPPORTUNITIES FOR THE AMAZON REGION',
    es:
      'SEGURIDAD ALIMENTARIA: BRASIL EN EL ESCENARIO GLOBAL Y LAS OPORTUNIDADES PARA LA REGIÓN AMAZÓNICA',
  },
  {
    ptBr:
      'OS DESAFIOS E CAMINHOS PARA A REGULARIZAÇÃO FUNDIÁRIA E O DESENVOLVIMENTO DA AMAZÔNIA',
    en:
      'CHALLENGES AND PATHWAYS FOR LAND REGULARIZATION AND THE DEVELOPMENT OF THE AMAZON',
    es:
      'LOS RETOS Y LOS CAMINOS PARA LA REGULARIZACIÓN AGRARIA Y EL DESARROLLO DE LA AMAZONÍA',
  },
  {
    ptBr:
      'OS DESAFIOS DE INFRAESTRUTURA BÁSICA E TECNOLÓGICA NA AMAZÔNIA: SANEAMENTO BÁSICO, LOGÍSTICA E CONECTIVIDADE',
    en:
      'CHALLENGES FOR BASIC AND TECHNOLOGICAL INFRASTRUCTURE IN THE AMAZON: BASIC SANITATION, LOGISTICS AND CONECTIVTY',
    es:
      'LOS RETOS DE INFRAESTRUTURA BÁSICA Y TECNOLÓGICA EN LA AMAZONÍA: SANEAMIENTO BÁSICO, LOGÍSTICA Y CONECTIVIDAD',
  },
  {
    ptBr:
      'ENERGIA LIMPA E A MATRIZ ENERGÉTICA BRASILEIRA: INVESTIMENTOS EM INFRAESTRUTURA E O DESENVOLVIMENTO DA AMAZÔNIA',
    en:
      'CLEAN ENERGY AND THE BRAZILIAN ENERGY MATRIX: INVESTMENTS IN INFRASTRUCTURE AND THE DEVELOPMENT OF THE AMAZON',
    es:
      'ENERGÍA LIMPIA Y LA MATRIZ ENERGÉTICA BRASILEÑA: INVERSIONES EN INFRAESTRUCTURA Y EL DESARROLLO DE LA AMAZONÍA',
  },
  {
    ptBr:
      '9h30 - Agronegócios e geração de riquezas, regularização fundiária e projetos para conservação da amazônia',
    en:
      '9h30 - Agronegócios e geração de riquezas, regularização fundiária e projetos para conservação da amazônia',
    es:
      '9h30 - Agronegócios e geração de riquezas, regularização fundiária e projetos para conservação da amazônia',
  },
  {
    ptBr:
      '14h30 - Infraestrutura e desenvolvimento sustentável da Amazônia, investimentos e reflexões sobre o futuro',
    en:
      '14h30 - Infraestrutura e desenvolvimento sustentável da Amazônia, investimentos e reflexões sobre o futuro',
    es:
      '14h30 - Infraestrutura e desenvolvimento sustentável da Amazônia, investimentos e reflexões sobre o futuro',
  },
  {
    ptBr: 'O campo Nome Completo é obrigatório',
    en: 'The Full Name Field is Required',
    es: 'Nombre Completo obligatorio',
  },
  {
    ptBr: 'Preencha sua Data de Nascimento',
    en: 'Fill in your Date of Birth',
    es: 'Introduzca su fecha de nacimiento',
  },
  {
    ptBr: 'Data de Nascimento',
    en: 'Date of Birth',
    es: 'Fecha de nacimiento',
  },
  {
    ptBr: 'Preencha um email válido',
    en: 'Fill in a Valid Email',
    es: 'Introduzca un email válido',
  },
  {
    ptBr: 'O campo Email é obrigatório',
    en: 'The Email Field is Required',
    es: 'el campo email es obligatorio',
  },
  {
    ptBr: 'Telefone',
    en: 'Telephone',
    es: '',
  },
  {
    ptBr: 'O campo Telefone é obrigatório',
    en: 'The Telephone Field is Required',
    es: 'el campo teléfono es obligatorio',
  },
  {
    ptBr: 'O campo Categoria é obrigatório',
    en: 'The Category Field is Required',
    es: 'el campo Categoría es obligatorio',
  },
  {
    ptBr: 'O campo Escolaridade é obrigatório',
    en: 'The Education Field is Required',
    es: 'el campo Educación es obligatorio',
  },
  {
    ptBr: 'O campo Empresa é obrigatório',
    en: 'The Company Field is Required',
    es: 'el campo Empresa es obligatorio',
  },
  {
    ptBr: 'O campo Cargo é obrigatório',
    en: 'The Job Position is Required',
    es: 'el campo Cargo es obligatorio',
  },
  {
    ptBr: 'O campo Cidade é obrigatório',
    en: 'The City Field is Required',
    es: 'el campo ciudad es obligatorio',
  },
  {
    ptBr: 'O campo UF é obrigatório',
    en: 'The UF is Required',
    es: 'el campo UF es obligatorio',
  },
  {
    ptBr: 'Erro ao gerar seu cadastro',
    en: 'Error when generating your registration',
    es: 'Error al generar su registro',
  },
  {
    ptBr: 'Preparando seu cadastro',
    en: 'Preparing your registration',
    es: 'Preparando su registro',
  },
  {
    ptBr: 'Cadastro realizado com sucesso!',
    en: 'Successful registration',
    es: '¡Inscripción realizada con éxito!',
  },
  { ptBr: 'ACESSAR', en: 'sign in', es: 'Acceso' },
  { ptBr: 'ACESSAR', en: 'SIGN IN', es: 'ACCESO' },
  {
    ptBr: 'Aproveite e indique para seus amigos!',
    en: 'Enjoy and tell your friends',
    es: '¡Aproveche y recomiende a sus amigos!',
  },
  {
    ptBr:
      'Olá! Acabei de me inscrever no Amazônia+21. Acredito que você irá gostar de participar desse evento: Amazônia+21 - Conectando Iniciativas para o Desenvolvimento Sustentável. É gratuito, 100% virtual e com muito conteúdo. Inscreva-se pelo link: https://amazonia21.encontrodigital.com.br/',
    en:
      'Hello! I have just signed up for Amazonia +21.I believe you will enjoy taking part in this event: Amazonia+21 - Connecting Initiatives for Sustainable Development. It is free, 100% virtual and with a lot of content. Subscribe by the link: https://amazonia21.encontrodigital.com.br/',
    es:
      '¡Hola! Acabo de inscribirme en Amazônia +21. Pienso que te gustara participar  de este evento: Amazônia+21. Juntando iniciativas para el Desenvolvimiento Sustentable. Es gratuito 100% virtual y con mucho contenido. Inscrívase en el link: https://amazonia21.encontrodigital.com.br/',
  },
  { ptBr: 'Compartilhe!', en: 'Share!', es: '¡Comparta!' },
  { ptBr: 'Nome Completo', en: 'Full Name', es: 'Nombre completo' },
  { ptBr: 'Empresa', en: 'Company', es: 'Empresa' },
  { ptBr: 'Cargo', en: 'Position', es: 'Cargo' },
  { ptBr: 'Celular', en: 'Mobile', es: 'Celular' },
  { ptBr: 'E-mail', en: 'Email', es: 'E-mail' },
  { ptBr: 'UF', en: 'UF', es: 'UF' },
  { ptBr: 'Cidade', en: 'City', es: 'Ciudad' },
  { ptBr: 'País', en: 'Country', es: 'País' },
  {
    ptBr: 'Como você soube do evento?',
    en: 'How did you hear about the event?',
    es: '¿Cómo te enteraste del evento?',
  },
  { ptBr: 'Escolaridade', en: 'Education', es: 'Educación' },
  {
    ptBr: 'Ensino Fundamental',
    en: 'Elementary School',
    es: 'Enseñanza Básica ',
  },
  {
    ptBr: 'Ensino Fundamental Incompleto',
    en: 'Incomplet Elementary School',
    es: 'Eseñanza básica incompleta',
  },
  {
    ptBr: 'Ensino Médio Incompleto',
    en: 'Incomplet High School',
    es: 'Eseñanza media incompleta',
  },
  { ptBr: 'Ensino Médio', en: 'High School', es: 'Eseñanza Media ' },
  {
    ptBr: 'Ensino Superior',
    en: 'University Education',
    es: 'Enseñanza Superior',
  },
  {
    ptBr: 'Ensino Superior Incompleto',
    en: 'Incomplete University Education',
    es: 'Enseñanza Superior Incompleta',
  },
  { ptBr: 'Pós Graduação', en: 'Postgraduate', es: 'Postgraduación' },
  { ptBr: 'Mestrado', en: 'Master degree', es: 'Máster' },
  { ptBr: 'Doutorado', en: 'Doctorate degree', es: 'Doctorado' },
  { ptBr: 'pós doutorado', en: 'Post Doctorate', es: 'Post-doctorado' },
  { ptBr: 'Setor', en: 'Sector', es: 'Sector' },
  { ptBr: 'Governo Federal', en: 'Federal Government', es: 'Gobierno Federal' },
  { ptBr: 'Governo Estadual', en: 'State Government', es: 'Gobierno Estatal' },
  {
    ptBr: 'Governo Municipal',
    en: 'Municipal Government',
    es: 'Gobierno Municipal',
  },
  { ptBr: 'Empresário', en: 'Entrepreneur', es: 'Empresario' },
  { ptBr: 'Executivo', en: 'Manager', es: 'Ejecutivo' },
  {
    ptBr: 'Entidades e Sindicatos',
    en: 'entities and unions',
    es: 'Entidades y Sindicatos',
  },
  { ptBr: 'Investidor', en: 'Investor', es: 'Inversor' },
  { ptBr: 'Pesquisador', en: 'Researcher', es: 'Investigador' },
  { ptBr: 'Professor', en: 'Professor', es: 'Profesor' },
  { ptBr: 'Estudante', en: 'Student', es: 'Estudiante' },
  { ptBr: 'Imprensa', en: 'Press', es: 'Prensa' },
  { ptBr: 'ONG – OSIP', en: 'NGO - OSIP', es: 'ONG-OSIP' },
  { ptBr: 'Cadastrar', en: 'Fill in', es: 'Registrarse' },
  { ptBr: 'CADASTRAR', en: 'FILL IN', es: 'REGISTRARSE' },
  {
    ptBr: 'Sua inscrição está confirmada',
    en: 'Your Subscription is confirmed',
    es: 'Tu inscripción está confirmada',
  },
  {
    ptBr: 'Retorne a esta página no dia do evento.',
    en: 'Return to this page on the day of the event',
    es: 'vuelva a esta página el día del evento',
  },
  { ptBr: 'Sair', en: 'Sign out', es: 'Salir' },
  {
    ptBr: 'Nenhum arquivo disponibilizado',
    en: 'No files available',
    es: 'ningún archivo disponible',
  },
  { ptBr: 'Arquivos de apoio', en: 'Backup files', es: 'Achivos de apoyo' },
  { ptBr: 'Pesquisar', en: 'Search', es: 'Investigar' },
  { ptBr: 'Listar Vídeos', en: 'List Videos', es: 'Enumerar videos' },
  {
    ptBr: 'Ocorreu um erro ao fazer login',
    en: 'There was an error signing in',
    es: 'Sucedió un error al registrarse',
  },
  {
    ptBr: 'Erro na autenticação',
    en: 'authentication error',
    es: 'Error de autentificación',
  },

  {
    ptBr: '4 A 6 DE NOVEMBRO',
    en: 'NOVEMBER 4-6',
    es: '4-6 DE NOVIEMBRE',
  },

  { ptBr: 'ACESSE', en: 'ACCESS', es: 'ACCEDA' },
  { ptBr: 'INSCREVA-SE', en: 'REGISTER', es: 'INSCRÍVASE' },
  {
    ptBr: 'VOLTAR PARA O WEBSITE',
    en: 'BACK TO WEBSITE',
    es: 'VOLVER LA PÁGINA WEB',
  },
  { ptBr: '26 DE AGOSTO', en: 'AUGUST 26TH', es: '26 DE AGOSTO' },
  { ptBr: '19 DE AGOSTO', en: 'AUGUST 19TH', es: '19 DE AGOSTO' },
  { ptBr: 'PRÓXIMO EVENTO', en: 'NEXT EVENTS:', es: 'Próximo Evento' },
  { ptBr: 'Recarregar vídeo', en: 'Reload video', es: 'Recargar video' },
  {
    ptBr: 'Amazonia+21 - Todos os direitos reservados – 2020',
    en: 'Amazonia+21 - All rights reserved - 2020',
    es: 'Amazônia +21 Todos los derechos reservados - 2020',
  },
  { ptBr: 'Inscrição', en: 'REGISTER', es: 'Inscripción' },
  { ptBr: 'Home', en: 'Home', es: 'Inicio' },
  { ptBr: 'Login', en: 'Login', es: 'Ingreso' },
  {
    ptBr: 'Preencha um email válido',
    en: 'Fill in a Valid Email',
    es: 'Introduzca un email válido',
  },
  {
    ptBr: 'Horário de Brasilia',
    en: 'Brasilia time',
    es: 'Hora de Brasilia',
  },
  {
    ptBr: 'Visão de Futuro, Oportunidades e Desafios para a Região Amazônica',
    es: 'Visión de Futuro, Oportunidades y Retos para la Región Amazónica',
    en: 'Future Vision, Opportunities and Challenges to the Amazon Region',
  },
  {
    ptBr:
      '9h - Alternativas de Funding para Negócios e Projetos de Alto Impacto na Amazônia',
    en:
      '9h - Funding Alternatives for High Impact Businesses and Projects in The Amazon',
    es:
      '9h - Alternativas de Financiación para Negocios y Proyectos de Alto Impacto en Amazonía',
  },
  {
    ptBr:
      '11h - Cooperação Internacional, Fomento e Mecanismos de Alavancagem para o Desenvolvimento da Região Amazônica',
    en:
      '11h - International Cooperation, Fostering, and Leverage Mechanisms for the Development of the Amazon Region ',
    es:
      '11h - Cooperación Internacional, Fomento y Mecanismos de Apalancamiento para el Desarrollo de la Región Amazónica',
  },
  {
    ptBr: 'JÁ SOU CADASTRADO',
    en: 'I AM ALREADY REGISTERED',
    es: 'YA ESTOY REGISTRADO',
  },
  {
    ptBr: 'Faça sua Pergunta',
    en: 'Ask your question',
    es: 'Haga su pregunta',
  },
  {
    ptBr: '+ Palestras',
    en: '+ Videos',
    es: '',
  },
  { ptBr: 'Informação opcional, obrigatório apenas para pagamentos por pessoa jurídica', en: 'Optional information, mandatory only for payments by legal entity', es: '' },
  { ptBr: 'Sair', en: 'Log out', es: 'Cerrar sesión' },
  { ptBr: 'Entrar', en: 'Login in', es: 'Entrar' },
  { ptBr: 'ENTRAR', en: 'LOGIN IN', es: 'ENTRAR' },
  { ptBr: 'Criar minha conta', en: 'Create my account', es: 'Crear mi cuenta' },
  { ptBr: 'CRIAR MINHA CONTA', en: 'CREATE MY ACCOUNT', es: 'CREAR MI CUENTA' },
  { ptBr: 'Carregando', en: 'Loading', es: 'Cargando' },
  { ptBr: 'Carregando...', en: 'Loading...', es: 'Cargando' },
  {
    ptBr: 'Inscrição realizada com sucesso!',
    en: 'Registration Successful!',
    es: '¡Inscripción exitosa!',
  },
  {
    ptBr: 'Cadastro realizado com sucesso!',
    en: 'Successful Registration!',
    es: '¡Registro exitoso!',
  },
  {
    ptBr: 'Adicionado com sucesso!',
    en: 'Added Successfully!',
    es: '¡Añadido con éxito!',
  },
  {
    ptBr: 'Removido com sucesso',
    en: 'Successfully removed',
    es: 'Eliminado con éxito',
  },
  {
    ptBr: 'Escreva sua mensagem',
    en: 'Write your message',
    es: 'Escriba su mensaje',
  },
  { ptBr: 'Enviar', en: 'Send', es: 'Enviar' },
];
