import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import api from '../../services/api';
import { Link } from 'react-router-dom';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IVideos {
  title?: string;
  video_id: string;
  type_id: string;
  className?: string;
}

const SignIn: React.FC = () => {


  const [videos,setVideos] = useState<Array<IVideos>>([]);

  const load = async () => {
    const response = await api.get('/video-gallery/1');

    if(response){

      const newData : Array<IVideos> = [];


      setVideos(response?.data?.videos || []);
    }
  }
  useEffect(() => {

    load();
    window.scrollTo(0,0);
  },[])

  return (
    <>


      <Container>
    <h2>Congresso Kids</h2>

        <div style={{width:'100%'}}>
          <VideoGallery data={videos}/>
        </div>

 
      </Container>
    </>
  );
};
export default SignIn;
