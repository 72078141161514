import React, { useState, useEffect, useRef } from 'react';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR/index.js';
import { Form } from '@unform/web';
import { FiMessageSquare, FiUser } from 'react-icons/fi';
import { uuid } from 'uuidv4';
import { useAuth } from '../../hooks/Auth';
import {
  Container,
  Body,
  Message,
  Footer,
  OnlineUser,
  Info,
  Faces,
  Ball,
  Wrapper,
} from './styles';
import { useSocket } from '../../hooks/Socket';
import { useModal } from '../../hooks/Modal';
import { useToast } from '../../hooks/Toast';
import Expositores from '../../pages/AreaExposicao';

interface IMessage {
  id: number;
  chat_id: string;
  message: string;
  createdAt: string;
  user_id: string;
  user_name: string;
  formatted_date?: string;
  message_from_id?: number;
  message_from_name?: string;
}

interface ISendMessage {
  id: number;
  message: string;
}

interface IMessageList {
  id: number;
  expositor: number;
  user: number;
  user_name: string;
  last_message: string;
  status: number;
  messages: Array<IMessageChat>;
  attendant: number;
  attendant_name: string;
  created_at: Date;
  formatted_date?: string;
}

interface IMessageChat {
  id: string;
  message: string;
  from: number;
  from_name: string;
  to?: number;
  formatted_date: string;
  created_at: Date;
}

interface ICurrentPrivateChat {
  userID: number;
  userName: string;
}

interface IChatRoom {
  id_expositor: number;
  expositor_name: string;
}

interface IText {
  text: string;
}

interface IUsers {
  id: number;
  name: string;
  lastMessage?: string;
}

interface IListPrivateChatMessages {
  data: Array<IMessage>;
  chat_id: string;
}

const date = (createdAt: Date): string => {
  const formattedDate = format(createdAt, `HH:mm`, {
    locale: pt,
  });
  return formattedDate;
};

const newMessage = (user, text) => {
  const newDate = new Date();
  const messageFactory: IMessageChat = {
    id: uuid(),
    message: text,
    from: user.id,
    from_name: user.name,
    formatted_date: date(newDate),
    created_at: newDate,
  };

  return messageFactory;
};

export const startChatApi = (socketIO, expositor_id, userInfo) => {
  if (userInfo && userInfo.reference) {
    return false;
  }
  socketIO.emit('createPrivateChat', {
    expositor: expositor_id,
    user: userInfo.id,
    user_name: userInfo.name,
    last_message: 'Chat iniciado',
    messages: [newMessage(userInfo, 'Chat iniciado')],
  });
};

const ChatRoom: React.FC<IChatRoom> = ({ id_expositor, expositor_name }) => {
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { socket } = useSocket();
  const [messages, setMessage] = useState<Array<IMessage>>(
    [] as Array<IMessage>,
  );

  const [userList, setUserList] = useState<Array<IMessageList>>([]);

  const [selectedMessages, setSelectedMessage] = useState<Array<IMessageChat>>(
    [],
  );

  const [currentChat, setCurrentChat] = useState();
  const [textChat, setTextChat] = useState('');

  const [messageColor, setMessageColor] = useState(false);
  const [privateChatId, setPrivateChatId] = useState();

  const { user } = useAuth();
  const formRef = useRef(null);
  const getMessage = (id, list) => {
    const userMessage = list.find(item => item.id === id);
    return userMessage;
  };
  const selectUser = (id, list) => {
    const userMessage = getMessage(id, list);

    if (userMessage) {
      setCurrentChat(id);
      setSelectedMessage(userMessage.messages);
    }
  };

  useEffect(() => {
    console.log(id_expositor, expositor_name, user);
    if (user && expositor_name === user.reference && !currentChat) {
      socket.emit('startPrivateChat', {
        expositor: id_expositor,
        user: user.id,
      });
    }

    socket.on('privateChatSetId', data => {
      if (data) {
        setUserList([data]);
        setCurrentChat(data.id);
        selectUser(data.id, [data]);
      }
    });

    socket.on('privateChatResponse', response => {
      console.log(response, currentChat);
      setUserList(response);

      if (currentChat) {
        const getMessages = response.find(li => li.id === currentChat);

        if (getMessages) {
          setSelectedMessage(getMessages.messages);
          setCurrentChat(getMessages.id);
        }
      }
    });
  }, [currentChat]);

  const ListMessages = (
    listMessages: Array<IMessageChat>,
  ): Array<JSX.Element> => {
    const response: Array<JSX.Element> = [];

    listMessages &&
      listMessages.map((messageLine: IMessageChat) =>
        response.push(
          <Message key={messageLine.id} isMe={user.id === messageLine.from}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="message">
                {' '}
                {messageLine.from_name}
                <p className="date">
{messageLine.formatted_date}
{' '}
 </p>
              </div>

              <div style={{ color: '#333' }} className="info">
                <p>{messageLine.message}</p>
              </div>
            </div>
          </Message>,
        ),
      );

    return response;
  };

  const handleTextChat = () => {
    /* Grava mensagem */

    const userMessage = getMessage(currentChat, userList);
    if (userMessage) {
      userMessage.last_message = textChat;
      userMessage.messages = [
        newMessage(user, textChat),
        ...userMessage.messages,
      ];
      socket.emit('messagePrivateChat', {
        id: currentChat,
        user: user.id,
        message: newMessage(user, textChat),
      });

      setTextChat('');

      selectUser(userMessage.id, userList);
      return true;
    }
    return addToast({ type: 'error', title: 'Erro ao identificar mensagem' });
  };

  const ListUsers = (userList: Array<IMessageList>): Array<JSX.Element> => {
    const response: Array<JSX.Element> = [];

    userList.map(message =>
      response.push(
        <Message
          onClick={() => selectUser(message.id, userList)}
          isMe={false}
          key={`chatUser${message.id}`}
        >
          {' '}
          <Ball status={message.status} />
          <div>
            <div className="message">
              {message.user_name}
              <p className="date">{message.formatted_date}</p>
            </div>
            <div style={{ color: '#333' }} className="info">
              <p>{message.last_message}</p>
            </div>
          </div>
        </Message>,
      ),
    );

    return response;
  };

  return (
    <Wrapper>
      {user && expositor_name === user.reference && (
        <Container className={messageColor ? 'greenMessages' : ''}>
          <div className="main">
            <h2>
              <FiUser /> Atendimentos{' '}
            </h2>

            <Body>{ListUsers(userList)}</Body>
          </div>
        </Container>
      )}
      <Container className={messageColor ? 'greenMessages' : ''}>
        <div className="main">
          <h2>
            <FiMessageSquare /> Chat{' '}
          </h2>
          <Footer>
            <Form ref={formRef} onSubmit={handleTextChat}>
              <input
                type="text"
                name="text"
                placeholder="Escreva sua mensagem"
                value={textChat}
                onChange={e => {
                  const { value } = e.target;
                  if (value) {
                    setTextChat(value.substr(0, 200));
                  }
                }}
              />
              <div>
                <button type="submit">Enviar</button>
              </div>
            </Form>
          </Footer>
          <Body>{ListMessages(selectedMessages)}</Body>
        </div>
      </Container>
    </Wrapper>
  );
};

export default ChatRoom;
