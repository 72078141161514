import styled from 'styled-components';
import { shade } from 'polished';
import { defaultTheme } from '../../../../config';

export const Container = styled.div`

display: flex;
align-items: flex-start;
justify-content: flex-end;
flex-direction: column;
width: 100%;
max-width: 500px;
min-height: 100vh;
z-index: 10000;
position: fixed;
top: 0px;
right: 0px;
background: #333;
z-index: 1000;

.contentMessage {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: 100%;
    padding: 10px 20px;
}

form {
    display: flex;
    padding: 10px;
    width: 100%;
    justify-content: center;
    >button{
        margin-left: 5px;
        min-width: 50px;
        background: #ff6600 !important;
        border-radius: 10px;
        color: #fff;
        border: 0px

    }
}

`;

export const Body = styled.div`
width: 100%;

height: 100vh;
background: rgba(0,0,0,0.3);
display: flex;
align-items: flex-start;
justify-content: flex-start;
flex-direction: column;
`;

export const Sidebar = styled.div`
width: 100%;
height: 100vh;
background: #333;

`;

export const ProfileBox = styled.div`
width: 100%;


    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    padding: 15px 20px;
    border: 2px solid #000;
    cursor: pointer;

  color: #ff6600 !important;

    &:hover{
        background: #111;
    
    }

`;

export const FormBox = styled.div`
width: 100%;
`;

export const MessagesBox = styled.div`
width: 100%;
max-height: 80vh;
overflow-y: auto;
`;

export const Message = styled.div`
width: 100%;
padding: 10px 20px;
`;

export const Contact = styled.div`
width: 100%;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    padding: 15px 20px;
    border: 2px solid #000;
    cursor: pointer;
    color: #ff6600 !important;
  

    &:hover{
        background: #111;
    }
    
`;

export const SearchBox = styled.div`
width: 100%;
height: 100vh;


>div{
    >div{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    padding: 15px 20px;
    border: 2px solid #000;
    cursor: pointer;

  

    &:hover{
        background: #111;
    }
    }
}
`;

export const PrivateMessageButton = styled.button`
width: 100%;
`;

export const Header = styled.div`
width: 100%;
display: flex;
justify-content: flex-end;
align-items: center;
background: #333;
border: 2px solid #000;
`;

interface IActive {
    active : boolean;
}

export const ActiveButton = styled.button<IActive>`
width: 50px;
height: 50px;
display: flex;
justify-content: center;
align-items: center;
color: #fff;
background: ${props => props.active ? '#ff6600 !important' : '#333'};
border: 0px;
border-right: 2px solid #000;
box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
>svg{
    font-size: 18px;
}
`;

interface IPhoto {
    background : string;
}

export const Photo = styled.button<IPhoto>`
width: 40px;
min-width: 40px;
height: 40px;
background: url(${props => props.background}) no-repeat center;
background-size: cover;
border-radius: 50%;
margin: 10px;
display: flex;
align-items: center;
justify-content: center;

`;

export const GlobalButton = styled.button`
width: 40px;
min-width: 40px;
height: 40px;

border-radius: 50%;
margin: 10px;
display: flex;
align-items: center;
justify-content: center;
border: 2px solid #ccc;
transition: 0.4s all;
color: #ff6600 !important;
&:hover { 
    border: 2px solid #ff6600 !important;
    >svg{
        
        transform: scale(1.3);
    }
}

`;

export const ConfigBox = styled.div`
width: 100%;
height: 100vh;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;

`;




