import React from 'react';

import uuid from '../../core/components/UniqueHash';
import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const Dashboard: React.FC = () => {
  const endpoint = '/certificates';
  const title = 'Certificados';
  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    title: { ref: 'title', column: 'title', label: 'Título' },
    status: {
      ref: 'status',
      column: 'status_response',
      includeColumn: 'title',
      label: 'Ativo?',
      filter: true,
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
    title: {
      model: 'input',
      type: 'text',
      name: 'title',
      label: 'Título',
    },
    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'status',

      endpoint,
    },
    content: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'content',
      label: 'content Icons',
      list: [
        { label: 'Texto', name: 'text' },
        { label: 'Tamanho', name: 'width' },
        { label: 'Tamanho da fonte', name: 'size' },
        { label: 'Topo', name: 'top' },
        { label: 'Esquerda', name: 'left' },
        { label: 'Alinhamento', name: 'align' },
        { label: 'Negrito', name: 'fontWeight' },
        { label: 'Elemento HTML', name: 'element' },
      ],
    },
  };

  const formSchemaUpdate = {
    ...formSchema,
    title: {
      model: 'input',
      type: 'text',
      name: 'title',
      label: 'Título',
    },
    height: {
      model: 'input',
      type: 'text',
      name: 'height',
      label: 'Setar altura',
    },

    image: {
      model: 'uploadImage',
      type: 'upload',
      name: 'image',
      label: 'Imagem',
    },
    content: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'content',
      label: 'content Icons',
      list: [
        { label: 'Texto', name: 'text' },
        { label: 'Tamanho', name: 'width' },
        { label: 'Tamanho da fonte', name: 'size' },
        { label: 'Topo', name: 'top' },
        { label: 'Esquerda', name: 'left' },
        { label: 'Alinhamento', name: 'align' },
        { label: 'Negrito', name: 'fontWeight' },
        { label: 'Elemento HTML', name: 'element' },
      ],
    },
    type: {
      model: 'selectSimple',
      name: 'type',
      label: 'Tipo',

      options: [
        { label: 'Global', value: 1},
        { label: 'Palestra', value: 2}

      ]
    
    },
    room_id: {
      alias: 'room_id',
      model: 'selectApi',
      name: 'room_id',
      label: 'Palestra',
      endpoint,
    },
    days: {
      model: 'input',
      type: 'text',
      name: 'days',
      label: 'Data',
    },
    hours: {
      model: 'input',
      type: 'text',
      name: 'hours',
      label: 'Horas',
    },
  };

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
