import React, { useState, useEffect } from 'react';
import {
  differenceInHours,
  differenceInMinutes,
  differenceInDays,
  parseISO,
  differenceInSeconds,
} from 'date-fns';
import { Container } from './styles';
import { useLanguage } from '../../hooks/Language';

interface IContagemRegressiva {
  dataDesejada?: string;
}

const ContagemRegressiva: React.FC<IContagemRegressiva> = ({
  dataDesejada = '2021-07-12T20:00:00-03:00',
}) => {
  
  

  const {translate} = useLanguage();
  const [days, setDay] = useState('');
  const [hours, setHour] = useState('');
  const [minutes, setMinute] = useState('');
  const [seconds, setSeconds] = useState('');
  const [active,setActive ] = useState(true);

  

  useEffect(() => {


    if(new Date(dataDesejada) < new Date){
      setActive(false)
    }
    else{

    const repeat = setInterval(() => {

      const minutesStamp = differenceInMinutes(
        new Date(dataDesejada),
        new Date(),
      );
      const secondsStamp = differenceInSeconds(
        new Date(dataDesejada),
        new Date(),
      );

      
      const hoursStamp = differenceInHours(new Date(dataDesejada), new Date());
      const daysStamp = differenceInDays(new Date(dataDesejada), new Date());
  
      const second = minutesStamp > 0 ? secondsStamp % 60 : 0;
      const minute = minutesStamp > 0 ? minutesStamp % 60 : 0;
      const hour = hoursStamp > 0 ? hoursStamp % 24 : 0;
      const day = daysStamp > 0 ? daysStamp : 0;
  
      setHour(
        hour.toString().length < 2 ? `0${hour.toString()}` : hour.toString(),
      );
      setMinute(
        minute.toString().length < 2
          ? `0${minute.toString()}`
          : minute.toString(),
      );
      setSeconds(
        secondsStamp.toString().length < 2
          ? `0${second.toString()}`
          : second.toString(),
      );
      setDay(day.toString());

 
    }, 1000);

    const minutesStamp = differenceInMinutes(
      new Date(dataDesejada),
      new Date(),
    );
    const secondsStamp = differenceInSeconds(
      new Date(dataDesejada),
      new Date(),
    );
    const hoursStamp = differenceInHours(new Date(dataDesejada), new Date());
    const daysStamp = differenceInDays(new Date(dataDesejada), new Date());

    const second = minutesStamp > 0 ? secondsStamp % 60 : 0;
    const minute = minutesStamp > 0 ? minutesStamp % 60 : 0;
    const hour = hoursStamp > 0 ? hoursStamp % 24 : 0;
    const day = daysStamp > 0 ? daysStamp : 0;

    setHour(
      hour.toString().length < 2 ? `0${hour.toString()}` : hour.toString(),
    );
    setMinute(
      minute.toString().length < 2
        ? `0${minute.toString()}`
        : minute.toString(),
    );
    setSeconds(
      secondsStamp.toString().length < 2
        ? `0${second.toString()}`
        : second.toString(),
    );
    setDay(day.toString());

    return () => clearInterval(repeat);
    }
  }, []);

  return (
    active ?
    <Container>
      <div className="ContagemDias">
        <h3>{days}</h3>
        <p>{translate('Dias')}</p>
      </div>
      <div className="ContagemHoras">
        <h3>{hours}</h3>
        <p>{translate('Horas')}</p>
      </div>
      <div className="ContagemMinutos">
        <h3>{minutes}</h3>
        <p>{translate('Minutos')}</p>
      </div>
      <div className="ContagemMinutos">
        <h3>{seconds}</h3>
        <p>{translate('Segundos')}</p>
      </div>
    </Container> : <></>
  );
};
export default ContagemRegressiva;
