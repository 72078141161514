import React, { useState, useEffect } from 'react';

import { IconType } from 'react-icons/lib';

import { FaChevronDown, FaChevronUp, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import { Container, ContainerWhite, Evento } from './styles';

import { SpeakersContainer } from '../../components/SpeakersGrid';
import api from '../../services/api';
import { useAuth } from '../../hooks/Auth';

interface ISpeakerData {
  name: string;
  photo: string;
  position?: string;
  description?: string;
  id: number;
  category_response ?: Record<any,string>;
  social ?: Record<any,string>;
  category_id : string;

}

const Speakers: React.FC = () => {
  const {handleApiErrors} = useAuth();
  const [comissionData, setComissionData] = useState<Array<ISpeakerData>>([]);
  const [speakersData, setSpeakerData] = useState<Array<ISpeakerData>>([]);

  useEffect(() => {
    const load = async () => {
      const order = { orderBy: 'name', direction: 'asc' };

      const string = `order=${encodeURIComponent(JSON.stringify(order))}`;

      try{
      const response = await api.get(`/speakers-list?limitItems=100`);
      if (response) {

        const sp : Array<ISpeakerData> = [];
        const comission : Array<ISpeakerData> = [];

response.data.rows.map( data => {
  if(data.category_id === '1'){
    sp.push(data);
  }
  else if(data.category_id === '2'){
    comission.push(data);
  }
} )

sp.sort( (a,b) => {

  const nameA = a.name ? a.name.toLowerCase().replace('dr. ','').replace('dra. ','') : '';
  const nameB = b.name ? b.name.toLowerCase().replace('dr. ','').replace('dra. ','') : '';
 
  if(nameA < nameB){ return -1;}
  else if(nameB < nameA){ return 1;}
  else{ return 0}

});

comission.sort( (a,b) => {

  const nameA = a.name ? a.name.toLowerCase().replace('dr. ','').replace('dra. ','') : '';
  const nameB = b.name ? b.name.toLowerCase().replace('dr. ','').replace('dra. ','') : '';
 
  if(nameA < nameB){ return -1;}
  else if(nameB < nameA){ return 1;}
  else{ return 0}

});

        setSpeakerData(sp);
        setComissionData(comission);
      }
    }
    catch(err){
      handleApiErrors(err);
    }
    };
    load();
  }, []);

  return <>
  { comissionData?.length > 0 && <SpeakersContainer title="COMISSÃO ORGANIZADORA" speakers={comissionData} />}
  {speakersData?.length > 0 && <SpeakersContainer title="PARTICIPANTES" speakers={speakersData} />}
  
  </>
};

export default Speakers;
