/* eslint-disable @typescript-eslint/camelcase */
import React, { useState } from 'react';

import { Link } from 'react-router-dom';
import { urlWebsite } from '../../config';
import { Container, AnimatedDiv, Content } from './styles';
import ProductsContainer from './modules/Products';
import CoursesContainer from './modules/Courses';
import LoginContainer from './modules/Login';
import AddressContainer from './modules/Address';
import PaymentMethodContainer from './modules/PaymentMethod';
import { ResponseMessage } from '../../components/ResponseMessage';
import Button from '../../components/Button';

interface ProductProps {
  id: string;
  title: string;
  price: number;
  formattedPrice: string;
  max_selectable_courses: number;
  benefits: string[];
  quantity: number;
}

interface AddressProps {
  zipcode: string;
  address: string;
  address_number?: string;
  neighborhood: string;
  city: string;
  state: string;
}

interface LoginProps {
  name: string;
  email: string;
  password: string;
}

interface UserProps {
  document_type: string;
  document_number: string;
  birth_date: string;
  phone: string;
}

interface BillingProps {
  paymentMethod: 'boleto' | 'credit_card';
  credit_card_hash?: string;
  installments?: number;
}

export interface BuyContext {
  amount: number;
  maxCourses: number;
  productType: 'free' | 'paid';
  isLogged?: boolean;
  products?: ProductProps[];

  courses?: string[];
  login: LoginProps;
  user?: UserProps;
  address?: AddressProps;
  billing?: BillingProps;
}

interface IProduct {
  productId?: number;
}

const Products: React.FC<IProduct> = ({ productId = 0 }) => {
  const [step, setStep] = useState<number[]>([1, 1]);

  const [buyContext, setBuyContext] = useState<BuyContext>({} as BuyContext);

  return (
    <Container>
      {step[1] === 1 && (
        <ProductsContainer
          step={step}
          setStep={setStep}
          buyContext={buyContext}
          setBuyContext={setBuyContext}
        />
      )}

      {step[1] === 2 && (
        <CoursesContainer
          step={step}
          setStep={setStep}
          buyContext={buyContext}
          setBuyContext={setBuyContext}
        />
      )}

      {step[1] === 3 && (
        <LoginContainer
          step={step}
          setStep={setStep}
          buyContext={buyContext}
          setBuyContext={setBuyContext}
        />
      )}

      {step[1] === 4 && (
        <AddressContainer
          step={step}
          setStep={setStep}
          buyContext={buyContext}
          setBuyContext={setBuyContext}
        />
      )}

      {step[1] === 5 && (
        <PaymentMethodContainer
          step={step}
          setStep={setStep}
          buyContext={buyContext}
          setBuyContext={setBuyContext}
        />
      )}

      {step[1] === 6 && (
        <AnimatedDiv visible={step[1] === 6}>
          <ResponseMessage
            active
            type="success"
            title="Cadastro realizado!"
            description={(
              <>
                Seu cadastro foi finalizado com sucesso.
                <br />
                Para acessar a plataforma, clique no botão abaixo
                <Link to="/login">
                  <Button type="button">Fazer Login </Button>
                </Link>
              </>
            )}
          />
        </AnimatedDiv>
      )}
    </Container>
  );
};
export default Products;
