import React, { useCallback, useRef, useEffect, useState } from 'react';
import { FiLogIn, FiMail, FiLock, FiUser } from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
// import logo from '../../assets/logo-compressed.png';

import {  supportNumber, authTitle } from '../../config';
import { Container, Content,  AnimatedDiv2,ErrorContainer } from './styles';
import Input from '../../components/Forms/Input';
import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import Whatsapp from '../../components/Whatsapp';
import { useLanguage } from '../../hooks/Language';
import Loader from '../../components/Loader';
import { FaWhatsapp } from 'react-icons/fa';
import SignUp from '../SignUp2';


interface SignInData {
  email: string;
  password: string;
  name: string;
}

interface IParams{
  redirect ?: string;
}

const SignIn: React.FC<IParams> = ({redirect = '/' }) => {
  const history = useHistory();
  const [withPassword, setWithPassword] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showCadastrar, setShowCadastrar] = useState(false);
  const { signIn } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const {translate} = useLanguage();
  const [errorMessage, setErrorMessage] = useState<Record<string,string>>({});
  const [manutencao, setManutencao] = useState(false);
  const handleSubmit = useCallback(
    async (data: SignInData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});

        const shape = withPassword
          ? {
              email: Yup.string()
                .required(`${translate('Email')} ${translate('obrigatório')}`),               
              password: Yup.string().required(`${translate('Senha')} ${translate('obrigatória')}`),
            }
          : {
              email: Yup.string()
                .required(`${translate('Email')} ${translate('obrigatório')}`)
                
            };

        const schema = Yup.object().shape(shape);
        const { email : email_trim } = data;
        data.email = email_trim.trim();

        await schema.validate(data, { abortEarly: false });

        const { email, name, password } = data;
        
        await signIn({ email, password, name });
        
        window.location.href = '/dashboard';
        return;
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
        setLoading(false);

        const { status} = err?.response;

        if(status >= 500){
        
          setErrorMessage ({title : 'Um momento...', description: 'Estamos com alta demanda de pedidos de acesso. <br/><br/>Por favor refaça seu login em 1 minuto para acessar.'})
          setTimeout(() => {
            setErrorMessage({});
          },60000)
        
        }
        else{

          setErrorMessage ({title : 'E-mail não identificado', description: 'Se você ainda não se cadastrou, clique em Cadastrar.'})
          setShowCadastrar(true);
          setTimeout(() => {
            setErrorMessage({});
          },5000)

         
        }


   
       
      }
    },
    [signIn, addToast, history],
  );

  return (<>
  {loading  && <Loader message="Autenticando seu acesso ..."/>}

  <div style={{display:'flex', alignItems:'center', marginTop:'25px', justifyContent:'center', width: '100%'}}>
  <strong onClick={() => setShowSignUp(false)} style={{color: '#fff', margin: '10px',cursor: 'pointer', borderBottom: `${showSignUp ? '0' : '2'}px solid #fff`}}>
         Login 
         </strong> 
         <strong onClick={() => setShowSignUp(true)} style={{color: '#fff', margin: '10px', cursor: 'pointer', borderBottom: `${!showSignUp ? '0' : '2'}px solid #fff`}}> 
         Cadastrar 
           </strong>
  </div>
    <Container>
    
      <Content>
        {!showSignUp ? <AnimatedDiv2 className='popup shadow'>
        
          { manutencao ?         <div className="onlineMessage ">
           
           <h2 className="defaultMarked"><div/>{translate('Estamos em manutenção')}</h2>
           <p style={{color: '#333', textAlign: 'center',marginTop: '20px'}}>
             {translate(
               'Voltamos em breve!',
             )}
           </p><br/>
         </div> : <Form className="form" ref={formRef} onSubmit={handleSubmit}>

           <img src="/apoio/logoblack.svg" style={{width: '100%', maxWidth: '250px', margin: '20px auto'}}/>
          <h2 style={{margin: '20px auto', color: '#231F20'}} className="titleElement">{translate('Insira seu email para acessar!')}</h2>

                    <Input label="" name="email" placeholder={translate('E-mail')} />
                  { withPassword &&  <Input
                      
                      name="password"
                      type="password"
                      placeholder={translate('Senha')}
                    /> }

                    { errorMessage && errorMessage.title && <ErrorContainer>
                      <h2>{errorMessage.title}</h2>
                      <p dangerouslySetInnerHTML={{__html: errorMessage.description}}/>
                      </ErrorContainer>}

                     {showCadastrar ?   <aside style={{display:'flex', alignItems:'center', marginTop:'25px', justifyContent:'center', width: '100%'}}>
  
         <strong onClick={() => setShowSignUp(true)} style={{color: '#333', margin: '10px', cursor: 'pointer', borderBottom: '2px solid #333'}}> 
         Cadastrar 
           </strong>
  </aside> : <></>}

                    <button className='defaultButtonReverse' style={{margin:'0px auto',marginTop:'40px'}} type="submit">{translate('Entrar')}</button>
                    { withPassword &&    <> <p style={{margin: '10px 0px'}}>Caso tenha dificuldade em logar, solicite uma nova senha.</p>
                    <Link style={{ color: '#fff' }} to="/recuperar_senha"><Button>{translate('Esqueci minha senha')}</Button>
                    
                    </Link></>}

                    <Link style={{ color: '#4278BC' }} to="/">{translate('VOLTAR')}
                    </Link>

             
          </Form>}
        </AnimatedDiv2> : <></>}
        { showSignUp ? <SignUp/> : <></>}
      </Content>
    </Container></>
  );
};
export default SignIn;
