import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';
import { ISigla, readSigla, siglas as siglasRef } from './siglas';
import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../config';
import { countries } from './countries';
import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import { useLanguage } from '../../../hooks/Language';

interface IRecoverFormData {
  [key : string] : any;
}


interface IRead {
  [key: string]: any;
}


interface ICaptcha {
  getValue(): string;
}

const FormContainer = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { translate} = useLanguage();
  const [errorMessage, setErrorMessage] = useState<Array<string>>([]);
  const [withPassword, setWithPassword] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };
const siglas = readSigla();
  const {addToast} = useToast();

  const [countryISO, setCountryISO] = useState('BR');

  const getCepFromApi = async (value: string): Promise<void> => {
    const cepResponse = await getCep(value);

    if (!cepResponse.error) {
      formRef?.current?.setFieldValue('address', cepResponse.logradouro);
      formRef?.current?.setFieldValue('neighborhood', cepResponse.bairro);
      formRef?.current?.setFieldValue('city', cepResponse.localidade);
      formRef?.current?.setFieldValue('state', cepResponse.uf);
    }
  };


  const handleNewLogin = async (data: IRecoverFormData) => {
    setLoading(true);
    try {
      formRef.current?.setErrors({});
      const schema = Yup.object().shape({
        name: Yup.string().required('Nome obrigatório'),
        email: Yup.string()
          .required('Email obrigatório')
          .email('Preencha um email válido'),
          phone: Yup.string().required('Whatsapp / Fone de contato obrigatório'),

 
        term: Yup.string().required('Informe se você autoriza o compartilhamento de seus dados.'),
      });
   

      data.email = data?.email.trim().toLowerCase() || '';
 
      await schema.validate(data, { abortEarly: false });

      const {
        name,
        email,
        password,
        company,
        phone,
        position,
        know_about,
        birth_date,
        company_document_number,
        extra_1,
        extra_2,
        extra_3,
        extra_4,
        education_id,
        especialidade,
        term,
        country,
          city,
          state,
      } = data;

      const captchaValue =
      getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
      false;

      const newUser = await api
        .post('/signup', {
          name,
          email,
          phone,
          country,
          term
        })
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            console.log(error);
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao cadastrar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }

      window.location.href = '/confirmado';
      //setSuccess(true);
      setLoading(false);
     

      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }

  return (
    <>
      {loading === true && (
        <Loader message="Preparando seu cadastro..." position="fixed" />
      )}

      <aside>
        {success ? (
          <ResponseMessage
            active
            type="clean"
            title=""
            description={
              <p style={{color: '#231F20',fontSize: '14px'}}>
                <h2 className="titleH2" style={{color: '#231F20', fontSize: '18px'}}> Cadastro realizado com sucesso!</h2>
          
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px auto'}}>
                <Link to="login" >
                  <button className="defaultButtonReverse" type="button">
                    Acessar
                  </button>
                </Link>
                </div>

                {translate('Aproveite e indique para seus amigos!')}
                <Whatsapp
                  text={translate(`Olá! Acabei de me inscrever no ${authTitle}. Inscreva-se também!. ${urlWebsite}`)}
                  number=""
                  message={translate('Compartilhe!')}
                />
                
                
              </p>
            }
          />
        ) : (<>
           
                  <Form name='Palco-Fronteiras-2022' ref={formRef} onSubmit={handleNewLogin} initialData={{country: 'BR'}}>

                    <p style={{fontWeight: 500, textAlign:'left', width: '100%', marginBottom: '25px'}}>Preencha o formulário abaixo e garanta sua vaga</p>
                 <p>País</p>
                    <SelectSimple setCallback={(value) => setCountryISO(value) } options={countries} name="country" label={translate('')}/>
                   

                    <Input name="name" label='Nome completo:' placeholder="insira seu nome completo" />

                    <Input name="email" label='E-mail:' placeholder="insira seu melhor e-mail" />

                    { countryISO === 'BR' ? <MaskedInput mask="(99) 99999 - 9999" name="phone" label='Celular:' placeholder="(00) 00000 - 0000" /> : <Input   name="phone" label='Celular:' placeholder="" />}



           
<CheckboxInput
              style={{ display: 'flex'  }}
              name="term"
              unique={true}
              preText={(
                <>
                  {' '}
                  Estou de acordo em fornecer meus dados pessoais para acesso ao Palco Fronteiras que ocorrerá nos dias 18/05 e 20/06, e ciente de que serão tratados e compartilhados apenas com os parceiros do Fronteiras do Pensamento neste evento.
{' '}
                 
                </>
              )}
              options={[
                {
                  id: `Sim, eu autorizo o tratamento e o uso dos meus dados`,
                  label: 'Sim, eu autorizo o tratamento e o uso dos meus dados',
                  value: `Sim, eu autorizo o tratamento e o uso dos meus dados`,
                },
                {
                  id: `Não autorizo o tratamento e o uso dos meus dados`,
                  label: 'Não autorizo o tratamento e o uso dos meus dados',
                  value: `Não autorizo o tratamento e o uso dos meus dados`,
                },
              ]}
            />

<p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
               
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
               Os dados fornecidos poderão ser utilizados para envio de conteúdos, promoções, divulgações, notícias e marketing do Fronteiras do Pensamento. Ao me cadastrar, afirmo que li e aceito a 
               <a
                    style={{ display: 'inline' }}
                    href="https://fronteiras.com/politica-de-privacidade"
                    target="_BLANK"
                  ><strong style={{fontSize:'12px'}}>Política de Privacidade</strong></a> desta plataforma.

              </p>

                    <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

                    </aside>  <aside style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <button className="defaultButtonReverse" type="submit">
                    QUERO ME INSCREVER!

                  
                    </button>
                    </aside>
                  </Form>
        </>
        )}
      </aside>
    </>
  );
};

export default FormContainer;
