import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import AreaExposicao from '../AreaExposicao';
import AreaInstitucional from '../AreaInstitucional';
import { ContactIcons, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';
import { logo } from '../../config';
import {
  Container,
  SecondaryContainer,
  ContainerWhite,
  GreyContainer,
  Capa,
  ProgramContainer,
  Sponsors,
  Photo,
} from './styles';

import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import { scrollTo } from '../../components/Menu';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';


import Publicas from './modules/publicas';

import { usePages } from '../../hooks/Pages';

import SponsorsList from '../Sponsors';
import Program from '../Program';
import Youtube from '../../components/Youtube';
import Speakers from '../Speakers';
import Input from 'react-select/src/components/Input';
import SignUp from '../SignUp';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Confirmado: React.FC = () => {



  return (
    <>
    <Container >
      <Capa className='home'>
        <div className='homeContent' >

        <div className='imageCapa' >
       <img  src="/apoio/palco2-branco.png"/> 

        </div>
        <p className='p1' style={{margin: '0px auto', marginTop: '25px'}}>Parabéns, você acaba de se inscrever no</p>

        <h2 className='bold'>PALCO FRONTEIRAS 2022</h2>

          <div className='liner'/>


          <p className='p2'>Em breve, você receberá um e-mail com informações sobre o evento. 
            <br/><br/>
            Enquanto isso, acompanhe as novidades do Fronteiras do Pensamento em nossas redes sociais:</p>


         <div className='iconesCapa'>
           <a href="http://facebook.com/fronteirasweb" target="_BLANK">
              <img src="/redes-sociais/facebook.svg"/>
           </a>
          
           <a href="https://instagram.com/fronteirasweb" target="_BLANK">
              <img src="/redes-sociais/instagram.svg"/>
           </a>

           <a href="https://www.linkedin.com/company/fronteiras-do-pensamento" target="_BLANK">
              <img src="/redes-sociais/linkedin.svg"/>
           </a>

           <a href="http://youtube.com/fronteiraspoa" target="_BLANK">
              <img src="/redes-sociais/youtube.svg"/>
           </a>
         </div>
   </div>
    

 
      </Capa>
      </Container>
<Container style={{background: '#231F20', minHeight: 'auto', padding: '50px 10px'}}>
        <div className='pfull' style={{justifyContent:'center', flexWrap:'wrap',  }}>

        <div>
          <img style={{width:'180px'}} src="/apoio/logobranca.png"/>
         

          </div>
          <div>
          <p style={{fontSize: '12px'}}>© 2022 FRONTEIRAS DO PENSAMENTO. TODOS OS DIREITOS RESERVADOS</p>
          </div>
          
        </div>

        <a href="https://fronteiras.com/politica-de-privacidade"><p style={{fontSize: '12px', color: '#fff'}}>POLÍTICA DE PRIVACIDADE</p></a>

        </Container>
    
    </>
  );
};
export default Confirmado;
