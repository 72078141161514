import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import da from 'date-fns/esm/locale/da/index.js';
import React, { ButtonHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import { FaUserCircle } from 'react-icons/fa';
import { FiMessageCircle, FiPhone, FiSearch, FiSend, FiSidebar } from 'react-icons/fi';
import { MdMessage } from 'react-icons/md';
import { Socket } from 'socket.io-client';
import { uuid } from 'uuidv4';
import { useAuth } from '../../../../hooks/Auth';
import { useSocket } from '../../../../hooks/Socket';
import Input from '../../core/components/Forms/Input';
import Profile from '../../core/components/Profile';
import api from '../../../../services/api';

import {Container, Body, Sidebar, ProfileBox, MessagesBox, FormBox,Message, Contact, PrivateMessageButton, SearchBox,
Header, ActiveButton, Photo, GlobalButton, ConfigBox} from './styles';
import { IconType } from 'react-icons';
import { useToast } from '../../../../hooks/Toast';



/*
Pergunta rápida
Onde eu armazeno as perguntas?
No profile.
*/

interface ContactInfo {
  user_hash ?: string;
  name ?: string;
  apelido ?: string;
  company ?: string;
  position ?: string;
  image ?: string;
  notFound ?: boolean;
  
}

interface IMessages {
  hash : string;
  from: string;
  message : string;
  loves : number;
  likes : number;
  created_at : Date;

}

interface IContact {
  profile : {
    user_hash : string;
    image : string;
    name : string;
    apelido : string;
    last_message ?: string;
    last_update ?: Date;
  }
  messages : Array<IMessages>;
}

interface IProps {
  typeInfo ?: string;
  Icon?: IconType
}

const ChatPrivate: React.FC<IProps> = ({ Icon = MdMessage, typeInfo = 'mix' }) => {
  const {socket} = useSocket();
  const {user} = useAuth();
const {addToast} = useToast();
  const [contacts, setContacts] = useState<Array<IContact>>([]);
  const [currentContact,setCurrentContact] = useState(-1);
  const [currentMessage,setCurrentMessage] = useState('');
  const [active,setActive] = useState(false);
  const [listContacts,setListContacts] = useState<Array<ContactInfo>>([]);
  const [currentContactSearch,setCurrentContactSearch] = useState('');
  const [type, setType] = useState('mix');
  const [loading,setLoading] = useState(false);
  const [profileKey,setProfileKey] = useState('profile');
  const [myProfile,setMyProfile] = useState({
    find_me : 1
  })

  const [lastMessage,setLastMessage] = useState('');

  const [alerts,setAlerts] = useState({});


  const clearAlert = useCallback(() => {
    setAlerts( state => { Object.keys(state ).map( key => {
      addToast({ type: 'info', position:'bottom', title: state [key].profile.name, description: state [key].message.message});
    })


return {};
  })
   
  },[alerts]);

  useEffect( () => {

    const getter = setInterval( () => {

    
      
     // clearAlert();
     
   
  },1000);

return clearInterval(getter);
  },[])

  const searchFormRef = useRef<FormHandles>(null);
  const messageFormRef = useRef<FormHandles>(null);

  useEffect(() => {
    if(type === 'configuration'){
      setProfileKey(uuid());
    }
  },[type])

  const alertNewMessage = (data) => {

  }




 

  const connectPrivateMessagesModule = (selectType) => {
    setType(selectType);
    setActive(true);
    if(contacts.length < 1){
      socket.emit('PrivateMessages:GetLoadContacts')
    }
  }

  const receivePrivateMessage = useCallback((data) => {

    const {profile, message, from} = data;

    if(from !== user.user_hash){
    addToast({ type: 'info', position:'bottom', title: profile.name, description: message.message});
    }
    setContacts( state => {
      
     
      const findContactIndex = state.findIndex( c => {

        return  c?.profile?.user_hash === profile?.user_hash
        
        });
    
    

       profile.last_message = message.message;

       setAlerts( state => ({...state,[message.hash] : { profile, message }}));
    
       if(findContactIndex < 0){
         return  [{ profile, messages: [message] },...state]
       }
       else{

        

         const newContacts = [...state];
         newContacts[findContactIndex].profile.last_message = message.message;
         newContacts[findContactIndex].profile.last_update = message.created_at;
         const indexMessage = newContacts[findContactIndex].messages.findIndex( m => m.hash === message.hash);
         if(indexMessage < 0){
          
         newContacts[findContactIndex].messages.unshift(message);
         }
         return [...newContacts]
       }


    })

  
   
  

  },[contacts,lastMessage]);

  const loadConfigutation = async () => {
    setLoading(true);

    const response = await api.get('/students/getConfiguration');
    setLoading(false);
    if(response){
      setMyProfile({...response?.data})
    }
  }


  const sendPrivateMessage = (data) => {

    
    const {user_hash, image, name, apelido} = user;
    const to = contacts[currentContact]?.profile?.user_hash;
    console.log(to);

    const newMessage = {
    
      profile : {
        user_hash, 
        image, 
        name, 
        apelido
      },
      message : {
        hash : uuid(),
        from: name,
        message : data.message,
        loves : 0,
        likes : 0,
        created_at : new Date()
      }
    }

    console.log('Envio',newMessage);

    socket.emit('PrivateMessages:SendMessage', { 
      to, 
      ...newMessage  
  });

  messageFormRef?.current?.clearField('message');


  }

  useEffect(() => {
    let messageLog = '';

    socket.on("PrivateMessages:ReceiveMessage", ( data ) => {

      if(messageLog != data.message.hash){
        messageLog = data.message.hash;
        receivePrivateMessage(data);
      }
  
    })

    socket.on("PrivateMessages:LoadContacts",(data) => {
      
      setContacts(state => data);
    })

    socket.on("reconnect",() => {
    
      
      socket.emit('PrivateMessages:GetLoadContacts')
    })

  },[])

  const ProfileInfo = () => {
    if(currentContact === -1){
      return <></>
    }

    return <>
    <Photo background={contacts[currentContact]?.profile?.image || '/apoio/user.jpg'}/>

    <div style={{display:'flex', flexDirection:'column', alignItems: 'flex-start', 'justifyContent': 'center'}}>
      <p className="contentMessage" style={{color: '#fff'}}><strong>{contacts[currentContact]?.profile?.name || 'Sem nome'}</strong></p>
      <span style={{color: '#fff'}}>{contacts[currentContact]?.profile?.last_message}</span>
      
    </div>
    </>
  }

  const messages = (current = -1) => {

    return <>
    {contacts?.[current]?.messages.map( messageInfo => {
      return <Message key={messageInfo.hash}>
        <strong>{messageInfo.from}</strong>
        <p>{messageInfo?.message}</p>
        </Message>


    })}
    </>
  }

  const activeContact = (index) => {
    setCurrentContact(index);
    setCurrentContactSearch('');
    setListContacts([]);
    setType('mix');
  }

  useEffect(() => {
    if(type === 'search'){
      searchContacts({ search : ''})
    }
  },[type])

  const Contacts = () => {
    if(!contacts || contacts.length < 1){
      return type === 'contacts' ? <p className="contentMessage">Nenhum contato identificado</p> : <></>;
    }

    const listContacts = [...contacts];

    /* A lista de Contatos é atualizada pelo último update */

    listContacts.sort(function(a,b){

      const checkB = b.profile.last_update ? new Date(b.profile.last_update).getMilliseconds() : new Date().getMilliseconds();
      const checkA = a.profile.last_update ? new Date(a.profile.last_update).getMilliseconds() : new Date().getMilliseconds();

      return checkA - checkB;
    });

    return <>
    {listContacts?.map( (contact,index) => {
      return <Contact onClick={() => activeContact(index)} key={contact?.profile?.user_hash}>

        <Photo background={contact?.profile?.image || '/apoio/user.jpg'}> 
        {!contact?.profile?.image && <strong>{contact?.profile?.name && contact?.profile?.name.charAt(0)}</strong>}
         </Photo>
    { type !== 'mix' && <div>
      <p className="contentMessage">{contact?.profile?.name || 'Sem nome'}<br/>
      <span> - {contact?.profile?.last_message}</span>
      </p>
    </div>}
        </Contact>


    })}
    </>
  }

  const searchContacts = async (data) => {
setLoading(true);
setListContacts( [] );
    const response = await api.post('/findContacts',{ search : data.search});
    setLoading(false);
    if(response && response?.data?.rows?.length > 0){
      setListContacts(response?.data?.rows || [] );
    }
    else{
      setListContacts( [{ notFound: true}] );
    }

  }



  const addToContact = (list) => {

    const { user_hash, image, name, apelido} = list;

    const newContact = {
      profile : {
        user_hash,
        image,
        name,
        apelido,
        last_message : '',
        last_update : new Date()
      },
      messages : []
    }

    const length = contacts.length;
    setContacts( state => [...state, newContact]);
    activeContact(length);
    searchFormRef?.current?.clearField('search');


  }
  
  const changeProfile = () => {
    
  }


  return <> { active && <Container>
    <Header>
      <ActiveButton style={{borderLeft:'2px solid #000'}} onClick={ () => setType('search')} active={ type === 'search'} type="button"><FiSearch/></ActiveButton>
      { /* <ActiveButton onClick={ () => setType('message')} active={ type === 'message'} type="button"><FiMessageCircle/></ActiveButton> */}
      <ActiveButton onClick={ () => setType('contacts')} active={ type === 'contacts'} type="button"><FiPhone/></ActiveButton>
      <ActiveButton onClick={ () => setType('mix')} active={ type === 'mix'} type="button"><FiSidebar/></ActiveButton>
      <ActiveButton onClick={ () => setType('configuration')} active={ type === 'configuration'} type="button"><FaUserCircle/></ActiveButton>
      <ActiveButton  active={false} style={{width:'auto', padding: '10px 20px'}} onClick={() => setActive(false)} type="button">Fechar</ActiveButton>
    </Header>
{ (type === 'search') && <SearchBox>
  <h2 className="contentMessage">Pesquisar participantes</h2>
  <Form ref={searchFormRef} onSubmit={searchContacts}>
  <Input name="search"/>
  <button ><FiSearch/></button>
  </Form>
  <div>
    {loading && <p className="contentMessage">Carregando ...</p>}
    {listContacts?.[0]?.notFound === true && 
      <p className="contentMessage">Nenhum participante encontrado</p>}
    
    {listContacts.length > 0 && !listContacts?.[0]?.notFound && listContacts.map( list => <div onClick={() => addToContact(list)}>
      <Photo background={list.image || '/apoio/user.jpg'}/>
      <p>{list.name}</p>
      
      </div>)}
  </div>
</SearchBox>}
<div style={{width: '100%', maxWidth: '500px', display: 'flex'}}>
  { (type === 'message' || type === 'mix') && <Body>
    <ProfileBox>
      <ProfileInfo/>
    </ProfileBox>
    <FormBox>

    { currentContact >= 0 ? <Form ref={messageFormRef} onSubmit={sendPrivateMessage}>
  <Input name="message"/>

    <button ><FiSend/></button>
</Form> : <><p className="contentMessage">Selecione um contato para iniciar<br/> Ou pesquise um participante</p> <button className="defaultButton" style={{margin: '10px auto'}} onClick={() => setType('search')}>Pesquisa participante</button>
<p className="contentMessage">Para permitir que outros participantes te encontrem, libere esta opção nas configurações</p>
<button className="defaultButton" style={{margin: '10px auto'}} onClick={() => setType('configuration')}>Configurações</button>
</>}
    </FormBox>
    <MessagesBox>
      {messages(currentContact)}
    </MessagesBox>
  </Body>}
  { (type === 'contacts' || type === 'mix') &&  <Sidebar style={{maxWidth: type==="mix" ? '100px' : '100%' }}>
  { type ==="contacts" && <h2 className="contentMessage">Contatos salvos</h2> }
  <Contacts/>
</Sidebar>}</div>

{ type === 'configuration' && <ConfigBox>
  <Profile key={profileKey}/>
</ConfigBox>}
  </Container>}
  <GlobalButton type="button" onClick={() => connectPrivateMessagesModule('mix')}><MdMessage/></GlobalButton>
<GlobalButton type="button" onClick={() => connectPrivateMessagesModule('configuration')}><FaUserCircle/></GlobalButton>
</>
};

export default ChatPrivate;