import React from 'react';
import { Switch } from 'react-router-dom';
import { home } from '../config';
import Route from './routes';
import SignIn from '../pages/SignIn';
import Sobre from '../pages/Sobre';
import Sobre2 from '../pages/Sobre2';
import SignInAdmin from '../pages/SignInAdmin';
import SignUp from '../pages/SignUp';
import RecoverPassword from '../pages/RecoverPassword';
import ResetPassword from '../pages/ResetPassword';
import Products from '../pages/Products';

import VideosDashboard from '../pages/VideosDashboard';
import Dashboard from '../pages/DashboardPages';

import Home from '../pages/Home';
import HomeEducacao from '../pages/HomeEducacao';
import AreaExposicao from '../pages/AreaExposicao';
import Expositor from '../pages/Expositor';
import Galeria from '../pages/Galeria';
import ExpositorChat from '../pages/ExpositorChat';
import SigninExpositor from '../pages/SigninExpositor';
import Program from '../pages/Program';
import Poster from '../pages/Poster';

import Validate from '../pages/Validate';
import Zoom from '../components/ZoomStarter';
import SpeakerLink from '../pages/SpeakerLink';
import ModeratorLink from '../pages/ModeratorLink';
import Certificados from '../pages/Certificados';
import Termos from '../pages/Termos';
import Confirmado from '../pages/Confirmado';
const ValidPaths = {
  SignIn,
  SignInAdmin,
  SignUp,
  RecoverPassword,
  ResetPassword,
  Products,
  VideosDashboard,
 
  Home,
  HomeEducacao,
  Sobre,
};

const Routes: React.FC = () => {
  return (
    <Switch>
      <Route
        
        path="/"
        exact
        component={ValidPaths[home] ? ValidPaths[home] : Home}
      />
       <Route  path="/confirmado" component={Confirmado} />
      <Route  path="/login" component={Home} />
      <Route isPrivate path="/poster" component={Poster} />
      <Route template="client" path="/validate/:hash" component={Validate} />
      <Route template="client" path="/events/access/:hash" component={SpeakerLink} />
       <Route path="/zoom" component={Zoom} />
       <Route path="/termos" component={Termos} />
     <Route template="client" path="/moderator/access/:hash" component={ModeratorLink} />
     <Route  path="/inscricao" component={Home} />
      <Route  path="/exposicao" component={AreaExposicao} />
      <Route isPrivate path="/programacao" component={Program} />
      <Route template="client" path="/admin" component={SignInAdmin} />
      <Route isPrivate path="/login_expositor" component={SigninExpositor} />
      <Route isPrivate path="/mostra" component={Sobre} />
      <Route   path="/certificados" component={Certificados} />
      <Route isPrivate path="/congresso-kids" component={Sobre2} />
      <Route
        
        path="/expositores/:link_name"
     
        component={ExpositorChat}
      />
      <Route
        isPrivate
        path="/galeria/:link_name"
        template="page"
        component={Galeria}
      />
      <Route
        isPrivate
        path="/expositores_chat/:link_name"
        template="page"
        component={ExpositorChat}
      />
      <Route path="/dashboard/categorias/:group_link" isPrivate component={VideosDashboard} />
      <Route
        isPrivate
        path="/dashboard/:videoIdItem"
        component={VideosDashboard}
      />
      
      <Route path="/dashboard" isPrivate component={VideosDashboard} />
      <Route path="/mini-cursos" isPrivate component={() => <VideosDashboard course={'27'}/>} />
      <Route
        path="/manager/:tab"
        template="dashboard"
        isPrivate
        isSecret
        component={Dashboard}
      />
      <Route
        path="/manager"
        template="dashboard"
        isPrivate
        exact
        isSecret
        component={Dashboard}
      />
   
 
    </Switch>
  );
};

export default Routes;
