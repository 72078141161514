import styled, { css } from 'styled-components';

interface IProps {
  position : string;
}

export const Container = styled.div<IProps>`
  position: fixed;
  right: 0;
  top: 0;

  ${ props => props.position === 'bottom' && css`
  top: auto;
bottom: 0px;
  `}
  padding-top: 5px;
  padding-right: 30px;
  overflow: hidden;
  z-index: 300000000000;
`;
