import React, { useEffect } from 'react';
import { MdChat, MdChatBubble } from 'react-icons/md';
import { Container, Sponsor, SponsorGrid } from './styles';
import { Button } from '../../components/Button/styles';
import Sponsors from '../Sponsors';
import Particles from 'react-particles-js';
import ImageSliderBackground from '../../components/ImageBackgroundSlider';

const FooterLayout: React.FC = ({ children }) => {
  return (    <div style={{zIndex:-1}}>
  { /*
  <ImageSliderBackground slides={['/apoio/background/BG_amazonia_1_1.jpeg',
      '/apoio/background/BG_amazonia_2_1.jpeg',
      '/apoio/background/BG_amazonia_3_1.jpeg',
    '/apoio/background/BG_amazonia_4_1.jpeg',
  '/apoio/background/BG_amazonia_5_1.jpeg']} speed={15000}/>
  <Particles
        params={{
          particles: {
            lineLinked: {
              enable: false,
            },
            move: {
              speed: 1.5,
              direction: 'top-right',
              collisions: false,
              outModes: {
                default: 'out',
                left: 'out',
                right: 'out',
                bottom: 'out',
                top: 'out',
              },

             
            },
            opacity: {
              value: 1,
              random: false,
              animation: {
                enable: false
              }

            },
            shape: {
              type: 'image',
              polygon: {
               
                nb_sides: 6,
                
              },
              image: {
                src: "/apoio/birds/bird2.gif",
                width: 100,
                height: 100
              }
            },
     
            color: { value: 'rgb(0,100,200)' },

            number: {
              density: {
                enable: true,
                value_area: 3400,
              },
              value: 25,
            },
            size: {
              value: 92,
              random: true,
            },
          },
        }}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: '100%',
          height: '100%',
          zIndex: 0
        }}
      />*/}
  
      </div> 
  );
};

export default FooterLayout;
