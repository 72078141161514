import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';

import { Link} from 'react-router-dom';

import {

  FaCalendarCheck,
  FaArrowDown,
} from 'react-icons/fa';

import { IconType } from 'react-icons/lib';

import { logo, urlWebsite } from '../../config';
import { Container, SecondaryContainer, ContainerWhite, GreyContainer, Capa, Sponsors, Photo } from './styles';

import Button from '../../components/Button';

import ContagemRegressiva from '../../components/ContagemRegressiva';
import Logo from '../../components/Logo';
import Speakers from '../Speakers';
import { usePages } from '../../hooks/Pages';

import SponsorsList from '../Sponsors';
import { useLanguage } from '../../hooks/Language';
import api from '../../services/api';


interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IEventData {
  title : string;
  date_text : string;
  start_at : string;
  image : string;
  selected : number;
}



const SignIn: React.FC = () => {

  const [eventData, setEventData] = useState<IEventData>({} as IEventData);
  const { translate, language} = useLanguage();



  const [showProgram, setShowProgram]  = useState(false);


  const [agenda, setAgenda]  = useState([] as Array<IEventData>);
  const loadEvent = async () => {
    const response = await api.get('/events-list');

    if(response.data && response.data){

      const index = response.data.findIndex( item => item.selected === 2);
      if(index >= 0){
        setEventData(response.data[index]);
      }
      

      setAgenda(response.data);
    }

  }

useEffect( () => {

   loadEvent();

},[])
  


  return (
    <>
      <Capa>

<div className="p50">

<div className="" style={{width: '100%', marginBottom:"20px", display:"flex", flexWrap:'wrap', flexDirection:'row', justifyContent: 'center'}}> <Button type="button" style={{cursor:'default', color: '#fff', fontWeight:'bold', marginBottom:'10px'}}>{translate(eventData?.date_text || '')}</Button> 
<Link className="" to="/inscricao"><Button type="button">{translate('Inscrição')}</Button> </Link>
<Link className="" to="/login"><Button type="button">{translate('Acessar')}</Button> </Link>  
<Button onClick={() => setShowProgram(!showProgram)} type="button">{translate('Programação')}</Button>  </div>



<img src={eventData.image ? ( language === 'en' ? `${urlWebsite}/apoio/ingles.png` : `${urlWebsite}/${eventData.image}`)  :`${urlWebsite}/assets/logofronteiras.png`} style={{width:'90%', maxWidth: '450px'}}/>


<ContagemRegressiva dataDesejada={eventData.start_at} />

</div>
<div className="p50">


         <div className="capaTitle animate" style={{display: 'flex', marginBottom: '50px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
   <div className="capaTitle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', maxWidth: '450px', margin: '20px auto'}}>
           <FaCalendarCheck size={60}/>
           <div style={{padding: '20px'}} >
<h2 style={{fontSize: '32px'}}>{translate('Agenda de Lives')}</h2>

</div>
         </div>
   {agenda.map( ag =>
            <div style={{ width: '100%', maxWidth: '550px',padding: '10px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', borderBottom: '2px solid #fff'}} >
           <p style={{fontSize: '14px',  color: ag.selected === 2 ? '#fff' : '#E65E01', width: '150px', minWidth:'150px', maxWidth:'150px',  marginRight: '15px', borderRadius: '10px', textAlign: 'center', background :  ag.selected === 2 ? '#E65E01' : '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px'}}>{translate(ag.date_text)}</p><p style={{fontSize: '14px'}}>
           {translate(ag.title)}</p>
          </div>
          
          ) }

          </div></div>
      </Capa>
    
    { showProgram && 
    <div style={{position:'relative',zIndex:10000}}>
   <div  style={{background: 'rgba(0,0,100,0.9)',display: 'flex', alignItems: 'flex-start', justifyContent: 'center', position: 'fixed', top:0, left: 0, right: 0, bottom: 0, width: '100%', minHeight: '100vh', overflowY:'auto'}}>
   
   <div className="capaTitle animate" style={{display: 'flex', marginBottom: '50px', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
   <Button onClick={() => setShowProgram(!showProgram)} type="button">{translate('Fechar')}</Button> 
   <div className="capaTitle" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', maxWidth: '350px', margin: '20px auto'}}>
           <FaCalendarCheck size={60}/>
           <div style={{padding: '20px'}} >
<h2 style={{fontSize: '32px'}}>Agenda de Lives</h2>

</div>
         </div>
   {agenda.map( ag =>
            <div style={{ width: '100%', maxWidth: '550px',padding: '10px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', borderBottom: '2px solid #fff'}} >
           <p style={{fontSize: '14px',  color: ag.selected === 2 ? '#fff' : '#E65E01', width: '150px', minWidth:'150px', maxWidth:'150px',  marginRight: '15px', borderRadius: '10px', textAlign: 'center', background :  ag.selected === 2 ? '#E65E01' : '#fff', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '50px'}}>{ag.date_text}</p><p style={{fontSize: '14px'}}>
           {ag.title}</p>
          </div>
          
          ) }

          </div>
          </div></div>}
  
  

    </>
  );
};
export default SignIn;
