import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules';
import { Container } from './styles';


interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

export const searchable: Record<string, any> = {
  id: { ref: 'id', column: 'id', label: 'ID'  },
  user_hash: { ref: 'user_hash', column: 'user_hash', label: 'Hash', show: false },
  name: { ref: 'name', column: 'name', label: 'Nome' },
  email: { ref: 'email', column: 'email', label: 'Email' },
  phone: { ref: 'phone', column: 'phone', label: 'Telefone' },
  company: { ref: 'company', column: 'company', label: 'Organização' },
  position: { ref: 'position', column: 'position', label: 'Cargo' },
  know_about: { ref: 'know_about', column: 'know_about', label: 'Sugestão' },
  checkin: {
    ref: 'checkin',
    column: 'checkin_response',
    includeColumn: 'title',
    label: 'Presença',
    filter: true,
  },

  createdAt: {
    ref: 'created_at',
    column: 'createdAt',
    label: 'Data de Criação',
    mask: 'date',
  },
};

const Dashboard: React.FC = () => {
  const endpoint = '/students';
  const title = 'Participantes';
  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID'  },
    user_hash: { ref: 'user_hash', column: 'user_hash', label: 'Hash', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },
    phone: { ref: 'phone', column: 'phone', label: 'Telefone' },
    company: { ref: 'company', column: 'company', label: 'Organização' },
    position: { ref: 'position', column: 'position', label: 'Cargo' },
    know_about: { ref: 'know_about', column: 'know_about', label: 'Sugestão' },
    checkin: {
      ref: 'checkin',
      column: 'checkin_response',
      includeColumn: 'title',
      label: 'Presença',
      filter: true,
    },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {
   
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },

    email: { model: 'input', type: 'text', name: 'email', label: 'Email' },
    document_number: { model: 'input', type: 'text', name: 'document_number', label: 'CPF' },
    zipcode: { model: 'input', type: 'text', name: 'zipcode', label: 'CEP' },
    instituicao: {
      model: 'input',
      type: 'text',
      name: 'instituicao',
      label: 'Instituição',
    },

    password_hash: {
      model: 'input',
      type: 'password',
      name: 'password_hash',
      label: 'Senha',
    },
    profile: {
      model: 'selectSimple',
      name: 'profile',
      label: 'Nível',
      defaultValue: '2',
      options: [
        { label: 'Administrador', value: 1 },
        { label: 'Participante', value: 2 },
        { label: 'Mediador / Palestrante', value: 3 },
        { label: 'Organização', value: 4 },
        { label: 'Expositor', value: 5 },
        { label: 'Moderador de Chat', value: 6 },
        { label: 'Usuário de Teste', value: 7 },
        { label: 'Leitor', value: 8 },
      ],
    },
  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  
  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
        () =>
        lineModules.crachaLote({
           
          }),
    ], 

    bodyModules: [
      (lineData: Record<string, any>) =>
      lineModules.cracha({
        lineData,
        title,
        formSchema : formSchema,
        validationSchema: {},
        endpoint,
      }),
      (lineData: Record<string, any>) =>
      lineModules.qrCode({
        lineData,
        title,
      
      }),

      (lineData: Record<string, any>) =>
      lineModules.credenciar({
        lineData,
        title,
        formSchema : formSchema,
        validationSchema: {},
        endpoint,
      }),
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema : formSchema,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
