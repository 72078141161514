import React, { useCallback, useRef, useEffect, useState } from 'react';
import {
  FiLogIn,
  FiMail,
  FiLock,
  FiKey,
  FiMessageCircle,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { rgb } from 'polished';
import {
  FaConnectdevelop,
  FaLock,
  FaHandPointUp,
  FaCertificate,
  FaTicketAlt,
  FaVideo,
  FaAward,
  FaSlideshare,
  FaKey,
  FaSpinner,
} from 'react-icons/fa';
import { MdColorLens, MdMessage } from 'react-icons/md';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import { IconType } from 'react-icons/lib';
import AreaExposicao from '../AreaExposicao';
import AreaInstitucional from '../AreaInstitucional';
import { ContactIcons, WhatsappIcon } from '../../components/Menu';
import Whatsapp from '../../components/Whatsapp';
import { logo } from '../../config';
import {
  Container,
  SecondaryContainer,
  ContainerWhite,
  GreyContainer,
  Capa,
  ProgramContainer,
  Sponsors,
  Photo,
} from './styles';

import Button from '../../components/Button';
import getValidationErrors from '../../utils/getValidationErrors';
import { scrollTo } from '../../components/Menu';
import { useAuth } from '../../hooks/Auth';
import { useToast } from '../../hooks/Toast';
import { useModal } from '../../hooks/Modal';


import Publicas from './modules/publicas';

import { usePages } from '../../hooks/Pages';

import SponsorsList from '../Sponsors';
import Program from '../Program';
import Youtube from '../../components/Youtube';
import Speakers from '../Speakers';
import Input from 'react-select/src/components/Input';
import SignUp from '../SignUp';

interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

const Home: React.FC = () => {
  const {addModal} = useModal();

useEffect(() => {
{/*
  addModal({
    title:'',
    content: <a href="/login"><img  src="/apoio/popup.jpg" style={{width:'100%',maxWidth: '500px'}}/></a>,
    theme: 'whiteModal'
  });

*/}

setTimeout(() => {
  window.location.href= 'https://fronteiras.com';
},3000)

},[])

return (
<Container style={{padding: '0px'}}>
<Capa className='home'>
  <div className='homeContent' >

 
    
   
   <div className='calendarioCapa'>
     <img src="/apoio/calendario.svg"/>
     <h2>EVENTO ENCERRADO</h2>
   </div>

   <strong style={{width: '100%', margin: '0px auto', textAlign:'center', display:'flex', alignItems:'center', justifyContent:'center'}}>Você será redirecionado <FaSpinner className='rotateSpinner' style={{marginLeft:'10px'}} size={15}/></strong>
  </div>
  </Capa></Container>
);

  return (
    <>
    <Container style={{padding: '0px'}}>
      <Capa className='home'>
        <div className='homeContent' >

          <img className='logoCapa computerOnly' src="/assets/logofronteiras.png" />
          <img className='logoCapa mobileOnly' src="/apoio/logocentralizado.png" />
          
          <div className='liner'/>
         <div className='calendarioCapa'>
           <img src="/apoio/calendario.svg"/>
           <h2>20/06 às 20h</h2>
         </div>
         <div className='imageCapa mobileOnly' >
       <img  src="/apoio/palco2-branco.png"/> 

        </div>
         <p className='paragraph'>
         Um espaço para refletir o nosso tempo, antecipando os temas da nova Temporada do Fronteiras do Pensamento. Dois encontros com nomes que investigam, analisam e traduzem as principais transformações da vida humana.
         </p>

         <Link to="/#inscricao" onClick={(e) => scrollTo(e,'inscricao')}><button className='defaultButton buttonMargin '>INSCREVA-SE</button></Link>
        {/* <Link to="/login"> <button className='defaultButton ' >
            ACESSE E ASSISTA AO VIVO
  </button></Link> */}
     
          </div>
     <div className='imageCapa computerOnly' >
       <img  src="/apoio/palco2-branco.png"/> 

        </div>

 
      </Capa>
      </Container>
      <Container style={{background: '#231F20', minHeight: 'auto'}}>
        <div className='pfull'>

        <div>
          <h2 className='bold'>EVENTO ON-LINE E GRATUITO</h2>
          <p>para você assistir de onde quiser</p>

          </div>
          <div>
          <Link to="/#inscricao" onClick={(e) => scrollTo(e,'inscricao')}> <button className='defaultButtonReverse' >
            INSCREVA-SE
          </button></Link>
     
          </div>
          
        </div>

        </Container>

        <Container style={{background: '#FFF', minHeight: 'auto'}}>
<h2 className='bold agendaTitle'>AGENDA</h2>

{/*
<div className='agendaHead'>

    <h2 className='bold dateButton'>18/05 - 20h</h2>

    <h2 className='bold dateTitle'>PALCO 1: TECNOLOGIA E INOVAÇÃO</h2>



    <p className='p2 semibold'>
    As transformações dos próximos 30 anos serão maiores do que as dos últimos 3 mil anos.
    </p>

    <p className='p3'>
    
 
      
      O que podemos esperar do amanhã? Que inovações ainda são possíveis? Como está o ser humano diante da mudança de paradigma digital? São algumas das discussões que estarão no Palco Fronteiras.</p>
      <p className='p1'>MODERADORA: Adriana Couto</p>
</div>

<div className='pensadoresDiv'>
<div className='pensadoresHead'>
    <h2 className='bold'>CONVIDADOS</h2>
    <div/>
  </div>
  <div className='pensadoresBody'>
      <img src="/palestrantes/martha.png"/>
      <div>
        <h2 className='semibold'>Martha Gabriel</h2>
        <p>Doutora em Artes pela ECA/USP e futurista pelo IFTF, é uma das pensadoras digitais mais influentes do Brasil, autora do best-seller <i>Marketing na Era Digital</i>.
</p>
      </div>
  </div>

  <div className='pensadoresBody revert'>
     
      <div>
        <h2 className='semibold'>Ronaldo Lemos</h2>
        <p>Reconhecido internacionalmente por ser o coautor do inovador Marco Civil da Internet e membro do recém-criado Conselho de Supervisão do Facebook.
</p>
      </div>
      <img src="/palestrantes/ronaldolemos.png"/>
  </div>

  <div className='pensadoresBody'>
      <img src="/palestrantes/fernando.png"/>
      <div>
        <h2 className='semibold'>Fernando Schüler</h2>
        <p>Curador do Fronteiras do Pensamento, é historiador, filósofo e cientista político com Ph.D. pela Universidade de Columbia. É colunista do jornal <i>Folha de S. Paulo</i>, da revista <i>Veja</i> e da rede BandNews.</p>
      </div>
  </div>
<div className='dividerLine'/>
</div> */}







<div className='pensadoresDiv'>
<div className='agendaHead'>

   

    <h2 className='bold dateButton'>20/06 - 20h</h2>

    <h2 className='bold dateTitle'>TECNOLOGIA E PSICANÁLISE</h2>



    <p className='p2 semibold'>
    A velocidade da tecnologia é o fato central da vida de hoje.
    </p>

    <p className='p3'>
  
    E, também, uma das maiores causas das angústias pela fragmentação do indivíduo. Como se encontrar, se reconectar com o humano nesse cenário e usar a tecnologia a seu favor? Vamos ao palco com estas e outras reflexões.</p>
    <p className='p1'>MODERADORA: Adriana Couto</p>
</div>

<div className='pensadoresDiv'>
<div className='pensadoresHead'>
    <h2 className='bold'>CONVIDADOS</h2>
    <div/>
  </div>
  <div className='pensadoresBody'>
      <img src="/palestrantes/maria.png"/>
      <div>
        <h2 className='semibold'>Maria Homem</h2>
        <p>Psicanalista clínica, é uma das intelectuais públicas mais lidas e vistas do país, autora de <i>Coisa de menina?</i> e <i>Lupa da alma</i>.</p>
      </div>
  </div>

  <div className='pensadoresBody  revert'>
     
      <div>
        <h2 className='semibold'>Christian Dunker</h2>
        <p>É um dos psicanalistas mais respeitados e conhecidos do país, autor de <i>Estrutura e constituição da clínica psicanalítica</i>.</p>
      </div>
      <img src="/palestrantes/christian.png"/>
  </div>


</div>

</div>

          </Container>

<Container style={{background: '#F3F3F3', minHeight: 'auto'}}>
<Capa style={{alignItems: 'flex-start' , minHeight: 'auto'}}>
<div className='promoTitle'>
  <h2 className='bold' >Palco Fronteiras é o lançamento de uma nova temporada de conferências!</h2>
</div>
<div className='promoContent'>
  <div className='topLiner'/>
  <h2 className='bold'>Prepare-se para o Fronteiras do Pensamento 2022</h2>
  <p>Novos questionamentos, reflexões e provocações. Vamos conhecer o que há de mais atual no pensamento mundial. 
      <br/><br/>
      Não perca a oportunidade de se conectar a esse palco e saber em primeira mão os nomes da Temporada 2022.
      </p>
  <div>
    <div>
      <img src="/apoio/desconto.svg"/>
    </div>
    <p><strong>Descontos exclusivos</strong> para os participantes do Palco Fronteiras. Disponível durante os eventos</p>
  </div>
  <div>
    <div>
      <img src="/apoio/vip.svg"/>
    </div>
    <p><strong>Acesso gratuito</strong> aos encontros do Palco Fronteiras 2022</p>
  </div>

  <Link to="/#inscricao" onClick={(e) => scrollTo(e,'inscricao')}><button className='defaultButtonReverse' style={{marginTop: '20px'}} >INSCREVA-SE</button></Link>
</div>
</Capa>

</Container>

<Container id="inscricao" style={{background: '#FFF', minHeight: 'auto'}}>
<Capa className='noPaddingMobile' style={{alignItems: 'flex-start' , minHeight: 'auto'}}>
<div className='signTitle'>
  <h2 className='bold' >RESERVE SEU LUGAR NO PALCO FRONTEIRAS</h2>
  <div className='dividerLine'/>
  <p>
  
  <strong>VAGAS LIMITADAS.</strong>

  </p>
</div>
<div className='signContent'>
 
 

    <SignUp/>

  
</div>
</Capa>

</Container>
<Container style={{background: '#231F20', minHeight: 'auto', padding: '50px 10px'}}>
        <div className='pfull' style={{justifyContent:'center', flexWrap:'wrap',  }}>

        <div>
          <img style={{width:'180px'}} src="/apoio/logobranca.png"/>
         

          </div>
          <div>
          <p style={{fontSize: '12px'}}>© 2022 FRONTEIRAS DO PENSAMENTO. TODOS OS DIREITOS RESERVADOS</p>
          </div>
          
        </div>

        <a href="https://fronteiras.com/politica-de-privacidade"><p style={{fontSize: '12px', color: '#fff'}}>POLÍTICA DE PRIVACIDADE</p></a>

        </Container>
    
    </>
  );
};
export default Home;
