import React, { useState, createContext, useContext, useCallback } from 'react';
import { Console } from 'console';
import { traductionList } from '../utils/traductions';
import { authTitle } from '../config';

interface LanguageContextData {
  changeLanguage(language): void;
  translate(message): string;
  language: string;
}

const LanguageContext = createContext<LanguageContextData>(
  {} as LanguageContextData,
);

const LanguageProvider: React.FC = ({ children }) => {
  const [language, setLanguage] = useState(() => {
    const selected = localStorage.getItem(`@${authTitle}:language`);

    return selected || 'ptBr';
  });
  const [traductions, setTraductions] = useState(traductionList || []);

  const validLanguages = {
    ptBr: true,
    en: true,
    es: true,
  };

  const translate = (message): string => {
    console.log()
    const responseIndex = traductions.findIndex(
      text => text.ptBr && (text.ptBr)?.toString()?.toLowerCase() === message?.toString()?.toLowerCase()
    );

    if (responseIndex && traductions[responseIndex]) {
      return traductions[responseIndex][language] || message;
    }

    return message;
  };

  const changeLanguage = useCallback(
    nextLanguage => {
      if (validLanguages[nextLanguage] === true) {
        localStorage.setItem(`@${authTitle}:language`, nextLanguage);
        setLanguage(nextLanguage);
      } else {
        localStorage.setItem(`@${authTitle}:language`, 'ptBr');
      }
    },
    [validLanguages],
  );

  return (
    <LanguageContext.Provider value={{ changeLanguage, translate, language }}>
      {children}
    </LanguageContext.Provider>
  );
};

function useLanguage(): LanguageContextData {
  const context = useContext(LanguageContext);

  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }

  return context;
}

export { useLanguage, LanguageProvider };
