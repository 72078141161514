import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import { IconBaseProps } from 'react-icons';

import { useField, FormHandles } from '@unform/core';
import { Container, Field } from './styles';
import {Error } from '../styles';
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from 'react-icons/fa';
import { FiXCircle } from 'react-icons/fi';
interface IListInput {
  label: string;
  name: string;
}

interface IInputProps {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  setCallback?: Function;
  formRef?: React.RefObject<FormHandles>;
  list: Array<IListInput>;
  placeholder: string;
}

interface IVideos {
  type_id : string;
  video_id : string;
  
}

const JsonList: React.FC<IInputProps> = ({
  name,
  icon: Icon,
  formRef,
  setCallback = () => {},
  list,
  ...rest
}): JSX.Element => {
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<Array<IVideos>>(defaultValue || []);

  const addRow = () => {
    const newValue = [...value];

    const newLine = Object.keys(list).reduce((prev, key) => {
      prev[list[key].name] = '';
      return prev;
    }, { type_id: '', video_id: ''} as IVideos);

    newValue.push(newLine);

    setValue([...newValue]);
  };

  const printLine = (name, index, list) => {
    const inputs: Array<JSX.Element> = [];

    Object.keys(list).map(key => {
      inputs.push(
        <Field>
          <span>{list[key].label}</span>

          <input
            placeholder={list[key].label}
            defaultValue={
              value && value[index] && value[index][list[key].name]
                ? value[index][list[key].name]
                : ''
            }
            onChange={e => {
              const newValue = [...value];

              if (!newValue[index]) {
                newValue[index] = {} as IVideos;
              }
              if (!newValue[index]) {
                newValue[index][list[key].name] = '';
              }

              newValue[index][list[key].name] = e.target.value;
              setValue([...newValue]);
            }}
            name={`${name}_${index}`}
          />
        </Field>,
      );
    });

  

    return inputs;
  };

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: undefined,
      getValue: (ref: any) => {
        return JSON.parse(ref.value);
      },
    });
  }, [fieldName, registerField]);

  const removeItem = (removeIndex) => {

    const videos : Array<IVideos> = [];

    value.map( (v,index) => {
      if( index !== removeIndex){
        videos.push(v);
      }
    })

    setValue(videos);

  }

  const goUp = index => {
    const indexup = index - 1;

    console.log(value,indexup);

    if (value[indexup]) {
      const newCurrent = [...value];

      newCurrent[index] = value[indexup];
      newCurrent[indexup] = value[index];

      setValue([...newCurrent]);
   
    }
  };

  const goDown = index => {
    const indexup = index + 1;

    if (value[indexup]) {
      const newCurrent = [...value];

      newCurrent[index] = value[indexup];
      newCurrent[indexup] = value[index];

      setValue([...newCurrent]);

    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <input
        ref={inputRef}
        style={{ display: 'none' }}
        name={name}
        value={JSON.stringify(value)}
      />
      <button type="button" onClick={() => addRow()}>
        Adicionar
      </button>
      {value.map((line, index) => (
        <Container>{printLine(name, index, list)}
     
        <button style={{background:'none', border:'0px'}} type="button" onClick={() => removeItem(index)}><FiXCircle size={30}/></button>
        
        </Container>
      ))}
    </div>
  );
};

export default JsonList;
