import styled, { keyframes } from 'styled-components';

interface IBackground {
  background?: string;
}

export const Fixed = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100000;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
`;

export const Container = styled.div<IBackground>`
  min-height: auto;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  background: none;
  color: #fff;
  padding: 0px;
  width: 100%;

  margin: auto;

  .borderBlue {
    border-bottom: 5px solid rgb(0, 100, 150);
  }

  .borderPurple {
    border-bottom: 5px solid rgb(100, 0, 150);
  }

  .borderGreen {
    border-bottom: 5px solid rgb(0, 150, 50);
  }

  .posterSelector {
    display: flex;
    align-items: stretch;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    
    > form {
      > input {
        padding-left: 10px;
      }

      margin: 0 5px;
      > button {
        margin: 0;
        font-size: 12px;
      }
    }

    > button.active {
      background: #fff !important;
      color: #026271 !important;
    }

    > button {
      margin: 0;
      font-size: 12px;

      @media (max-width: 750px) {
        min-width: auto;
        margin-bottom: 10px;
      }
    }
  }

  .mainPoster {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    cursor: pointer;
  }

  .mainFooter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  .posterDisplay {
    max-width: 300px;
    min-width: 300px;
    margin: 10px;
    background: #fff;

    color: #333;
    padding: 10px;
  }

  .posterTitle {
    font-size: 8px;
    width: 100%;
    height: 40px;
    overflow: hidden;
    font-weight: bold;
  }

  .posterAuthor,
  .posterCoAuthor {
    font-size: 8px;
    width: 100%;
    height: 50px;
    overflow: hidden;
    margin-top: 10px;
  }

  .posterImage {
    width: 100%;
  }

  > h2 {
    display: flex;
    justify-content: center;
  }

  .programDayHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 15px;
    align-items: center;

    > span {
      min-width: 36px;
    }

    > h2 {
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 750px) {
    padding: 0px;
  }

  > table {
    width: 100%;
    border-collapse: collapse;
    > tbody {
      > tr {
        border-top: 1px solid #555;

        > td {
          width: 100%;
          display: flex;
          flex-direction: column;
          padding: 5px 0;
          > div {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 12px;

            > a > {
              button {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
`;
