import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

import { background, defaultTheme } from '../../config';
import theme from '../../styles/ThemeComponents';

const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

const rotate = keyframes`
from {

  transform:rotate(0deg);
}
to{

  transform:rotate(360deg);
}
`;

const fly = keyframes`
from {

  transform:translateX(0%) translateY(0%);
}
to{

  transform:translateX(0%) translateY(-5%);
}
`;

const round = keyframes`
from {

  transform:rotate(0deg);
  width: 95%;
  height: 95%;
}
to{

  transform:rotate(360deg);
  width: 105%;
  height: 105%;
}
`;

const round2 = keyframes`
from {

  transform:rotate(0deg);
  width: 100%;
  height:100%;
}
to{

  transform:rotate(360deg);
  width: 85%;
  height: 85%;
}
`;

const rotateSmoth = keyframes`
from {

  transform: rotateX(0deg) ;
}
to{
  transform: rotateX(20deg) ;
}
`;

interface IBackground {
  background?: string;
}

export const Container = styled.div<IBackground>`
  min-height: 90vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: none;
  color: #555;
  padding: 50px 10px;
  width: 100%;

  margin: auto;

  >div{
    max-width: 1400px;
    width: 100%;
  }

  @media (max-width: 750px) {

    padding: 50px 20px;
}

  .home{

    max-width: 100% !important;
    @media (max-width: 1200px) {
  
  flex-direction: column-reverse;
  padding: 0px !important;
}
    @media (max-width: 750px) {
  
    flex-direction: column-reverse;
    padding: 0px !important;
  }

  .rotateSpinner{
    animation: ${rotate} 2s infinite ;
  }

  .imageCapa{

    display: flex ;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 893px;
    >img{
    width: 100%;
    max-width: 893px;

    @media (max-width: 750px) {
    width: 100% ;
    margin-bottom: 20px;

    }

    }

  }


.calendarioCapa{

display: flex ;
align-items: center;
justify-content: flex-start;
width: 100%;
margin: 40px 0px;

@media (max-width: 1200px) {
  justify-content: center;
  margin: 10px 0px;
  align-items: center;
    }

img{
  width: 39px;
height: 45px;
margin-right: 20px;

@media (max-width: 750px) {
  width: 25px;
height: 25px;
    }

}

h2{
  text-align: left;
  font-size: 25px;
  line-height: 38px;

  @media (max-width: 750px) {
    font-size: 16px;
    }
}

}



.liner {
 
width: 122px;
height: 3px;
background: #fff ;
opacity: 1;

@media (max-width: 750px) {
 margin-top: 0px;
    }
}

p.paragraph {
    max-width: 530px !important;
    width: 100%;

    font-size: 16px;

    @media (max-width: 750px) {
      font-size: 16px !important;
      text-align: center;
    }

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }
  }

  .homeContent{
    margin: 0px 40px;
    margin-top: 30px; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-start;
  

    @media (max-width: 1200px) {
      align-items: center;
      margin: 0px;
     
    }
  }

  .logoCapa {

width: 473px;
max-width: 100%;
margin-bottom: 20px;

@media (max-width: 750px) {
    width: 233px ;

    }
}
 
  }

  .noPaddingMobile{
    @media (max-width: 750px) {
    padding: 0px !important;

    }
  }

  .pfull{
    width: 100% ;
    display: flex ;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;

    @media (max-width: 750px) {
    padding: 0px;
    flex-direction: column;
  }

    >div{
      padding: 0px 40px;

      h2{
        font-size: 40px;
        color: #fff;
        text-align: center;
        @media (max-width: 750px) {
          font-size: 20px;
        }

        
      }

      p{
        font-size: 30px;
        color: #fff;

        @media (max-width: 750px) {
          font-size: 16px;
          text-align: center;
        }
      }

      >button{
         
        @media (max-width: 750px) {
          margin: 25px auto;
          margin-bottom: 0px;
        }
      }
    }
  }

  .agendaTitle{
    text-align: center;
font-size: 40px !important;
line-height: 51px;
letter-spacing: 0px;
color: #231F20;
opacity: 1;

@media (max-width: 750px) {
  font-size: 30px !important;
line-height: 38px;
        }
  }

.agendaHead{
  width: 100% ;
  max-width: 1000px;
  display: flex ;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .dateButton{
      font-size: 25px;
    
      width: 230px ;
      height: 76px ;
      border: 0px solid #FFFFFF;
      border-radius: 5px;
      padding: 14px 38px ;
      text-align: center;
      background: #4278BC;
      border: 0px solid #FFFFFF;
      border-radius: 5px;
      color: #fff  !important;
      display: flex;
      justify-content: center;
      text-align: center;
      transition:  0.5s all;

      display: flex ;
      align-items: center;
      justify-content: center;

      margin: 40px 0px;

      @media (max-width: 750px) {
        width: 113px;
        height: 45px;
        padding: 10px;
        font-size: 16px;
        margin: 25px 0px;
        }
  }

  .dateTitle{
    text-align: center;
    font-size: 30px !important;
line-height: 38px;
letter-spacing: 0px;
color: #4278BC;
opacity: 1;

margin: 10px 0px;

@media (max-width: 750px) {
  font-size: 20px !important;
line-height: 26px;
}
  }

  .p1{
    text-align: center;
letter-spacing: 0px;
color: #231F20;
font-size: 20px;

margin: 10px 0px;

@media (max-width: 750px) {
  font-size: 18px;

}
  }

  .p2{
    text-align: center;
letter-spacing: 0px;
color: #231F20;
font-size: 18px;

margin: 10px 0px;

@media (max-width: 750px) {
  font-size: 16px;

}
  }

  .p3{
    text-align: center;
letter-spacing: 0px;
font-size: 18px;
letter-spacing: 0px;
color: #6D6E71;
margin: 10px 0px;

@media (max-width: 750px) {
  font-size: 16px;

}
  }
}

.pensadoresDiv {
  width: 100%;
  max-width: 1088px;
  display: flex ;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;

  .dividerLine{
    height: 2px;
      width: 100%;
      background: #231F20;
      margin-bottom: 8px;
      margin: 25px 0px;
      border-radius: 10px;
  }

  .pensadoresHead{
    display: flex ;
    align-items: center;
    justify-content: space-around;
    width: 100% ;
    margin: 25px;

    
    >h2{
      font-size: 30px;
      letter-spacing: 0px;
      color: #4278BC;
      opacity: 1;
      margin-right: 25px;

      @media (max-width: 750px) {
  font-size: 20px;
      }
    }
    >div{
      height: 2px;
      width: 100%;
      background: #231F20;
      margin-bottom: 8px;
      border-radius: 10px;
    }

  }
  .pensadoresBody{

    display: flex ;
    align-items: center;
    justify-content: space-around;
    max-width: 970px;
    margin: 25px 0px;

    @media (max-width: 1200px) {
      margin: 0px 25px;
    flex-direction: column;
    margin-bottom: 50px !important;
  }
    >div{
      padding: 27px;
      @media (max-width: 750px) {
        padding: 15px;
        }

      >h2{
        font-size: 25px;
        letter-spacing: 0px;
        color: #231F20;
        opacity: 1;

        @media (max-width: 750px) {
          font-size: 16px;
          text-align: center;
        }

      }
      >p{
        font-size: 18px;
        text-align: left;
letter-spacing: 0px;
color: #6D6E71;
margin-top: 10px;

        @media (max-width: 750px) {
          font-size: 12px;
          line-height: 27px;
          text-align: center;
        }
      }
    }
    >img{
      margin: 0px 20px;
      width: 215px;
      height: 214px;

    }

  }
}

.revert{
  @media (max-width: 750px) {
         flex-direction: column-reverse !important;
        
        }
}

.promoTitle{
  width: 100%;
  max-width: 345px;
  margin: 0px 25px;

  @media (max-width: 750px) {
    max-width: 100%;
    margin: 0px;
  }

  >h2{
    width: 100% ;
    font-size: 45px;
    text-align: right;
    letter-spacing: 0px;
    color: #4278BC;
    line-height: 58px;

    @media (max-width: 750px) {
      font-size: 20px;
      line-height: 26px;
      text-align: center;
      width: 100%;
      max-width: 100%;
        }
  }
}

.promoContent{
  width: 100%;
  max-width: 700px;
  margin: 25px;
  margin-top: 0px;

  display: flex ;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 750px) {
    margin: 0px;
    margin-top: 10px;
    align-items: center;
    
  }

  .topLiner{
    width: 124px;
    height: 2px;
    background: #4278BC ;
    border-radius: 5px;
    margin: 10px;

    @media (max-width: 750px) {
      margin : 10px auto;
    }

  }

  >h2{
    text-align: left;
    letter-spacing: 0px;
    color: #010101;
    opacity: 1;
    padding: 10px;
    font-size: 25px;

    @media (max-width: 750px) {
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      width: 100%;
      max-width: 100%;
        }
  }

  >p{
    font-size: 18px;
    text-align: left;
    letter-spacing: 0px;
    color: #010101;
    opacity: 1;
    padding: 10px;

    @media (max-width: 750px) {
      font-size: 14px;
      text-align: center;
      width: 100%;
      max-width: 100%;
      color: #6D6E71;
        }
  }

  >div{
    display: flex ;
    align-items: center;
    justify-content: flex-start;
    >div{
      width: 50px;
      min-width: 50px;
      display: flex ;
    align-items: center;
    justify-content: center;
      >img{
        height: 40px ;
      }
  }
    >p{
      text-align: left;
      font-size: 20px;
      line-height: 30px;
      letter-spacing: 0px;
      color: #231F20;
      padding: 10px;

      @media (max-width: 750px) {
      font-size: 14px;
      line-height: 21px;
      text-align: left;
      width: 100%;
      max-width: 100%;
      
        }

    }
  }

  >button{
    margin: 40px;
    margin-bottom: 0px;

    @media (max-width: 750px) {
      margin: 10px auto;
 
    }
  }
}

.signTitle{
  width: 100%;
    max-width: 666px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    margin: 25px 50px;
    padding-top: 220px;

    @media (max-width: 750px) {
      margin: 0px;
    padding-top: 0px;
    }

    >div{
      width: 100% ;
      height: 2px ;
      background:#231F20 ;
      margin: 25px 0px;

      
    }

  >h2{
    max-width: 398px;
    font-size: 40px;
    text-align: left;
    letter-spacing: 0px;
    color: #4278BC;
    text-transform: uppercase;
    opacity: 1;

    @media (max-width: 750px) {
      font-size: 20px;
      line-height: 26px;
      max-width: 100%;
      text-align: center;
    }
  }

  >p{
    max-width: 398px;
    font-size: 20px;
    text-align: left;
    letter-spacing: 0px;
    color: #231F20;
    opacity: 1;

    @media (max-width: 750px) {
      font-size: 16px;
      line-height: 21px;
      max-width: 100%;
      text-align:center;
      margin-bottom: 20px;
    }

    


  }
}


`;

export const ContainerWhite = styled(Container)`
  min-height: 90vh;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: rgb(56,56,56);
  padding: 100px 0;
  width: 100%;
  max-width: 100%;
  margin: auto;

  >h2{

  font-size: 18px;
  font-weight: bolder;
  line-height: 18px;
  margin: 0 50px;

  @media (max-width: 1200px) {
    font-size: 18px;
    line-height: 18px;
    text-align: center;
  }

}

  @media (max-width: 1200px) {
    padding: 0px 20px;
    flex-direction: column;
  }

  > .p50 {
    z-index: 1;
    width: 50%;
    min-width: 50%;
    margin: 30px 0;

    @media (max-width: 1200px) {
      width: 100%;
      min-width: 100%;
    }

    > h2 {
      font-size: 1.5em;
    }

    > p {
      text-align: justify;
    }
  }

  .p600 {
    max-width: 600px;
    min-width: auto;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }

    > a {
      z-index: 2;
       }; button {
         margin-top: 10px;
      width: 200px;
      padding: 20px;
      text-align: center;
      background: rgb(0, 102, 178);
      color: #fff;
      transition: background 0.5s;
      border: 0;
      border-radius: 8px;

      &:hover {
        background: rgb(0, 102, 178);
      }
    }
  }
  }
`;

export const GreyContainer = styled.div`
  background: rgb(235, 235, 235);
  min-height: auto;
  width: 100%;
  padding: 100px 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  > table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;

    > thead {
      background: ${defaultTheme.menu.backgroundSticky};

      > tr {
        > th {
          padding: 20px;
          border: 0;
          text-align: left;
        }
      }
    }

    > tbody {
      > tr {
        border-bottom: 1px solid rgb(185, 185, 185);
        > td {
          padding: 20px 20px;

          text-align: left;
          color: rgb(56, 56, 56);
        }
      }
    }
  }

  > div.paragraph {
    max-width: 1100px;
    margin: auto;
    text-align: center;
    > h2,
    > p {
      color: rgb(56, 56, 56);

      @media (max-width: 1200px) {
        text-align: justify;
      }
    }
    > p {
      max-width: 750px;
      margin: auto;
    }

    > h2 {
      margin-bottom: 50px;
      font-size: 32px;

      @media (max-width: 1200px) {
        font-size: 16px;
      }
    }
  }
  > h2,
  > p {
    color: #fff;

    @media (max-width: 1200px) {
      text-align: justify;
    }
  }

  > h2 {
    font-size: 18px;
    font-weight: bolder;
    line-height: 18px;

    @media (max-width: 1200px) {
      font-size: 16px;
    }
  }
`;

export const SecondaryContainer = styled.div`
  background: rgb(88, 46, 145);
  min-height: auto;
  width: 100%;
  padding: 100px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 1200px) {
    padding: 100px 20px;
  }

  > div.paragraph {
    max-width: 1100px;
    margin: auto;
    text-align: center;
    > h2,
    > p {
      color: #fff;

      @media (max-width: 1200px) {
        text-align: justify;
      }
    }
    > p {
      max-width: 750px;
      margin: auto;
    }

    > h2 {
      margin-bottom: 50px;
      font-size: 32px;

      @media (max-width: 1200px) {
        font-size: 16px;
      }
    }
  }
  > h2,
  > p {
    color: #fff;

    @media (max-width: 1200px) {
      text-align: justify;
    }
  }
`;

export const Capa = styled.div<IBackground>`
  min-height: 70vh;


  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  background: none ;
  background-size: cover;
  color: #fff;
  padding: 0px 20px;
  width: 100%;
  max-width: 100%;
  margin: auto;
  position: relative;
  

  @media (max-width: 1200px) {
    min-height: 50vh;
    flex-direction: column;
  }



.paragraph{
  font-size: 20px !important;
  width: 100%;
  max-width: 482px !important;
  line-height: 30px;
}

.buttonMargin{
  margin-top: 40px;
}


  > div {
    margin: 0 10px;
    @media (max-width: 1200px) {
        width: 100%;
        margin: 0px;
      }
  }

  .titleCapa {
    max-width: 600px;

    > h2 {
      font-size: 32px;
      margin: 10px 0;
      text-align: center;
    }

    >p{
      font-size: 18px;
      text-align: center;
    }
  }

  > .p50 {
      z-index: 1;
      width: 50%;
      min-width: 50%;
      margin: 10px 0;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      @media (max-width: 1200px) {
        width: 100%;
      }

      > h2 {
        font-size: 18px;

        @media (max-width: 1200px) {
          text-align: center;
        }
      }

      > p {
        text-align: justify;
      }
    }

.imgCapa {
  margin-top: 80px;
  max-width: 75%;
  width: 100%;
  margin-left: -4.5%;
  z-index: 1;

  @media (max-width: 1200px) {
    max-width: 100%;
    margin-left: -10%;
  }
}

.sideColor{
  position: absolute;
  width: 20%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: rgb(185,185,185);
  z-index: 0;

  @media (max-width: 1200px) {
    display: none;
  }
}

  .fundoFlutuante {
    position: absolute;
    top: 0;
    right: 0px;
    width: 50vh;
    height: 50vh;

    border-radius: 50% 50% 0 50%;


>div.imagemFlutuante {
  width: 100%;
  height: 100%;
  border-radius: 0 50%;
  overflow: hidden;
    >div{
    overflow: hidden;

    background: url('/assets/evento/fundo.jpeg') no-repeat center;
    background-size: cover;
    width: 90%;
    height: 100vh;
    transform: translateX(0%) ;
    animation : ${fly} 15s infinite alternate-reverse;

    }

  }


    >div.arcoFlutuante1{

border: 2px solid rgb(251,178,22);
border-radius: 0 50%;
width: 100%;
height: 100%;
position: absolute;
top: 0px;
left: 0px;

    animation : ${fly} infinite alternate-reverse 10s;
    }

    >div.arcoFlutuante2{

border: 2px solid rgb(251,198,22);
border-radius: 0 50%;
width: 100%;
height: 100%;
position: absolute;
top: -20px;
left: 0px;

    animation : ${fly} infinite alternate-reverse 7s;
    }

  }

  .noMargin {
    margin: 0px !important;
  }

  .logoText {

    font-size: 50px;
    color: ${defaultTheme.menu.logoColor};

    @media (max-width: 750px) {
      font-size: 40px;
  }

  }

  .justifyStart {
    justify-content: flex-start !important;

    @media (max-width: 750px) {
      justify-content: center !important;
  }
  }

  @media (max-width: 750px) {
    padding: 10px 20px;
  }

  .separate {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
  }

  .titleSkewRight{
    display: flex;
    border-radius: 10px;
    background: rgb(0, 102, 178);
    transition: 0.5s all;
    padding: 10px 30px;
    justify-content: center;
    > h2, > button{
      transform: skewX(10deg);
      color: #fff;
      font-size: 18px;
    }

    &:hover {
        background: rgb(0, 102, 178);
        > h2, > button{
          background: rgb(0, 102, 178);
        }
      }

  }

  .sponsors {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    flex-wrap: wrap;
    color: rgb(0, 102, 178);
    position: relative;
    z-index:200;
    background: #fff;

    @media (max-width: 750px) {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
  }

    > div {
      width: auto;
      max-width: 400px;
      margin: 5px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @media (max-width: 750px) {
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
  }
      > h2 {
        font-size: 16px;

        color: rgb(50, 50, 50);
      }
      > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        min-height: 100px;

        @media (max-width: 750px) {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

        > img {
          width: 150px;
          min-width: 150px;
          margin: 10px;
        }

        > img.organizacao {
          width: 100px;
        }

        >a{
          > img {
          width: 150px;
          min-width: 150px;
          margin: 10px;
        }

        > img.organizacao {
          width: 100px;
        }
        }


      }
    }

    @media (max-width: 750px) {
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }

    .embrapa { width: 100px; >img {max-width: 100%;}}
        .unipampa {width: 100px;  >img {max-width: 100%;}}
        .gov{ width: 200px;  >img {max-width: 100%;}

        }
        .juntos { width: 500px;  >img {max-width: 100%;}}
  }

  > .blocoLogo {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    z-index: 2;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    > .p50 {
      z-index: 1;
      width: 50%;
      min-width: 50%;
      margin: 10px 0;
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;

      @media (max-width: 1200px) {
        width: 100%;
      }

      > h2 {
        font-size: 1.5em;
      }

      > p {
        text-align: justify;
      }
    }

    .p600 {
    max-width: 600px;
    min-width: auto;
    width: 100%;

    font-size: 16px;

    > strong {
      font-weight: bold;
      color: rgb(0, 102, 178);
    }

    > a {
      z-index: 2;
       >} button {
         margin-top: 10px;
      width: 200px;
      padding: 20px;
      text-align: center;
      background: rgb(0, 102, 178);
      color: #fff;
      transition: background 0.5s;
      border: 0;
      border-radius: 8px;

      &:hover {
        background: rgb(0, 102, 178);
      }
    }
  }

  .titleSkewRight, .titleSkewRightDark{
    display: flex;border-radius: 10px,

    background: rgb(0, 102, 178);;
    padding: 10px 30px;
    justify-content: center;
    text-decoration: none;
    transition: 0.5s all;
    > h2, > button{
      transform: skewX(10deg);
      color: #fff;
    }

    &:hover {
        background: rgb(0, 102, 178);
        > h2, > button{
          background: rgb(0, 102, 178);
        }
      }

  }



  .titleSkewRightDark{
border-radius: 10px;
    background: rgb(0,148,217);
    align-items: flex-start;
    > h2, > button{
          background: rgb(0,148,217);
        }



  }

    .logoEvento {
      width: 100%;
      max-width: 500px;
      height: auto;
      transform: translateX(-5%);
    }

    .logoEventoMini {
      width: 100px;
      margin-top: 50px;
    }
  }

  .bloco {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;

    > a {
      margin: 10px;
    }

    @media (max-width: 750px) {
      flex-direction: column;
    }

    > a {
      > button {
        width: 200px;
        padding: 20px;
        text-align: center;
        background: rgb(0, 102, 178);
        color: #fff;
        transition: background 0.5s;
        border: 0;
        border-radius: 8px;

        &:hover {
          background: rgb(0, 102, 178);
        }
      }
    }
  }

  .separator {
    width: 100%;
    max-width: 200px;
    height: 2px;
    background: rgb(0, 102, 178);
    margin: 20px 0;
  }

  .borderedBottom {
    border: 2px solid #fff;
    padding: 10px;
    color: #fff;
    background: none;
    opacity: 0.8;
    transition: opacity 0.5s;

    &:hover {
      opacity: 1;
    }
  }

  .logoEvento {
    width: 400px;
    max-width: 80%;
    z-index: 200;
  }

  .palestrantes {
    max-width: 200px;
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 750px) {
      margin: 20px 0;
    }

    > .palestranteDiv {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      > .palestrante {
        width: 100px;
        max-width: 100px;
        min-width: 100px;
        height: 100px;
        margin: 10px;
        border: 2px solid rgb(0, 102, 178);
        border-radius: 50%;
        overflow: hidden;
        > img {
          width: 100%;
        }
      }

      > h2 {
        font-size: 16px;
        font-weight: bold;
        color: rgb(0, 102, 178);
      }

      > p {
        font-size: 14px;

        color: rgb(0, 102, 178);
      }
    }
  }


`;

export const Sponsors = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  flex-wrap: wrap;
  color: rgb(0, 102, 178);
  position: relative;
  z-index: 200;
  background: #fff;

  @media (max-width: 750px) {
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
  }

  > div {
    width: auto;
    max-width: 400px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: 750px) {
      justify-content: center;
      flex-direction: row;
      flex-wrap: wrap;
    }
    > h2 {
      font-size: 16px;

      color: rgb(50, 50, 50);
    }
    > div {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      min-height: 100px;

      @media (max-width: 750px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
      }

      > img {
        width: 150px;
        min-width: 150px;
        margin: 10px;
      }

      > img.organizacao {
        width: 100px;
      }

      > a {
        > img {
          width: 150px;
          min-width: 150px;
          margin: 10px;
        }

        > img.organizacao {
          width: 100px;
        }
      }
    }
  }

  @media (max-width: 750px) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
  }

  .embrapa {
    width: 100px;
    > img {
      max-width: 100%;
    }
  }
  .unipampa {
    width: 100px;
    > img {
      max-width: 100%;
    }
  }
  .gov {
    width: 200px;
    > img {
      max-width: 100%;
    }
  }
  .juntos {
    width: 500px;
    max-width: 100%;
    > img {
      max-width: 100%;
    }
  }
`;

export const Photo = styled.div<IBackground>`
  width: 200px;
  margin: 10px 40px;
  border-radius: 50%;
  height: 200px;
  background: url(${props => props.background}) no-repeat;
  background-size: cover;
`;

export const ProgramContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: auto;
  align-items: center;
  justify-content: center;
  z-index: 2000;
  transition: 0.5s all;
  width: 100%;

  > div {
    overflow-y: auto;
    padding: 40px;
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    color: #333;
    background: #fff;
    margin: 0;

    @media (max-width: 750px) {
      max-width: 100%;
      width: 100%;
    }

    > h2 {
      color: ${defaultTheme.defaultBackground};
      margin-bottom: 10px;
      text-align: center;
      justify-content: center;
      display: flex;
    }

    > p {
      color: #333;
      font-size: 12px;
    }

    > button {
      padding: 10px;
      margin: 5px auto;
      width: 200px;
      border-radius: 8px;
      background: ${defaultTheme.defaultBackground};
      color: #fff;
      border: 0px;
    }

    > div {
      color: #333;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 10px;

      > h2 {
        color: ${defaultTheme.defaultBackground};
        margin-bottom: 10px;
        text-align: left;
        width: 100%;
      }

      > p {
        color: #333;
        font-size: 12px;
      }

      > button {
        padding: 10px;
        margin: 5px auto;
        width: 200px;
        border-radius: 8px;
        background: ${defaultTheme.defaultBackground};
        color: #fff;
        border: 0px;
      }
    }
  }
`;
