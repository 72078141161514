import React, { useCallback, useRef, useEffect, useState } from 'react';

import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';


import { logo, eventName, urlWebsite } from '../../../../../config';
import { Container, Content, Background, AnimatedDiv2,Photo } from './styles';
import Input from '../Forms/Input';


import { useAuth } from '../../../../../hooks/Auth';
import { useToast } from '../../../../../hooks/Toast';


import { useLanguage } from '../../../../../hooks/Language';
import api from '../../../../../services/api';

import UploadImage from '../Forms/UploadImage';

import Loader from '../../../../../components/Loader';
import SelectSimple from '../Forms/SelectSimple';
interface SignInData {
  email: string;
  password: string;
  name: string;
}

interface IProps {
  key ?: string;
}

const Profile: React.FC<IProps> = ({key = 'profile'}) => {

  const { signIn, updateImage } = useAuth();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
const [loading,setLoading] = useState(false);
  const {translate} = useLanguage();

  const [student,setStudent] = useState({
    image : '/apoio/user.jpg',
    name: '',
    apelido : '',
    email : '',
    phone : '',
    extra_1 : 1,
    company: '',
    position: '',
    find_me : 1
  });

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {

    const load = async () => {
     const response = await api.get('/profile');
     if(response){
       setStudent(response.data);
     }
    }
    load();

  },[])

  const handleSubmit = useCallback(
    async (data: SignInData) => {
      setLoading(true);

      try{
      const key = Object.keys(data)[0];
      if(key){ 

        if(key === 'image'){

          if(data?.[key]?.size && data?.[key]?.size >= 1000000){
             addToast({title: 'O tamanho máximo permitido é 1MB', type:'info'});
             throw new Error("Tamanho superior ao a 1MB");
             return;
          }

          const formData = new FormData();
          formData.append('file', data[key]);
          const response = await api.put(`/profile/${key}`,formData);

          if(response){

            const check = setTimeout( async () => {

          
              updateImage(`${response.data.value}`);
           
                
                setStudent(state => ({ ...state, [key] : response.data.value}));
                addToast({title: 'Atualizado com sucesso!', type:"success"});
           
                setLoading(false);
                return;
              

            }, 1000);


          
          }
        }
        else{
          const response = await api.put(`/profile/${key}`,data);

          if(response){
            setStudent(state => ({ ...state, [key] : response.data.value}));
            addToast({title: 'Atualizado com sucesso!', type:"success"});
            setLoading(false);
            return;
          }
        }

  

      }
    } catch (err) {
    
      setLoading(false);
      return;
    }

    },
    []
  );

  return (
<Container>
          {
            loading && <Loader position="absolute" message="Atualizando"/>
          }


        <Form ref={formRef} onSubmit={handleSubmit}>
        <p style={{color: '#fff', textAlign:'center'}}>Foto</p>
        
        <Photo style={{border:'2px solid #fff'}} background={student.image ? `${urlWebsite}/${student.image}` : '/apoio/user.jpg'}>
          <UploadImage placeholder="Foto" name="image"/>
          </Photo>
          <p style={{color: '#fff', textAlign:'center'}}>Tamanho máximo : 1Mb</p>
          <div className="checkDiv">
        <button className="checkButton" type="submit">Salvar</button></div>
        </Form>

        <Form  ref={formRef} onSubmit={handleSubmit} initialData={{apelido : student.apelido || student.name}}>
        <p style={{fontSize: '12px', textAlign: 'left', padding: '0px 10px', color: '#fff'}}>Nome</p>
        <div className="checkDiv">
        <Input placeholder="" type="text" name="apelido" />
        <button className="checkButton" type="submit">Salvar</button>
        </div>
        </Form>

        <Form  ref={formRef} onSubmit={handleSubmit} initialData={{company : student.company || ''}}>
        <p style={{fontSize: '12px', textAlign: 'left', padding: '0px 10px', color: '#fff'}}>Empresa</p>
        <div className="checkDiv">
        <Input placeholder="" type="text" name="company" />
        <button className="checkButton" type="submit">Salvar</button>
        </div>
        </Form>

        <Form  ref={formRef} onSubmit={handleSubmit} initialData={{find_me : student.find_me || 1}}>
        <p style={{fontSize: '12px', textAlign: 'left', padding: '0px 10px', color: '#fff'}}>Autorizo que meus dados sejam pesquisados por outros participantes para contato.</p>
        <div className="checkDiv">
        <SelectSimple label="" options={[{ value: 2, 'text':'Sim', label:'Sim'},{ value: 1, 'text':'Não', label:'Não'}]} name="find_me" />
        <button className="checkButton" type="submit">Salvar</button>
        </div>
        </Form>

        <Form  ref={formRef} onSubmit={handleSubmit} initialData={{position : student.position || ''}}>
        <p style={{fontSize: '12px', textAlign: 'left', padding: '0px 10px', color: '#fff'}}>Cargo</p>
        <div className="checkDiv">
        <Input placeholder="" type="text" name="position" />
        <button className="checkButton" type="submit">Salvar</button>
        </div>
        </Form>

        <Form ref={formRef} onSubmit={handleSubmit} initialData={{phone : student.phone}}>
        <p style={{fontSize: '12px', textAlign: 'left', padding: '0px 10px', color: '#fff'}}>Telefone (WhatsApp)</p>
        <div className="checkDiv">
        <Input placeholder="" type="text" name="phone" />
        <button className="checkButton" type="submit">Salvar</button>
        </div>
        </Form>

</Container>
  );
};
export default Profile;
