import React, { useEffect, useState } from 'react';
import { ZoomMtg } from "@zoomus/websdk";
import testTool from './tools';
import {Container} from './styles';
import { useAuth } from '../../hooks/Auth';
import api from '../../services/api';
import { useToast } from '../../hooks/Toast';
import { Button } from '../Button/styles';

interface IProps {
  room_id ?: number;
}
      
const Zoom: React.FC<IProps> = ({room_id}) => {

  const [url,setUrl] = useState('');
  const {user} = useAuth();
  const {addToast} = useToast();


/*
 meetingNumber: meetConfig.meetingNumber,
                userName: meetConfig.userName,
                signature: res.result,
                apiKey: meetConfig.apiKey,
                userEmail: 'email@gmail.com',
                passWord: meetConfig.passWord,
*/

useEffect( () => {

  const load = async () => {
    const response = await api.get(`/checkzoom/${room_id}`);

    if(!response){
      return addToast({ title:"Não foi possível acessar sala", type:"info" });
    }

    if(response.data.message ){
      return addToast({ title:response.data.message, type:"error" });
    }

    if(response.data.signature ){
      launchMeetings(response.data);
      return addToast({ title:'Acessando sala de Zoom', type:"success" });
    }
  }

  load();

},[]);


const launchMeetings = (config) => {
   const url = `https://zoomtest.encontrodigital.com.br:/meeting.html?name=${encodeURI(config.name)}&mn=${config.meeting}&email=${encodeURI(config.email)}&pwd=&role=0&lang=en-US&signature=${config.signature}&china=0&apiKey=${config.apiKey}`;
  setUrl(url);
}

const [fullScreen,setFullScreen] = useState(false);


return <Container full={fullScreen}><div className="zoomer">{ url && <iframe src={url} allow="camera;microphone" />}</div>
<Button className="computerOnly" style={{justifyContent: 'center', alignItems: 'center'}} onClick={() => {setFullScreen(!fullScreen)}}>{ fullScreen ? 'Fechar' : 'Full Screen'}</Button></Container>
}

export default  Zoom;