/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect, Dispatch, useState, useCallback } from 'react';
import { FiChevronRight } from 'react-icons/fi';
import { useToast } from '../../../../hooks/Toast';
import { AnimatedDiv } from '../../styles';
import { ProductsContent } from './styles';
import { BuyContext } from '../../index';
import api from '../../../../services/api';
import Loader from '../../../../components/Loader';
import format from '../../../../utils/format';

interface ProductProps {
  id: string;
  title: string;
  price: number;
  formattedPrice?: string;
  max_selectable_courses: number;
  type: 'free' | 'paid';
  benefits: string[];
  quantity: number;
}

interface CoursesProps {
  id: string;
  title: string;
}

interface ComponentProps {
  step: number[];
  setStep: Dispatch<React.SetStateAction<number[]>>;
  buyContext: BuyContext;
  setBuyContext: Dispatch<React.SetStateAction<BuyContext>>;
  productId?: number;
}

const ProductsContainer: React.FC<ComponentProps> = ({
  step,
  setStep,
  buyContext,
  setBuyContext,
  productId = 0,
}) => {
  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(true);

  const [productList, setProductList] = useState<ProductProps[]>([]);

  useEffect(() => {
    const loadInfo = async (): Promise<void> => {
      const responseProducts =
        productId > 0
          ? await api.get(`/products?id=${productId}`)
          : await api.get('/products');

      if (!responseProducts) {
        addToast({
          title: 'Ops, Falha de conexão.',
          description: `Por favor, recarregue a tela e tente novamente`,
          type: 'error',
        });
      }

      const formatProducts = responseProducts.data.map(
        (productValue: ProductProps) => ({
          ...productValue,
          formattedPrice: format(productValue.price),
        }),
      );

      setProductList(formatProducts);
      setLoading(false);
    };

    loadInfo();
  }, [addToast]);

  const handleProduct = useCallback(
    productInfo => {
      productInfo.quantity = 1;

      const changeBuyContext: BuyContext = {
        ...buyContext,
        products: [productInfo],
      };

      const maxCourses = productInfo.max_selectable_courses;
      const amount = changeBuyContext.products
        ? changeBuyContext.products.reduce(
            (prev, productMap) => prev + productMap.price * productMap.quantity,
            0,
          )
        : 0;

      setBuyContext({
        ...changeBuyContext,
        amount,
        maxCourses,
        productType: productInfo.type,
      });
      setStep([1, maxCourses > 0 ? 2 : 3]);
    },
    [buyContext, setBuyContext, setStep],
  );

  return (
    <>
      {loading ? (
        <Loader message="Carregando" />
      ) : (
        <AnimatedDiv visible={step[1] === 1}>
          <ProductsContent>
            <h1>Produtos</h1>
            <aside>
              {productList.map(productInfo => (
                <div>
                  <h2>{productInfo.title}</h2>
                  <h3>{productInfo.formattedPrice}</h3>
                  <button
                    onClick={() => handleProduct(productInfo)}
                    type="button"
                  >
                    {productInfo.type === 'free' ? 'Cadastrar' : 'Comprar'}
                  </button>
                  <ul>
                    <li>
                      <strong>Benefícios</strong>
                    </li>
                    {productInfo.benefits.map(benefit => (
                      <li>
                        <p>
                          <FiChevronRight />
                        </p>
                        <p>{benefit}</p>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </aside>
          </ProductsContent>
        </AnimatedDiv>
      )}
    </>
  );
};

export default ProductsContainer;
