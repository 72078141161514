import React from 'react';
import { Menu } from '../../../components/Menu';
import { Wrapper, Content } from './styles';
import { usePages } from '../../../hooks/Pages';
import FooterLayout from '../../Footer';
import PopupComponent from '../../../components/PopupContainer';
import { RoomChangeContainer } from '../../../components/RoomChange';
import Particles from 'react-particles-js';

const AuthLayout: React.FC = ({ children }) => {
  const { menu } = usePages();
  return (<>
    <Wrapper>
      <Menu menuList={menu} fixedMenu={false} />
      <Content>{children}</Content>
     
      <PopupComponent />
      <RoomChangeContainer />
      <FooterLayout />
    </Wrapper>
    
     
    </>
  );
};

export default AuthLayout;
