const template = {
    bodyBackgroundWithOpacity : 'rgba(0,0,0,0.3)',
    color: '#fff',
    input : {
        placeholder : '#333',
        background: '#fff',
        color: '#333',
        border: '1px solid #333',
        colorFilled : 'rgb(0,100,50)',
        colorFocused : 'rgb(0,150,50)',
        borderColorFocused: 'rgb(0,150,50)',
    },
    button : {
        background : 'rgb(0,100,0)',
        color: '#fff',
        backgroundHover: 'rgb(0,150,0)',
        colorHover: '#fff',
    },
    video : {
        background : '#000',
        videoColor: '#fff',
        color: '#fff'
    }
}

export default template;