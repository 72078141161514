export const hash = '9fb6aaf4-69df-44d7-96cc-19e0802abc03';

// export const gateKey = '';
export const gateKey = '';
export const recaptchaKey = '6Ldsz2AbAAAAAGxObMQDaQ-o8CIehPS8XgJ6WEnN';
export const apiUrl = `https://api-global.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://global-socket.encontrodigital.com.br/';
//export const apiSocket = 'http://localhost:8001';
//export const apiUrl = `http://localhost:8000/${hash}`;
export const urlWebsite = 'https://palco.fronteiras.com';
export const supportNumber = `5551981476007`;
export const home = 'Home';
// export const urlWebsite = 'http://localhost:3000';



export const config = {
  menu : {
    language : 1,
    directChat : 1
  }
}
export const dadosOrganizacao = 'PALCO FRONTEIRAS DO PENSAMENTO';
export const eventName = 'PALCO FRONTEIRAS DO PENSAMENTO';
export const logo = '/apoio/logobranca.png';
export const background = '/assets/evento/Fundo.jpeg';
export const authTitle = 'PALCO FRONTEIRAS DO PENSAMENTO';
export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  twitter: '',
};

export const defaultTheme = {
  defaultBackground: 'rgb(45, 77, 181)',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: 'none',
    backgroundSticky: 'none',
    backgroundMobile: 'rgb(45, 77, 181)',
    color: '#fff',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
