import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import { background } from '../../../../../config';


const fadeFromLeft = keyframes`
from {
  opacity: 0;
  transform:translateX(50) ;
}
to{
  opacity: 1;
  transform:translateX(0);
}
`;

export const Container = styled.div`
  height: 80vh;
  display: flex;
  width: 100%;

  flex-direction: column;
  padding: 20px;

  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 10%;
  overflow-y: auto;
  padding-bottom: 200px;

  .checkDiv {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100% !important;

    >div{
      width: 100%;
    }

    >button {
      width: 50px;
      height: auto;
      min-width: 50px;
      min-height: 30px;
      font-size: 12px;

      border-radius: 10px;
      border: 0;
      color: #fff;
      background: rgb(0,120,150);
      margin: 0px 5px;
    }
  }

  form {
    display: flex;
    flex-direction: column;

justify-content: flex-start;
align-items: center;

>p{
  text-align: left;
  width: 100%;
  padding: 10px !important;
}

    >div{
      box-shadow: none !important;
      border: 0px !important;
      color: #333;
      background: none !important;

    

      p{
        color: #333;
      }

      input{
        &::placeholder {
    color: #333  !important;
  }
      }
    }
  }

  @media (max-width: 750px) {
    min-height: auto;
    align-items: flex-start;
    padding: 0px;
    justify-content: flex-start;
    width: 100%;
  }
`;


interface IBackground {
  background ?: string;
}


export const Photo = styled.aside<IBackground>`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  position: relative;
  background: url(${props => props.background}) no-repeat top;
  background-size: cover;
  margin: 5px auto;

>div {
  opacity: 0.01;
    position: absolute;
    top:0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
  >input {
    opacity: 0.01;
    position: absolute;
    top:0px;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;

  }
}
`;


export const AnimatedDiv = styled.div<IBackground>`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  background: rgba(0, 0, 0, 0.9);
  padding: 10px 0px;

  @media (max-width: 750px) {
    align-items: center;
  }

  form {
    margin: 10px 0;
    width: 340px;
    max-width: 100%;
    text-align: center;

    @media (max-width: 750px) {
      padding: 0px 20px;
      width: 100%;
      max-width: 90%;
    }

    h2 {
      margin-bottom: 24px;
      color: #fff;
    }

    a {
      color: #fff;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#fff')};
      }
    }
  }

  > a {
    color: #fff;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;

export const AnimatedDiv2 = styled.div<IBackground>`
  z-index: 1;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
  width: 100%;
  background: none;
  padding: 20px 0px;
  position: relative;

  @media (max-width: 750px) {
    align-items: center;
  }

  form + form {
    border-top: 1px solid #333;
  }

  .checkDiv {
    display: flex;
    justify-content: center;
    align-items: stretch;
    width: 100% !important;

    >button {
      width: 50px;
      height: auto;
      min-width: 50px;
      min-height: 30px;
      font-size: 12px;

      border-radius: 10px;
      border: 0;
      color: #fff;
      background: rgb(0,120,150);
      margin: 0px 5px;
    }
  }

  form {
    margin: 0px 0;
    padding:  10px;
    width: 340px;
    max-width: 100%;
    text-align: center;

    @media (max-width: 750px) {
      padding: 0px;
      width: 100%;
  
    }

    h2 {
      margin-bottom: 24px;
      color: #fff;
    }

    a {
      color: #fff;
      display: block;
      margin-top: 24px;
      text-decoration: none;

      transition: color 0.2s;

      &:hover {
        color: ${shade(0.2, '#fff')};
      }
    }
  }

  > a {
    color: #fff;
    display: block;
    margin-top: 24px;
    text-decoration: none;

    transition: color 0.2s;

    &:hover {
      color: ${shade(0.2, '#fff')};
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 450px;

  z-index: 1;

  img {
    width: 300px;
    max-width: 100%;
    margin: 10px;
  }

  @media (max-width: 750px) {
    padding: 0;
    width: 100%;
  }
`;

export const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: url(${background}) no-repeat top right;
  background-size: cover;
  width: 100%;
  height: 100%;
  z-index: 0;
`;
