import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}

const ProductsAdmin: React.FC = () => {
  const endpoint = '/products-manager';
  const title = 'Produtos';
  const schema: Record<string, any> = {
    id: { ref: 'id', column: 'id', label: 'ID', show: false },
    hash_link: { ref: 'hash_link', column: 'hash_link', label: 'Link' },
    title: { ref: 'title', column: 'title', label: 'Título' },
    price: { ref: 'price', column: 'price', label: 'Preço', mask: 'currency' },
    before_price: {
      ref: 'before_price',
      column: 'before_price',
      label: 'Preço Cheio',
      mask: 'currency',
    },
    limited_quantities: {
      ref: 'limited_quantities',
      column: 'limited_quantities_response',
      includeColumn: 'title',
      label: 'Vagas Limitadas?',
      filter: true,
    },

    total_quantities: {
      ref: 'total_quantities',
      column: 'total_quantities',
      label: 'Total de Vagas',
    },

    available_quantities: {
      ref: 'available_quantities',
      column: 'available_quantities',
      label: 'Vagas Disponíveis',
    },

    sold_quantities: {
      ref: 'sold_quantities',
      column: 'sold_quantities',
      label: 'Ingressos Vendidos',
    },

    waiting_quantities: {
      ref: 'waiting_quantities',
      column: 'waiting_quantities',
      label: 'Ingressos Aguardando Pg.',
    },

    status: {
      ref: 'status',
      column: 'status_response',
      includeColumn: 'title',
      label: 'Status',
      filter: true,
    },
    valid: {
      ref: 'valid',
      column: 'valid_response',
      includeColumn: 'title',
      label: 'Valido?',
      filter: true,
    },
    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };
  const formSchema = {
    status: {
      alias: 'status',
      model: 'selectApi',
      name: 'status',
      label: 'Status',
      defaultValue: 1,

      endpoint,
    },

    valid: {
      alias: 'valid',
      model: 'selectApi',
      name: 'valid',
      label: 'Link Válido?',
      defaultValue: 2,

      endpoint,
    },

    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    hash_link: { model: 'input', type: 'text', name: 'hash_link', label: 'Link' },
    description: { model: 'input', type: 'text', name: 'description', label: 'Descritivo' },
    price: {
      model: 'currencyInput',
      name: 'price',
      label: 'Preço',
      defaultValue: '0',
    },
    before_price: {
      model: 'currencyInput',
      name: 'before_price',
      label: 'Preço Cheio',
      defaultValue: '0',
    },
    limited_quantities: {
      alias: 'limited_quantities',
      model: 'selectApi',
      name: 'limited_quantities',
      label: 'Status',
      defaultValue: 1,
      endpoint,
    },
    total_quantities: {
      model: 'input',
      type: 'text',
      name: 'total_quantities',
      label: 'Total de Vagas',
      defaultValue: 0,
    },
    email_message: {
      model: 'richText',
      type: 'text',
      name: 'email_message',
      label: 'Mensagem do email',
      defaultValue: 0,
    },
    courses: {
      model: 'JsonInput',
      bodyRender: (data: Record<string, any>) => (
        <nav>
          <strong>
            (
{data.id})
{' '}
{data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </nav>
      ),
      searchRender: (data: Record<string, any>) => (
        <>
          <strong>
            (
{data.id})
{data.title}
          </strong>
          <br />
          {data.release_date_formatted || data.release_date}
        </>
      ),
      storageType: 'json',
      endpoint: `/courses`,
      name: 'courses',
      label: 'Cursos',
    },
  };

  const formSchemaUpdate = {
 
    logo: {
      model: 'uploadImage',
      type: 'upload',
      name: 'logo',
      label: 'logo',
    },
...formSchema
  };

  

  const formValidation: Record<string, ValidationSchema> = {
    title: {
      name: 'title',
      type: 'string',
      label: 'Título',
      rules: [{ type: 'required' }],
    },
  };



  const config: ISearchComponent = {
    idKey: endpoint,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
      lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default ProductsAdmin;
