import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { uuid } from 'uuidv4';
import QRCode from 'qrcode.react';
import { FaBarcode, FaQrcode } from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { useModal } from '../../../../../../hooks/Modal';
import FormComponent, { sanitizeDataObject } from '../../FormComponent';
import { createSchemaByForm, IYup } from '../../../../../../utils/SchemaCreator';
import getValidationErrors from '../../../../../../utils/getValidationErrors';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';
import { useSearch } from '../../../../../../hooks/Search';
import Button from '../../Forms/Button';

import { usePrinter } from '../../../../../../hooks/PrinterHook';
import QRCodeComponent from '../../QRCodeComponent';

interface ICreateModule {
  lineData: Record<string, any>;
  title: string;
  formSchema: Record<string, any>;
  endpoint: string;
  validationSchema: Record<string, IYup>;
  apiRef?: AxiosInstance;
}

export default function CrachaLote({ idKey = ''}): JSX.Element {
  const { addModal, removeModal } = useModal();
  const { addToast } = useToast();
  const { addPrint, printIt } = usePrinter();
  const key = uuid();

  const {reloadSearch} = useSearch();

  const print = ()=> {
    printIt();
  };




  const handleQR = async (): Promise<void> => {



    

    let html : Array<JSX.Element> = [];

    const dataRows = await reloadSearch(idKey,'json');


    
   addPrint(
    html
    );
    

    const content =  <><h2>CRACHÁ</h2><Button onClick={() => print()}>Imprimir</Button><div style={{background: '#fff', width: '9cm', textAlign: 'center'}}>{html}</div> </>;

    if (content) {
      const keyModal = addModal({ title : 'Imprimir Lote', content, key });
    }


    
  };

  const moduleQRCode = (): JSX.Element => (
 

<button className="searchButton" title="Imprimir Lote de Crachás" type="button" onClick={handleQR}>
<FaBarcode />
</button>
  );

  return moduleQRCode();
}
