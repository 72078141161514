import styled from 'styled-components';
import { defaultTheme } from '../../config';

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 100%;

  
  z-index: 100;


  .iconButton {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      border: 0;
      >svg{
        font-size: 12px;
      }

      background: ${defaultTheme.defaultBackground};
      color: #fff;
    }}

    .iconTrash {
      background: #555;
      border-radius: 0 4px 4px 0;
      align-items: center;
      height: auto;
    }

.saveButton {
  margin-top: 10px;
  padding: 5px;
  border-radius: 4px;

}

input, select {
  width: 100%;
  padding: 10px;
}


textarea {
  min-height: 50px;
}

  nav {
    cursor: pointer;
    color: white;
    font-size: 16px;
    opacity: 1;
    transition: opacity 0.2s;
    font-weight: bold;

    & + nav {
      margin-left: 50px;
    }

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  min-width: 300px;

  > label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0;
  }

  > div {
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
  }

  .voteOption {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    > input {
      margin-right: 10px;
    }
  }
`;

export const Modal = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.5);
  align-items: center;
  justify-content: center;
  z-index: 300;

  .ListIcons {
    > button {
      margin: 3px;
      opacity: 0.7;
      transition: 0.5s all;
      &:hover {
        opacity: 1;
      }
    }
  }

  @media (max-width: 1200px) {
    position: fixed;
  }

  > div {
    overflow-y: auto;
    padding: 40px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    color: #333;
    background: #fff;

    > h2 {
      color: ${defaultTheme.defaultBackground};
      margin-bottom: 10px;
      font-size: 24px;
    }

    > p {
      color: #333;
      font-size: 16px;
      line-height: 24px;
    }

    > button {
      padding: 10px;
      margin: 5px auto;
      width: 200px;
      border-radius: 8px;
      background: ${defaultTheme.defaultBackground};
      color: #fff;
      border: 0px;
    }
  }
`;

export const QuizzHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  > div,
  > button {
    width: 100% !important;
    min-width: auto !important;
    min-height: 40px !important;
    padding: 10px !important;
    border-radius: 0px !important;

    @media (max-width: 750px) {
      font-size: 10px;
    }

    > button + button {
      border-left: 1px solid #fff;
    }
  }
`;
